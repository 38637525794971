import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcv-zero-state',
  templateUrl: './zero-state.component.html',
  styleUrls: ['./zero-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeroStateComponent implements OnInit {
  @Input() headerText = ``;
  @Input() image: 'default' | 'bank-dashboard' | 'scroll' = 'default';
  @Input() subText = ``;
  @Input() imgSrc;

  constructor() {}

  ngOnInit() {}
}
