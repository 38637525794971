// -------------------------------------------------------------------------
// EXAMPLE SELECTORS
// -------------------------------------------------------------------------

import * as fromDeposits from './deposits.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Deposit } from '@gcv/shared';

export const selectDepositsState = createFeatureSelector<fromDeposits.DepositState>('deposits');

export const selectDepositsIds = createSelector(
  selectDepositsState,
  fromDeposits.selectDepositIds
);

export const selectDepositEntities = createSelector(
  selectDepositsState,
  fromDeposits.selectDepositEntities
);

export const selectAllDeposits = createSelector(
  selectDepositsState,
  fromDeposits.selectAllDeposits
);

export const selectDepositById = createSelector(
  selectDepositEntities,
  (state, props) => {
    return Object.values(state).filter((deposit: Deposit) => deposit.deposit_id === props.depositId)[0];
  }
);

export const selectNewDeposit = createSelector(
  selectDepositsState,
  state => {
    return state.newDeposit;
  }
);

export const selectDepositsByDispensaryId = createSelector(
  selectAllDeposits,
  (state, props) => {
    return state.filter((deposit: Deposit) => deposit.dispensary_id === props.dispensaryId);
  }
);
