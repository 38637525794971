import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
  @Input() label = '';
  @Input() labelStyle: any;
  @Input() checked = false;
  @Output() toggled = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleChange(e) {
    this.toggled.emit(e.target.checked);
  }
}
