import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: any;
  @Output() notificationClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  goToNotification() {
    this.notificationClicked.emit(this.notification);
  }
}
