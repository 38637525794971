import { select, Store } from '@ngrx/store';
import { UserState } from './user.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { User } from '@gcv/shared';
import {
  LoadUser,
  LoadUserKba,
  ResetUser,
  ResetUserKba,
  UpdateUser,
  UpdateUserDocuments,
  VerifyUserKbaAnswers,
} from './user.actions';
import { filter, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { selectKbaQuestions, selectUserEntities } from './user.selectors';
import { Answers } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  user$: Observable<Dictionary<User>>;

  constructor(private store: Store<UserState>) {
    this.user$ = store.pipe(select(selectUserEntities));
  }

  getUserById(userId: string) {
    this.store.dispatch(new LoadUser({ userId }));
    return this.user$.pipe(map(userDictionary => userDictionary[userId]));
  }

  putUserById(user: User) {
    this.store.dispatch(new UpdateUser(user));
  }

  selectCurrentUser() {
    return this.user$.pipe(map(userDictionary => Object.values(userDictionary)[0]));
  }

  verifyUserByKba(userId: string) {
    this.store.dispatch(new LoadUserKba({ userId }));
    return this.store.pipe(
      select(selectKbaQuestions),
      timeout(3000),
      filter((questions: any) => questions !== null)
    );
  }

  verifyUserKbaAnswers(userId: string, answers: Answers) {
    this.store.dispatch(new VerifyUserKbaAnswers({ userId, userAnswers: answers }));
    return this.user$.pipe(map(userDictionary => userDictionary[userId]));
  }

  updateUserDocuments(userId: string) {
    this.store.dispatch(new UpdateUserDocuments({ userId }));
  }

  resetUserKba() {
    this.store.dispatch(new ResetUserKba());
  }

  resetUser() {
    this.store.dispatch(new ResetUser());
  }
}
