import * as fromStaff from './staff.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectStaffState = createFeatureSelector<fromStaff.StaffState>('staff');

export const selectStaffIds = createSelector(
  selectStaffState,
  fromStaff.selectStaffIds
);

export const selectStaffEntities = createSelector(
  selectStaffState,
  fromStaff.selectStaffEntities
);

export const selectAllStaff = createSelector(
  selectStaffState,
  fromStaff.selectAllStaff
);
