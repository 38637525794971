import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-initial-dashboard',
  templateUrl: './initial-dashboard.component.html',
  styleUrls: ['./initial-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() logo: string;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Output() buttonPressed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleButtonClicked() {
    this.buttonPressed.emit();
  }
}
