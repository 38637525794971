import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { updateConfig } from './rollbar/rollbar.config';
import { LaunchDarklyService } from './launch-darkley/launchdarkley.service';
import { UserFacade } from '@user-interface/gcv-state';
import { User } from '@gcv/shared';
import { filter, tap } from 'rxjs/operators';

/*
  This service provides communication for the Secure App family of components (secure/landing -> gcv-shell, bank-shell, dispensary-shell).
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class SecureAppFamilyService {
  signedIn = false;

  constructor(private userFacade: UserFacade, private launchDarklyService: LaunchDarklyService) {}

  static updateRollbar(user: User) {
    const config = {
      userId: user.id,
      username: `${user.firstName} ${user.lastName}`,
      email: user.email,
      env: environment.env,
    };
    updateConfig(config);
  }

  init(userId) {
    return this.userFacade.getUserById(userId).pipe(
      filter((user: User) => user !== undefined),
      tap((user: User) => this.handleUser(user))
    );
  }

  updateThirdPartyConfigs(user) {
    SecureAppFamilyService.updateRollbar(user);
    this.launchDarklyService.changeUser(false, user);
  }

  private handleUser(user: User) {
    this.userFacade.updateUserDocuments(user.id);
    this.updateThirdPartyConfigs(user);
    this.signedIn = true;
  }
}
