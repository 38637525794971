import { Pipe, PipeTransform } from '@angular/core';
import { DispensaryFacade } from '@user-interface/gcv-state';
import { Dispensary } from '@gcv/shared';
import { filter, map, take } from 'rxjs/operators';

@Pipe({
  name: 'formatNamePipe',
})
export class FormatNamePipe implements PipeTransform {
  constructor(private dispFacade: DispensaryFacade) {}
  transform(id: any, args?: any): any {
    return this.dispFacade.selectDispensaryById(id).pipe(
      filter((disp: Dispensary) => disp !== undefined),
      take(1),
      map((disp: Dispensary) => disp.name)
    );
  }
}
