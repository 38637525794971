import { DataPersistence } from '../../utils/data.persistance';
import { BankState } from './bank.reducer';
import { Effect } from '@ngrx/effects';
import {
  AddDispensaryToBank,
  AllBanksLoaded,
  BankActionTypes,
  BankCreated,
  BankLoaded,
  BankUpdated,
  CreateBank,
  DispensaryAddedToBank,
  GroupsLoaded,
  GroupsUpdated,
  LoadAllBanks,
  LoadBank,
  LoadGroups,
  UpdateBank,
  UpdateGroups,
} from './bank.actions';
import { map } from 'rxjs/operators';
import { Bank, Group } from '@gcv/shared';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class BankEffect {
  @Effect() loadBank$ = this.dataPersistance.fetch(BankActionTypes.LoadBank, {
    run: (action: LoadBank, state: BankState) => {
      return this.sdk.banksIdGet(action.payload.bankId).pipe(map((res: Bank) => new BankLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadBank, message: err });
    },
  });

  @Effect() updateBank$ = this.dataPersistance.fetch(BankActionTypes.UpdateBank, {
    run: (action: UpdateBank, state: BankState) => {
      return this.sdk.banksIdPut(action.payload.id, action.payload).pipe(map((res: Bank) => new BankUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.UpdateBank, message: err });
    },
  });

  @Effect() createBank$ = this.dataPersistance.fetch(BankActionTypes.CreateBank, {
    run: (action: CreateBank, state: BankState) => {
      return this.sdk.banksPost(action.payload).pipe(map((res: Bank) => new BankCreated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.CreateBank, message: err });
    },
  });

  @Effect() loadAllBanks$ = this.dataPersistance.fetch(BankActionTypes.LoadAllBanks, {
    run: (action: LoadAllBanks, state: BankState) => {
      return this.sdk.banksGet().pipe(map((res: Bank[]) => new AllBanksLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadAllBanks, message: err });
    },
  });

  @Effect() loadGroups$ = this.dataPersistance.fetch(BankActionTypes.LoadGroups, {
    run: (action: LoadGroups, state: BankState) => {
      return this.sdk.groupsOrgIdGet(action.payload.orgId).pipe(map((res: Group) => new GroupsLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadGroups, message: err });
    },
  });

  @Effect() updateGroups$ = this.dataPersistance.fetch(BankActionTypes.UpdateGroups, {
    run: (action: UpdateGroups, state: BankState) => {
      return this.sdk
        .groupsOrgIdPut(action.payload.bankId, action.payload.groups)
        .pipe(map((res: Bank) => new GroupsUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.UpdateGroups, message: err });
    },
  });

  @Effect() addDispensaryToBank$ = this.dataPersistance.fetch(BankActionTypes.AddDispensaryToBank, {
    run: (action: AddDispensaryToBank, state: BankState) => {
      return this.sdk
        .banksIdDispensariesDispensaryIdPut(action.payload.dispensaryId, action.payload.bankId)
        .pipe(map((res: Bank) => new DispensaryAddedToBank(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.AddDispensaryToBank, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<BankState>, private sdk: DefaultService) {}
}
