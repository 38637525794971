import { DataPersistence } from '../../utils/data.persistance';
import { NotificationState } from './notifications.reducer';
import { Effect } from '@ngrx/effects';
import {
  LoadNotifications,
  NotificationActionTypes,
  NotificationsLoaded,
  NotificationUpdated,
  UpdateNotification,
} from './notifications.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Notification } from '@gcv/shared';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class NotificationsEffect {
  @Effect() loadNotifications$ = this.dataPersistance.fetch(NotificationActionTypes.LoadNotifications, {
    run: (action: LoadNotifications, state: NotificationState) => {
      return this.sdk
        .notificationsIdGet(action.payload.userId)
        .pipe(map((res: Notification[]) => new NotificationsLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: NotificationActionTypes.LoadNotifications, message: err });
    },
  });

  @Effect() updateNotification$ = this.dataPersistance.pessimisticUpdate(NotificationActionTypes.UpdateNotification, {
    run: (action: UpdateNotification, state: NotificationState) => {
      return this.sdk
        .notificationsIdPut(action.payload.id, action.payload)
        .pipe(map((res: Notification) => new NotificationUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: NotificationActionTypes.UpdateNotification, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<NotificationState>, private sdk: DefaultService) {}
}
