import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'gcv-mot-detail-dialog',
  styleUrls: ['./mot-detail-dialog.component.scss'],
  templateUrl: 'mot-detail-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotDetailDialogComponent {
  constructor(
    private matDialogRef: MatDialogRef<MotDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  handleClose() {
    this.matDialogRef.close({});
  }
}
