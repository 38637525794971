import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-help-text-dialog',
  templateUrl: './help-text-dialog.component.html',
  styleUrls: ['./help-text-dialog.component.scss'],
})
export class HelpTextDialogComponent {
  title = '';
  headerText = '';
  helpBoxes = [{ header: '', content: '' }];
  footerText = '';

  constructor(private matDialogRef: MatDialogRef<HelpTextDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.headerText = data.headerText ? data.headerText : '';
    this.title = data.title ? data.title : 'Help';
    this.helpBoxes = data.helpBoxes ? data.helpBoxes : [{ header: '', content: '' }];
    this.footerText = data.footerText ? data.footerText : '';
  }
}
