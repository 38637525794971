import { select, Store } from '@ngrx/store';
import { DepositState } from './deposits.reducer';
import { Dictionary } from '@ngrx/entity';
import { Deposit } from '@gcv/shared';
import {
  selectAllDeposits,
  selectDepositById,
  selectDepositEntities,
  selectDepositsByDispensaryId,
  selectNewDeposit,
} from './deposits.selectors';
import { Observable } from 'rxjs';
import { AcceptDeposit, CreateDeposit, LoadDeposits, LoadNewDeposit, ResetDeposits } from './deposits.actions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DepositsFacade {
  deposits$: Observable<Dictionary<Deposit>>;

  constructor(private store: Store<DepositState>) {
    this.deposits$ = store.pipe(select(selectDepositEntities));
  }

  getDepositsByDispensaryId(dispensaryId: string) {
    this.store.dispatch(new LoadDeposits({ dispensaryId }));
  }

  postDeposit(deposit: Partial<Deposit>, dispensaryId: string) {
    this.store.dispatch(new CreateDeposit({ deposit, dispensaryId }));
  }

  putAcceptDeposit(params: { deposit: Deposit; bankId: string }) {
    this.store.dispatch(new AcceptDeposit(params));
  }

  getNewDepositByDispensaryId(dispensaryId: string) {
    this.store.dispatch(new LoadNewDeposit({ dispensaryId }));
  }

  resetDeposits() {
    this.store.dispatch(new ResetDeposits());
  }

  selectDepositById(depositId: string): Observable<Deposit> {
    return this.store.pipe(select(selectDepositById, { depositId })) as Observable<Deposit>;
  }

  selectNewDeposit() {
    return this.store.pipe(select(selectNewDeposit));
  }

  selectDepositsByDispensaryId(dispensaryId: string) {
    return this.store.pipe(select(selectDepositsByDispensaryId, { dispensaryId }));
  }

  selectAllDeposits() {
    return this.store.pipe(select(selectAllDeposits));
  }
}
