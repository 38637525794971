import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TransportVendor } from '@gcv/shared';
import { VendorsActionTypes } from './vendors.actions';

export interface VendorState extends EntityState<TransportVendor> {}

export const adapterVendor: EntityAdapter<TransportVendor> = createEntityAdapter<TransportVendor>();

export const initialVendorState = adapterVendor.getInitialState();

export function vendorReducer(state = initialVendorState, action): VendorState {
  switch (action.type) {
    case VendorsActionTypes.VendorLoadedById:
      return adapterVendor.addOne(action.payload, state);
    case VendorsActionTypes.ResetVendors:
      return initialVendorState;
    default:
      return state;
  }
}

export const {
  selectIds: selectVendorIds,
  selectEntities: selectVendorEntities,
  selectAll: selectAllVendors,
} = adapterVendor.getSelectors();
