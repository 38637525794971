import { Environment } from './env-interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:d9d957fc-7084-4375-a5eb-cfe075b2d951',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_xQLG95uM4',
    aws_user_pools_web_client_id: '11lb5kq4bjlo1mnak52vq563ov',
  },
  gcvConfig: {
    launchDarklyClientSideId: '5cfac57d21b824075bd50d40',
    webSocketUrl: '',
    doLoadWebSocket: false,
  },
  env: 'jboe',
  basePath: 'https://jboeapi.greencheckverified.com',
  storageConfig: {
    salesFile: 'jboe-new-data-file-bucket',
    document: 'jboe-gcv-company-document-store',
    regulatoryGuide: '',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
