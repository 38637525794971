import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BankState } from './bank.reducer';
import { Observable } from 'rxjs';
import { selectAllBank, selectBankById, selectBankEntities } from './bank.selectors';
import { Bank, Group } from '@gcv/shared';
import { Dictionary } from '@ngrx/entity';
import {
  AddDispensaryToBank,
  CreateBank,
  LoadAllBanks,
  LoadBank,
  LoadGroups,
  ResetBank,
  UpdateBank,
  UpdateGroups,
} from './bank.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BankFacade {
  banks$: Observable<Dictionary<Bank>>;

  constructor(private store: Store<BankState>) {
    this.banks$ = store.pipe(select(selectBankEntities));
  }

  getBankById(bankId: string) {
    this.store.dispatch(new LoadBank({ bankId }));
  }

  postBank(bank: Partial<Bank>) {
    this.store.dispatch(new CreateBank(bank));
  }

  putBank(bank: Bank) {
    this.store.dispatch(new UpdateBank(bank));
  }

  getAllBanks() {
    this.store.dispatch(new LoadAllBanks());
  }

  getBankGroups(orgId: string) {
    this.store.dispatch(new LoadGroups({ orgId }));
  }

  putBankGroups(bankId: string, groups: Group[]) {
    this.store.dispatch(new UpdateGroups({ bankId, groups }));
  }

  putAddDispensaryToBank(bankId: string, dispensaryId: string) {
    this.store.dispatch(new AddDispensaryToBank({ dispensaryId, bankId }));
  }

  resetBankState() {
    this.store.dispatch(new ResetBank());
  }

  selectBankById(bankId: string) {
    return this.store.pipe(select(selectBankById, { bankId }));
  }

  selectAllBanks() {
    return this.store.pipe(select(selectAllBank));
  }

  selectCurrentBank() {
    return this.store.pipe(
      select(selectAllBank),
      map((banks: Bank[]) => banks[0])
    );
  }
}
