import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupComponent implements OnInit {
  @Input() groups = [];
  @Input() users: any;
  @Output() createGroup = new EventEmitter();
  @Output() viewGroup = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleCreateGroup() {
    this.createGroup.emit();
  }

  handleViewGroup(group) {
    this.viewGroup.emit(group);
  }
}
