import { DataPersistence } from '../../utils/data.persistance';
import { DispensaryState } from './dispensary.reducer';
import { Effect } from '@ngrx/effects';
import {
  AllDispensaryLoaded,
  ApproveBankDueDiligence,
  ApproveGcvDueDiligence,
  BankDueDiligenceApproved,
  CreateDispensary,
  DispensaryActionTypes,
  DispensaryCreated,
  DispensaryGroupsUpdated,
  DispensaryLoaded,
  DispensaryUpdated,
  DispensaryUpdateGroups,
  GcvDueDiligenceApproved,
  LoadAllDispensary,
  LoadDispensary,
  UpdateDispensary,
} from './dispensary.actions';
import { Dispensary } from '@gcv/shared';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class DispensaryEffect {
  @Effect() loadDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.LoadDispensary, {
    run: (action: LoadDispensary, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdGet(action.payload.dispensaryId)
        .pipe(map((res: Dispensary) => new DispensaryLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.LoadDispensary, message: err });
    },
  });

  @Effect() updateDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.UpdateDispensary, {
    run: (action: UpdateDispensary, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdPut(action.payload.id, action.payload)
        .pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
    },
  });

  @Effect() createDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.CreateDispensary, {
    run: (action: CreateDispensary, state: DispensaryState) => {
      return this.sdk.dispensariesPost(action.payload).pipe(map((res: Dispensary) => new DispensaryCreated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.CreateDispensary, message: err });
    },
  });

  @Effect() loadAllDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.LoadAllDispensary, {
    run: (action: LoadAllDispensary, state: DispensaryState) => {
      return this.sdk.dispensariesGet().pipe(map((res: Dispensary[]) => new AllDispensaryLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.LoadAllDispensary, message: err });
    },
  });

  @Effect() dispensaryUpdateGroups$ = this.dataPersistance.fetch(DispensaryActionTypes.DispensaryUpdateGroups, {
    run: (action: DispensaryUpdateGroups, state: DispensaryState) => {
      return this.sdk
        .groupsOrgIdPut(action.payload.dispensaryId, action.payload.groups)
        .pipe(map((res: Dispensary) => new DispensaryGroupsUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.DispensaryUpdateGroups, message: err });
    },
  });

  @Effect() approveBankDueDiligence$ = this.dataPersistance.fetch(DispensaryActionTypes.approveBankDueDiligence, {
    run: (action: ApproveBankDueDiligence, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdDiligenceBankBankIdPost(action.payload.bankId, action.payload.dispensaryId, action.payload.data)
        .pipe(map((res: Dispensary) => new BankDueDiligenceApproved(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.approveBankDueDiligence, message: err });
    },
  });

  @Effect() approveGcvDueDiligence$ = this.dataPersistance.fetch(DispensaryActionTypes.approveGcvDueDiligence, {
    run: (action: ApproveGcvDueDiligence, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdDiligenceGcvPost(action.payload.dispensaryId, action.payload.data)
        .pipe(map((res: Dispensary) => new GcvDueDiligenceApproved(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.approveGcvDueDiligence, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<DispensaryState>, private sdk: DefaultService) {}
}
