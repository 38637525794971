import { DataPersistence } from '../../utils/data.persistance';
import { DepositState } from './deposits.reducer';
import { Effect } from '@ngrx/effects';
import {
  AcceptDeposit,
  CreateDeposit,
  DepositAccepted,
  DepositActionTypes,
  DepositCreated,
  DepositsLoaded,
  LoadDeposits,
  LoadNewDeposit,
  NewDepositLoaded,
} from './deposits.actions';
import { map } from 'rxjs/operators';
import { Deposit } from '@gcv/shared';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class DepositsEffect {
  @Effect() loadDeposits$ = this.dataPersistance.fetch(DepositActionTypes.LoadDeposits, {
    run: (action: LoadDeposits, state: DepositState) => {
      return this.sdk
        .dispensariesIdDepositsGet(action.payload.dispensaryId)
        .pipe(map((res: Deposit[]) => new DepositsLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.LoadDeposits, message: err });
    },
  });

  @Effect() createDeposit$ = this.dataPersistance.fetch(DepositActionTypes.CreateDeposit, {
    run: (action: CreateDeposit, state: DepositState) => {
      return this.sdk
        .dispensariesIdDepositsPost(action.payload.dispensaryId, action.payload.deposit)
        .pipe(map((res: Deposit) => new DepositCreated(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.CreateDeposit, message: err });
    },
  });

  @Effect() acceptDeposit$ = this.dataPersistance.fetch(DepositActionTypes.AcceptDeposit, {
    run: (action: AcceptDeposit, state: DepositState) => {
      return this.sdk
        .banksIdDispensariesDispensaryIdDepositsDepositIdAcceptPost(
          action.payload.deposit.deposit_id,
          action.payload.deposit.dispensary_id,
          action.payload.bankId,
          action.payload.deposit
        )
        .pipe(map((res: Deposit) => new DepositAccepted(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.AcceptDeposit, message: err });
    },
  });

  @Effect() loadNewDeposit$ = this.dataPersistance.fetch(DepositActionTypes.LoadNewDeposit, {
    run: (action: LoadNewDeposit, state: DepositState) => {
      return this.sdk
        .dispensariesIdDepositsNewGet(action.payload.dispensaryId)
        .pipe(map((res: Deposit) => new NewDepositLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.LoadNewDeposit, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<DepositState>, private sdk: DefaultService) {}
}
