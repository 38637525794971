import { Action } from '@ngrx/store';
import { Bank, Group } from '@gcv/shared';

export enum BankActionTypes {
  LoadBank = '[Bank] Load Bank',
  BankLoaded = '[Bank] Bank Loaded',

  LoadAllBanks = '[Bank] Load All Banks',
  AllBanksLoaded = '[Bank] All Banks Loaded',

  CreateBank = '[Bank] Create Bank',
  BankCreated = '[Bank] Bank Created',

  UpdateBank = '[Bank] Update Bank',
  BankUpdated = '[Bank] Bank Updated',

  LoadGroups = '[Bank] Load Groups',
  GroupsLoaded = '[Bank] Groups Loaded',

  UpdateGroups = '[Bank] Update Groups',
  GroupsUpdated = '[Bank] Groups Updated',

  AddDispensaryToBank = '[Bank] Add Dispensary to Bank',
  DispensaryAddedToBank = '[Bank] Dispensary Added to Bank',

  ResetBank = '[Bank] Reset Bank',
}

export class AddDispensaryToBank implements Action {
  readonly type = BankActionTypes.AddDispensaryToBank;

  constructor(public payload: { dispensaryId: string; bankId: string }) {}
}

export class DispensaryAddedToBank implements Action {
  readonly type = BankActionTypes.DispensaryAddedToBank;

  constructor(public payload: Bank) {}
}

export class LoadBank implements Action {
  readonly type = BankActionTypes.LoadBank;

  constructor(public payload: { bankId: string }) {}
}

export class BankLoaded implements Action {
  readonly type = BankActionTypes.BankLoaded;

  constructor(public payload: Bank) {}
}

export class CreateBank implements Action {
  readonly type = BankActionTypes.CreateBank;

  constructor(public payload: Partial<Bank>) {}
}

export class BankCreated implements Action {
  readonly type = BankActionTypes.BankCreated;

  constructor(public payload: Bank) {}
}

export class LoadAllBanks implements Action {
  readonly type = BankActionTypes.LoadAllBanks;
}

export class AllBanksLoaded implements Action {
  readonly type = BankActionTypes.AllBanksLoaded;

  constructor(public payload: Bank[]) {}
}

export class UpdateBank implements Action {
  readonly type = BankActionTypes.UpdateBank;

  constructor(public payload: Bank) {}
}

export class BankUpdated implements Action {
  readonly type = BankActionTypes.BankUpdated;

  constructor(public payload: Bank) {}
}

export class LoadGroups implements Action {
  readonly type = BankActionTypes.LoadGroups;

  constructor(public payload: { orgId: string }) {}
}

export class GroupsLoaded implements Action {
  readonly type = BankActionTypes.GroupsLoaded;

  constructor(public payload: Group) {}
}

export class UpdateGroups implements Action {
  readonly type = BankActionTypes.UpdateGroups;

  constructor(public payload: { bankId: string; groups: Group[] }) {}
}

export class GroupsUpdated implements Action {
  readonly type = BankActionTypes.GroupsUpdated;

  constructor(public payload: Bank) {}
}

export class ResetBank implements Action {
  readonly type = BankActionTypes.ResetBank;
}

export type BankActions =
  | LoadBank
  | BankLoaded
  | UpdateBank
  | BankUpdated
  | LoadGroups
  | GroupsLoaded
  | UpdateGroups
  | GroupsUpdated
  | ResetBank
  | AddDispensaryToBank
  | DispensaryAddedToBank
  | CreateBank
  | BankCreated
  | LoadAllBanks
  | AllBanksLoaded;
