import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TransportVendor } from '@gcv/shared';

@Component({
  selector: 'gcv-view-transportation-vendor',
  templateUrl: './view-transportation-vendor.component.html',
  styleUrls: ['./view-transportation-vendor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewTransportationVendorComponent implements OnInit {
  showHelp = true;
  vendor: TransportVendor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ViewTransportationVendorComponent>
  ) {
    this.vendor = data.vendor;
  }

  ngOnInit() {}

  close() {
    this.matDialogRef.close();
  }
}
