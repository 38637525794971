import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@gcv/shared';
import { LoggerService } from '../../../../../../services/logger/logger.service';
import { UtilsService } from '../../../../../../services/utils.service';
import { UserFacade } from '@user-interface/gcv-state';

@Component({
  selector: 'app-bank-personal-container',
  templateUrl: './bank-personal-container.component.html',
  styleUrls: ['./bank-personal-container.component.scss'],
})
export class BankPersonalContainerComponent implements OnInit {
  user$: Observable<User>;

  constructor(private userFacade: UserFacade, private logger: LoggerService, private utils: UtilsService) {}

  ngOnInit() {
    this.user$ = this.userFacade.selectCurrentUser();
  }

  save(user: Partial<User>) {
    this.userFacade.putUserById(this.utils.filterOutEmptyProperties(user));
  }
}
