/* tslint:disable:no-console */
import { Injectable } from '@angular/core';

import { LogLevelEnum } from './logLevel.enum';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private levels = ['OFF', 'ERROR', 'WARN', 'INFO', 'LOG', 'TRACE', 'DEBUG', 'ALL'];

  private logLevel: number;

  constructor() {
    this.setLevel(this.levels.indexOf('ALL'));
  }

  public setLevel(level: number) {
    this.logLevel = level;
  }

  public getLevel() {
    return this.levels[this.logLevel];
  }

  public trace(message, ...additional: any[]): void {
    this._log(LogLevelEnum.TRACE, message, additional);
  }

  public debug(message, ...additional: any[]): void {
    this._log(LogLevelEnum.DEBUG, message, additional);
  }

  public info(message, ...additional: any[]): void {
    this._log(LogLevelEnum.INFO, message, additional);
  }

  public log(message, ...additional: any[]): void {
    this._log(LogLevelEnum.LOG, message, additional);
  }

  public warn(message, ...additional: any[]): void {
    this._log(LogLevelEnum.WARN, message, additional);
  }

  public error(message, ...additional: any[]): void {
    this._log(LogLevelEnum.ERROR, message, additional);
  }

  private _log(level: number, message: string, additional: any) {
    const canLogToConsole = level < this.logLevel;
    const logLevelString = this.levels[level];
    const callerDetails = this.getCallerDetails();
    const timestamp = new Date().toISOString();

    if (canLogToConsole) {
      const metaString = this.prepareMetaString(
        timestamp,
        logLevelString,
        callerDetails.fileName,
        callerDetails.lineNumber
      );
      this.logFunc(level, metaString, message, additional);
    }
  }

  private logFunc(level: LogLevelEnum, metaString: string, message: string, additional: any[] = []) {
    const color = this.getColor(level);
    switch (level) {
      case LogLevelEnum.WARN:
        console.warn(`%c${metaString}`, `color:${color}`, message, ...additional);
        break;
      case LogLevelEnum.ERROR:
        console.error(`%c${metaString}`, `color:${color}`, message, ...additional);
        break;
      case LogLevelEnum.INFO:
        console.info(`%c${metaString}`, `color:${color}`, message, ...additional);
        break;
      case LogLevelEnum.TRACE:
        console.trace(`%c${metaString}`, `color:${color}`, message, ...additional);
        break;
      case LogLevelEnum.DEBUG:
        console.debug(`%c${metaString}`, `color:${color}`, message, ...additional);
        break;
      default:
        console.log(`%c${metaString}`, `color:${color}`, message, ...additional);
    }
  }

  private getColor(level: LogLevelEnum): 'blue' | 'teal' | 'gray' | 'red' | undefined {
    switch (level) {
      case LogLevelEnum.TRACE:
        return 'blue';
      case LogLevelEnum.DEBUG:
        return 'teal';
      case LogLevelEnum.INFO:
      case LogLevelEnum.LOG:
        return 'gray';
      case LogLevelEnum.WARN:
      case LogLevelEnum.ERROR:
        return 'red';
      case LogLevelEnum.OFF:
      default:
        return;
    }
  }

  private getCallerDetails(): { lineNumber: string; fileName: string } {
    const err = new Error('');

    try {
      // this should produce the line which Logger was called
      const callerLine = err.stack.split('\n')[4].split('/');

      // returns the file:lineNumber
      const fileLineNumber = callerLine[callerLine.length - 1].replace(/[)]/g, '').split(':');
      return {
        fileName: fileLineNumber[0],
        lineNumber: fileLineNumber[1],
      };
    } catch (e) {
      return {
        fileName: null,
        lineNumber: null,
      };
    }
  }

  private prepareMetaString(timestamp: string, logLevel: string, fileName: string, lineNumber: string) {
    const fileDetails = fileName ? ` [${fileName}:${lineNumber}]` : '';

    return `${timestamp} ${logLevel}${fileDetails}`;
  }
}
