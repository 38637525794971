import * as _ from 'underscore';
import { User } from '@gcv/shared';

export function filterOutEmptyProperties(obj: object): object {
  const filteredObj = _.pick(obj, (value: any) => {
    const keep = value !== null && value !== undefined && value !== '';
    return keep;
  });
  return filteredObj;
}

const getDefaultCompany = (companies: User['companies']) => {
  return companies[0];
};

export { getDefaultCompany };
