import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'gcv-card-group-card',
  templateUrl: './create-group-card.component.html',
  styleUrls: ['./create-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateGroupCardComponent {
  @Output() createGroup = new EventEmitter();
  subTitle = 'Groups are a helpful way to organize and keep track of your users.';
  title = 'Create Group';
  btnText = 'Get Started';

  constructor() {}

  handleCreateGroup() {
    this.createGroup.emit();
  }
}
