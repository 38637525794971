import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UserGroupService } from '../../../services/user-groups-service/user-groups.service';

@Component({
  selector: 'gcv-user-group-archive',
  templateUrl: './user-group-archive.component.html',
  styleUrls: ['./user-group-archive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupArchiveComponent implements OnChanges {
  @Input() archivedUsers = [];
  @Input() archivedGroups = [];
  @Output() userSelected = new EventEmitter();
  data: any;
  noHistory = true;
  selectedItems = [];
  displayedColumns: string[] = ['select', 'image', 'fullName', 'invitation_status', 'archive_date'];
  dataMap = {
    fullName: 'Name',
    invitation_status: 'Status',
    select: '',
    image: '',
    archive_date: 'Archive Date',
  };

  constructor(private groupApp: UserGroupService) {
    this.groupApp.selectedUsers.subscribe(user => {
      if (user.length === 0) {
        this.selectedItems = [];
      }
    });
  }

  ngOnChanges() {
    this.data = this.archivedUsers.map(user => {
      user.fullName = user.firstName + ' ' + user.lastName;
      if (user.role === 'dispensary_admin' || user.role === 'bank_admin') {
        user.viewRole = 'Admin';
      } else if (user.role === 'dispensary_user' || user.role === 'bank_user') {
        user.viewRole = 'Standard';
      }
      return user;
    });
  }

  itemSelected(e) {
    this.selectedItems = e;
    this.userSelected.emit(e);
  }
}
