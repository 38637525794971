import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'gcv-mot-warning-dialog',
  styleUrls: ['./mot-warning-dialog.scss'],
  templateUrl: 'mot-warning-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotWarningDialogComponent {
  isDispensary = true;
  isAllDispensaries = false;
  title = '';
  subtitle = '';

  constructor(
    private matDialogRef: MatDialogRef<MotWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isDispensary = data.isDispensary;
    this.isAllDispensaries = data.isAllDispensaries;

    if (this.isDispensary) {
      this.title = 'Default Method of Transportation';
      this.subtitle = 'This record will now be the default option shown when creating a new deposit.';
    } else if (!this.isDispensary && !this.isAllDispensaries) {
      this.title = 'Require service for this account?';
      this.subtitle =
        'By clicking continue, the business will be required to use this service provided by you to physically transfer deposits.';
    } else {
      this.title = 'Require service for all accounts?';
      this.subtitle =
        // tslint:disable-next-line:max-line-length
        'By clicking continue, every business will receive a notification letting them know that they must use this required method of transportation.';
    }
  }

  handleCancel() {
    this.matDialogRef.close({ confirmed: false });
  }

  handleContinue() {
    this.matDialogRef.close({ confirmed: true });
  }
}
