import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-onboarding-progress-bar',
  templateUrl: './onboarding-progress-bar.component.html',
  styleUrls: ['./onboarding-progress-bar.component.scss'],
})
export class OnboardingProgressBarComponent implements OnInit {
  // `currentStep` is the step the user needs to complete next
  // `selected` is the step the user has selected right now
  @Input() stepStatus: { label: string; complete: boolean; selected: boolean; currentStep: boolean }[];
  @Output() clickEmitter = new EventEmitter<{
    label: string;
    complete: boolean;
    selected: boolean;
    currentStep: boolean;
  }>();

  constructor() {}

  ngOnInit() {}

  handleClick(index) {
    if (this.stepStatus[index].currentStep || this.stepStatus[index].complete) {
      this.clickEmitter.emit(index);
    }
  }
}
