import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@gcv/shared';

@Component({
  selector: 'gcv-user-interface-review-account-owner-card',
  templateUrl: './review-account-owner-card.component.html',
  styleUrls: ['./review-account-owner-card.component.scss'],
})
export class ReviewAccountOwnerCardComponent implements OnInit {
  @Input() user: User;
  @Output() reviewButtonClicked = new EventEmitter<User>();

  constructor() {}

  ngOnInit() {}

  handleReviewButtonClicked() {
    this.reviewButtonClicked.emit(this.user);
  }
}
