import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-button',
  templateUrl: './button.component.html',
  styleUrls: ['shared.button.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() disabled = false;
  @Input() type = '';
  @Input() styles: string;
  @Input() fullWidth = false;
  @Output() clickEvent = new EventEmitter();
  @Input() icon = '';

  constructor() {}

  ngOnInit() {}

  calculateClasses(): { [key: string]: any } {
    const classes: { [key: string]: any } = {
      btn: true,
    };

    if (this.type.includes('pill')) {
      classes['pill'] = true;
    }

    if (this.type.includes('large')) {
      classes['large'] = true;
    }

    if (this.type.includes('file')) {
      classes['uploadWrapper'] = true;
    }
    if (this.type.includes('thin')) {
      classes['thin'] = true;
    }
    if (this.type.includes('link')) {
      classes['link'] = true;
    } else if (this.type.includes('tertiary')) {
      classes['tertiary'] = true;
    } else if (this.type.includes('secondary')) {
      classes['secondary'] = true;
    } else if (this.type.includes('cancel')) {
      classes['cancel'] = true;
    } else if (this.type.includes('danger')) {
      classes['danger'] = true;
    } else {
      classes['primary'] = true;
    }

    if (this.type.includes('fullWidth') || this.type.includes('full-width') || this.fullWidth) {
      classes['full-width'] = true;
    }

    if (this.type.includes('no-max-width') || this.type.includes('noMaxWidth')) {
      classes['no-max-width'] = true;
    }

    return classes;
  }

  addBaseClasses(classes: { [key: string]: any }) {
    classes.primary = true;
  }

  addCancelClasses(classes: { [key: string]: any }) {
    classes.cancel = true;
  }

  handleClick(e?) {
    this.clickEvent.emit(e);
  }

  getCypressName() {
    if (!this.buttonText) {
      this.buttonText = 'fix-me'; // FIXME
    }
    return this.buttonText.replace(/\s+|&|'|!/g, '-').toLowerCase();
  }
}
