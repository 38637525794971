import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, Configuration } from '@gcv/services';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Config, parseGcvConfig } from '../services/config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomErrorHandler } from '../services/customErrorHandler.service';
import { httpClientFactory } from '../services/customHttpClient/httpClientFactory';
import { LoggerService } from '../services/logger/logger.service';
import { JoyrideModule } from 'ngx-joyride';
import { RollbarService } from '../services/rollbar/rollbar.service';
import { rollbarFactory } from '../services/rollbar/rollbar.config';
import { GcvCommonModule } from '../components/common.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { StateModule } from '@user-interface/gcv-state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AmplifyAngularModule,
    ApiModule.forRoot(getApiConfig),
    BrowserAnimationsModule,
    AppRoutingModule,
    GcvCommonModule,
    BrowserModule,
    StateModule,
    JoyrideModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearHash: true,
        clearQueryParams: true,
      },
    }),
  ],
  providers: [
    AmplifyService,
    { provide: Config, useValue: parseGcvConfig(environment) },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HttpClient, useFactory: httpClientFactory, deps: [LoggerService, HttpHandler] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    angulartics2GoogleAnalytics.startTracking();
  }
}

const apiConfig = new Configuration({
  withCredentials: false,
  apiKeys: {}, // TODO look into setting all headers here instead of
  ...(environment.basePath
    ? {
        basePath: environment.basePath,
      }
    : {}),
});

export function getApiConfig() {
  return apiConfig;
}

export { apiConfig };
