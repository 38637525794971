import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'einPipe',
})
export class EinPipe implements PipeTransform {
  transform(einValue: string): any {
    try {
      if (einValue !== '' && einValue.length === 9) {
        const ein = einValue.substr(0, 2) + '-' + einValue.substr(2, 9);
        return ein;
      } else {
        return einValue;
      }
    } catch (error) {
      return einValue;
    }
  }

  parse(einValue: string): any {
    if (einValue !== '' && einValue.length === 10) {
      const ein = einValue.replace('-', '');
      return ein;
    } else {
      return einValue;
    }
  }
}
