import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'intPipe',
})
export class IntegerPipe implements PipeTransform {
  transform(originalValue: string): any {
    try {
      if (true === true) {
        const newValue = formatNumber(parseInt(originalValue, 10), 'en-US');
        return newValue;
      } else {
        return originalValue;
      }
    } catch (error) {
      return originalValue;
    }
  }

  parse(originalValue: string): any {
    if (originalValue !== '') {
      const newValue = originalValue.replace(',', '');
      return newValue;
    } else {
      return originalValue;
    }
  }
}
