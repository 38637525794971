import { Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserGroupService } from '../../../services/user-groups-service/user-groups.service';

@Component({
  selector: 'gcv-user-wrapper',
  templateUrl: './user-wrapper.component.html',
  styleUrls: ['./user-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWrapperComponent implements OnInit {
  @Input()
  orgName: string;
  @Input()
  tabs = [{ name: 'All Users' }, { name: 'Groups' }, { name: 'Archive' }];
  @Input()
  orgType: string;
  selectedIndex = 0;
  filterText = '';
  selectedUsers = [];

  constructor(
    private router: Router,
    private groupApp: UserGroupService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.pipe(distinctUntilChanged()).subscribe(() => {
      this.parseRoute(this.location.path(false));
    });
  }

  ngOnInit() {
    this.groupApp.selectedUsers.subscribe(users => {
      this.selectedUsers = users;
      this.cdr.markForCheck();
    });
    this.parseRoute(this.location.path(false));
  }

  parseRoute(routePath: string) {
    switch (routePath) {
      case `/secure/${this.orgType}/users/overview`:
        this.selectedIndex = 0;
        break;
      case `/secure/${this.orgType}/users/groups`:
        this.selectedIndex = 1;
        break;
      case `/secure/${this.orgType}/users/archive`:
        this.selectedIndex = 2;
        break;
    }
  }

  tabChanged(index: number) {
    switch (index) {
      case 0:
        this.router.navigate([`/secure/${this.orgType}/users/overview`]);
        break;
      case 1:
        this.router.navigate([`/secure/${this.orgType}/users/groups`]);
        break;
      case 2:
        this.router.navigate([`/secure/${this.orgType}/users/archive`]);
        break;
    }
  }

  filterItems() {
    this.groupApp.filterText.next(this.filterText);
  }

  handleRemoveClicked() {
    this.groupApp.removeUserFromArchive.next(this.selectedUsers);
  }

  handleArchiveUser() {
    this.groupApp.archiveUsers.next(this.selectedUsers);
  }

  handleCreateGroup() {
    if (this.selectedUsers.length === 0) {
      this.groupApp.createGroup.next({
        selectedUsers: this.selectedUsers,
        createEmpty: true,
      });
      this.groupApp.selectedUsers.next([]);
    } else {
      this.groupApp.createGroup.next({
        selectedUsers: this.selectedUsers,
        createEmpty: false,
      });
      this.groupApp.selectedUsers.next([]);
    }
  }
}
