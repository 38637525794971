import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-dispensary-due-diligence',
  templateUrl: './dispensary-due-diligence.component.html',
  styleUrls: ['./dispensary-due-diligence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispensaryDueDiligenceComponent implements OnInit {
  @Input() title: string;
  @Input() break = false;
  @Input() subTitle: string;
  @Input() buttonStatus = 'hidden';
  @Input() number: number;
  @Input() status = { business: false, compliance: false, organization: false };
  @Input() instance = 'business';
  @Output() handleButtonPressed = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.status[this.instance]) {
      this.buttonStatus = 'edit';
    } else if (this.instance === 'business') {
      this.buttonStatus = 'start';
    } else if (this.instance === 'organization') {
      this.buttonStatus = this.status['business'] ? 'start' : 'hidden';
    } else if (this.instance === 'compliance') {
      this.buttonStatus = this.status['business'] && this.status['organization'] ? 'start' : 'hidden';
    }
  }

  buttonPressed() {
    this.handleButtonPressed.emit();
  }
}
