import { Action } from '@ngrx/store';
import { Sale } from '@gcv/shared';

interface PaginatedSalesPayload {
  dispensaryId: string;
  startDate: any;
  endDate: any;
  limit?: string;
  status?: string;
  key?: string;
}

export enum SalesActionTypes {
  LoadSalesByDepositId = '[Sales] Load Sales By Deposit Id',
  SalesLoadedByDepositId = '[Sales] Sales Loaded By Deposit Id',

  LoadAllUndepositedSales = '[Sales] Load All Undeposited Sales',
  AllUndepositedSalesLoaded = '[Sales] All Undeposited Sales Loaded',

  LoadPaginatedSalesByDateRange = '[Sales] Load Paginated Sales By Date Range',
  PaginatedSalesLoadedByDateRange = '[Sales] Paginated Sales Loaded By Date Range',

  ResetSales = '[Sales] Reset Sales',
}

export class LoadSalesByDepositId implements Action {
  readonly type = SalesActionTypes.LoadSalesByDepositId;

  constructor(public payload: { depositId: string; dispensaryId: string }) {}
}

export class LoadAllUndepositedSales implements Action {
  readonly type = SalesActionTypes.LoadAllUndepositedSales;

  constructor(public payload: { dispensaryId: string }) {}
}

export class AllUndepositedSalesLoaded implements Action {
  readonly type = SalesActionTypes.AllUndepositedSalesLoaded;

  constructor(public payload: Sale[]) {}
}

export class SalesLoadedByDepositId implements Action {
  readonly type = SalesActionTypes.SalesLoadedByDepositId;

  constructor(public payload: Sale[]) {}
}

export class LoadPaginatedSalesByDateRange implements Action {
  readonly type = SalesActionTypes.LoadPaginatedSalesByDateRange;

  constructor(public payload: PaginatedSalesPayload) {}
}

export class PaginatedSalesLoadedByDateRange implements Action {
  readonly type = SalesActionTypes.PaginatedSalesLoadedByDateRange;

  constructor(public payload: Sale[]) {}
}

export class ResetSales implements Action {
  readonly type = SalesActionTypes.ResetSales;
}

export type SalesActions =
  | LoadSalesByDepositId
  | SalesLoadedByDepositId
  | LoadPaginatedSalesByDateRange
  | PaginatedSalesLoadedByDateRange
  | LoadAllUndepositedSales
  | AllUndepositedSalesLoaded
  | ResetSales;
