import { Injectable } from '@angular/core';
import { mockSale } from './mock.data';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  sales = {};

  constructor() {
    for (let i = 0; i < 100; i++) {
      const sale = mockSale('123456', Math.floor(Math.random() * 1000000000));
      this.sales[sale.id] = sale;
    }

    for (let i = 0; i < 100; i++) {
      const sale = mockSale('123453236', Math.floor(Math.random() * 1000000000));
      this.sales[sale.id] = sale;
    }
  }

  getSales(depositId, id) {
    return Object.values(this.sales).filter((sales: any) => sales.dispensary_id === id);
  }
}
