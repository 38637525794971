import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipPipe',
})
export class ZipPipe implements PipeTransform {
  transform(zipValue: string): any {
    try {
      if (zipValue !== '' && zipValue.length === 9) {
        const zipCode = zipValue.substr(0, 5) + '-' + zipValue.substr(5, 9);
        return zipCode;
      }
    } catch (error) {
      return zipValue;
    }
  }

  parse(zipValue: string): any {
    if (zipValue !== '' && zipValue.length === 10) {
      const zipCode = zipValue.replace('-', '');
      return zipCode;
    } else return zipValue;
  }
}
