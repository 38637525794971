import * as fromNotifications from './notifications.reducer';
import { Notification } from '@gcv/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectNotificationsState = createFeatureSelector<fromNotifications.NotificationState>('notifications');

export const selectNotificationsIds = createSelector(
  selectNotificationsState,
  fromNotifications.selectNotificationIds
);

export const selectNotificationsEntities = createSelector(
  selectNotificationsState,
  fromNotifications.selectNotificationEntities
);

export const selectNotificationsAll = createSelector(
  selectNotificationsState,
  fromNotifications.selectAllNotifications
);

export const selectIncompleteNotifications = createSelector(
  selectNotificationsAll,
  (notifications: Notification[]) => {
    return notifications.filter(notification => !notification.completed);
  }
);

export const selectCompleteNotification = createSelector(
  selectNotificationsAll,
  (notifications: Notification[]) => {
    return notifications.filter(notification => notification.completed);
  }
);
