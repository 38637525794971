import { DataPersistence } from '../../utils/data.persistance';
import { AggregatesState } from './aggregates.reducer';
import { Effect } from '@ngrx/effects';
import {
  AggregatesActionTypes,
  BankAggregateLoaded,
  DispensaryAggregateLoaded,
  FincenDepositAggregateLoaded,
  FincenReportAggregateLoaded,
  LoadBankAggregate,
  LoadDispensaryAggregate,
  LoadFincenDepositAggregate,
  LoadFincenReportAggregate,
  LoadSalesAggregate,
  SalesAggregateLoaded,
} from './aggregates.actions';
import { Injectable } from '@angular/core';
import {
  AggregateResponsePayload,
  BankAggregate,
  DispensaryAggregate,
  FincenDepositAggregate,
  FincenReportAggregate,
  SalesAggregate,
} from '../../typings/aggregates';
import { map } from 'rxjs/operators';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class AggregatesEffect {
  @Effect() loadBankAggregate$ = this.dataPersistence.fetch(AggregatesActionTypes.LoadBankAggregate, {
    run: (action: LoadBankAggregate, state: AggregatesState) => {
      return this.sdk.aggregatesCompanyIdTypeGet(action.payload.type, action.payload.companyId).pipe(
        map(
          (res: AggregateResponsePayload) =>
            new BankAggregateLoaded({
              id: res.company_id,
              data: res.data as BankAggregate,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: AggregatesActionTypes.LoadBankAggregate, message: err });
    },
  });

  @Effect() loadSalesAggregate$ = this.dataPersistence.fetch(AggregatesActionTypes.LoadSalesAggregate, {
    run: (action: LoadSalesAggregate, state: AggregatesState) => {
      return this.sdk.aggregatesCompanyIdTypeGet(action.payload.type, action.payload.companyId).pipe(
        map(
          (res: AggregateResponsePayload) =>
            new SalesAggregateLoaded({
              id: res.company_id,
              data: res.data as SalesAggregate,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: AggregatesActionTypes.LoadSalesAggregate, message: err });
    },
  });

  @Effect() loadFincenDepositAggregate$ = this.dataPersistence.fetch(AggregatesActionTypes.LoadFincenDepositAggregate, {
    run: (action: LoadFincenDepositAggregate, state: AggregatesState) => {
      return this.sdk.aggregatesCompanyIdTypeGet(action.payload.type, action.payload.companyId).pipe(
        map(
          (res: AggregateResponsePayload) =>
            new FincenDepositAggregateLoaded({
              id: res.company_id,
              data: res.data as FincenDepositAggregate,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: AggregatesActionTypes.LoadFincenDepositAggregate, message: err });
    },
  });

  @Effect() loadFincenReportAggregate$ = this.dataPersistence.fetch(AggregatesActionTypes.LoadFincenReportAggregate, {
    run: (action: LoadFincenReportAggregate, state: AggregatesState) => {
      return this.sdk
        .aggregatesCompanyIdTypeGet(action.payload.type, action.payload.companyId)
        .pipe(
          map((res: AggregateResponsePayload) => new FincenReportAggregateLoaded(res.data as FincenReportAggregate[]))
        );
    },
    onError: err => {
      return new AddError({ action: AggregatesActionTypes.LoadFincenReportAggregate, message: err });
    },
  });

  @Effect() loadDispensaryAggregate$ = this.dataPersistence.fetch(AggregatesActionTypes.LoadDispensaryAggregate, {
    run: (action: LoadDispensaryAggregate, state: AggregatesState) => {
      return this.sdk.aggregatesCompanyIdTypeGet(action.payload.type, action.payload.companyId).pipe(
        map(
          (res: AggregateResponsePayload) =>
            new DispensaryAggregateLoaded({
              id: res.company_id,
              data: res.data as DispensaryAggregate,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: AggregatesActionTypes.LoadDispensaryAggregate, message: err });
    },
  });

  constructor(private sdk: DefaultService, private dataPersistence: DataPersistence<AggregatesState>) {}
}
