import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcv-collapsing-shelf',
  templateUrl: './collapsing-shelf.component.html',
  styleUrls: ['./collapsing-shelf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsingShelfComponent implements OnInit {
  @Input() title = '';
  @Input() toggled = false;

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.toggled = !this.toggled;
  }
}
