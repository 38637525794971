import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { Dispensary, DocumentChecker } from '@gcv/shared';
import { map } from 'rxjs/operators';
import { DispensaryFacade } from '@user-interface/gcv-state';

@Component({
  selector: 'app-pending-container',
  templateUrl: './pending-container.component.html',
  styleUrls: ['./pending-container.component.scss'],
})
export class PendingContainerComponent implements OnInit {
  constructor(private router: Router, private dispFacade: DispensaryFacade) {}
  public dispensaries$: Observable<Dispensary[]>;

  static dispensaryStatus(dispensary): boolean {
    return (
      DocumentChecker.isGcvReviewed(dispensary) &&
      DocumentChecker.areDocumentsUploaded(dispensary) &&
      !DocumentChecker.isBankReviewed(dispensary, dispensary.documentContext ? dispensary.documentContext : 'default')
    );
  }

  ngOnInit() {
    this.setupStream();
  }

  setupStream() {
    this.dispensaries$ = this.dispFacade.selectAllDispensaries().pipe(
      map((dispensaries: Dispensary[]) => {
        return dispensaries.filter((dispensary: Dispensary) => PendingContainerComponent.dispensaryStatus(dispensary));
      })
    );
  }

  handleButtonClicked(disp: Dispensary) {
    const data: NavigationExtras = {
      queryParams: {
        account: disp.id,
      },
    };
    this.router.navigate(['/secure/bank/account/review'], data).then(() => {});
  }
}
