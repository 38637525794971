import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@gcv/shared';

@Component({
  selector: 'app-dispensary-admin-verification-presenter',
  templateUrl: './dispensary-admin-verification-presenter.component.html',
  styleUrls: ['./dispensary-admin-verification-presenter.component.scss'],
})
export class DispensaryAdminVerificationPresenterComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() user: User;
  @Input() questions: any[] = [];
  @Output() verifyEvent = new EventEmitter<{ user: User; answers: any }>();
  @Output() closeEvent = new EventEmitter<User>();
  public verificationFormGroup: FormGroup;
  public selectedQuestions: any[];
  public resets = 0;

  constructor() {}

  ngOnChanges() {
    if (this.questions && this.questions.length > 0) {
      this.selectedQuestions = this.questions.splice(0, 3);
      this.setupForm();
    }
  }

  verifyUser() {
    this.verifyEvent.emit({ user: this.user, answers: this.verificationFormGroup.getRawValue() });
  }

  getNewQuestion(index: number) {
    if (this.resets < 5) {
      this.resets++;
      if (this.selectedQuestions) {
        this.selectedQuestions.splice(index, 1);
        const newQuestion: any[] = this.questions.splice(0, 1);
        this.selectedQuestions.splice(index, 0, newQuestion[0]);
      }
      this.setupForm();
    }
  }

  setupForm() {
    this.verificationFormGroup = new FormGroup(this.formatForm());
  }

  formatForm() {
    const formcontrols = {};
    this.selectedQuestions.forEach(question => {
      let val = '';
      if (this.verificationFormGroup && this.verificationFormGroup.controls[question.code]) {
        val = this.verificationFormGroup.controls[question.code].value;
      }

      formcontrols[question.code] = new FormControl(val, Validators.required);
    });
    return formcontrols;
  }

  close() {
    this.closeEvent.emit(this.user);
  }
}
