import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-editable-bank-transport-vendors',
  templateUrl: './editable-bank-transport-vendors.component.html',
  styleUrls: ['./editable-bank-transport-vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableBankTransportVendorsComponent implements OnInit {
  @Input() vendors;
  @Input() allVendors;
  @Input() dispensaryView = true;
  @Output() favoriteVendor = new EventEmitter();
  @Output() addVendor = new EventEmitter();
  editActive = false;
  title = '';
  subtitle = '';

  constructor() {}

  ngOnInit() {
    this.title = 'Provided Services';
    this.subtitle =
      'Choose which of your service providers are available to this business. Create a new service option in Transportation Settings.';
  }

  handleFavorite(vendor, i) {
    this.favoriteVendor.emit({ type: 'bank', data: vendor });
  }

  edit() {
    this.editActive = !this.editActive;
  }

  addNewMethod() {
    this.addVendor.emit();
  }

  toggleActive(vendor, i) {
    const isAdded = this.isAlreadyAdded(vendor);
    this.vendors = this.vendors.filter(dispVendor => dispVendor.id !== vendor.id);
    if (!isAdded) {
      this.vendors.push(vendor);
    }
  }

  isAlreadyAdded(vendor) {
    let isAdded = false;

    this.vendors.forEach(addedVendors => {
      if (addedVendors.id === vendor.id) {
        isAdded = true;
      }
    });
    return isAdded;
  }
}
