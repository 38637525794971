import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@gcv/shared';
import { StaffActionTypes } from './staff.actions';

export interface StaffState extends EntityState<User> {}

export const adapterStaff: EntityAdapter<User> = createEntityAdapter<User>();

export const initialStaffState: StaffState = adapterStaff.getInitialState();

export function staffReducer(state = initialStaffState, action): StaffState {
  switch (action.type) {
    case StaffActionTypes.OrganizationStaffLoaded:
      return adapterStaff.addMany(action.payload, state);
    case StaffActionTypes.OrganizationStaffUpdated:
      return adapterStaff.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case StaffActionTypes.AllStaffLoaded:
      return adapterStaff.addMany(action.payload, state);
    case StaffActionTypes.OrganizationStaffCreated:
      return adapterStaff.upsertOne(action.payload, state);
    case StaffActionTypes.StaffManuallyVerified:
      return adapterStaff.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case StaffActionTypes.ResetStaff:
      return initialStaffState;
    default:
      return state;
  }
}

export const {
  selectIds: selectStaffIds,
  selectEntities: selectStaffEntities,
  selectAll: selectAllStaff,
} = adapterStaff.getSelectors();
