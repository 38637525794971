import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gcv-verified-users-transportation',
  styleUrls: ['./verified-users-transportation.component.scss'],
  templateUrl: './verified-users-transportation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifiedUsersTransportationComponent {
  @Input() vendors;
  @Input() dispensary = true;
  @Input() title = 'Deposit Transportation Users';
  @Input() subtitle = 'These are users who belong to a group with the Method of Transportation role.';
  @Output() manageGroup = new EventEmitter();
  @Output() favoriteUser = new EventEmitter();

  constructor() {}

  handleManageGroups() {
    this.manageGroup.next('');
  }

  handleFavorite(vendor, i) {
    this.favoriteUser.next({ type: 'user', data: vendor });
  }
}
