import { FormControl, FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditGroupAddUserDialogComponent } from './edit-group-add-user-dialog/edit-group-add-user-dialog.component';

@Component({
  selector: 'gcv-edit-group-wrapper',
  templateUrl: './edit-group-wrapper.component.html',
  styleUrls: ['./edit-group-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGroupWrapperComponent implements OnInit, OnDestroy, OnChanges {
  filterText = '';
  filteredUsers = [];
  users = [];
  userMap = {};
  @Input() group: any;

  @Input() allUsers: any;
  @Input() orgType = '';
  displayedColumns: string[] = ['firstName', 'lastName', 'invitation_status'];
  @Output() saveClicked = new EventEmitter();
  @Output() backClicked = new EventEmitter();
  roles: { value: string; viewValue: string }[];
  roleGroup: FormGroup;
  dataMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    invitation_status: 'Status',
  };
  editingTitle = false;
  editTitle: FormGroup;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.allUsers.forEach(user => {
      this.userMap[user.id] = user;
    });

    this.filteredUsers = this.group.users.map(id => {
      if (this.userMap[id]) {
        return this.userMap[id];
      }
    });
    if (this.orgType === 'dispensary') {
      this.roles = [
        { value: 'dispensary_user', viewValue: 'User' },
        { value: 'dispensary_admin', viewValue: 'Admin' },
        { value: 'dispensary_account_owner', viewValue: 'Primary Contact' },
      ];
    } else if (this.orgType === 'bank') {
      this.roles = [
        { value: 'bank_primary_contact', viewValue: 'Primary Contact' },
        { value: 'bank_admin', viewValue: 'Admin' },
        { value: 'bank_fincen_preparer', viewValue: 'Fincen Preparer' },
        { value: 'bank_fincen_approver', viewValue: 'Fincen Approver' },
        { value: 'bank_user', viewValue: 'User' },
      ];
    }

    this.roleGroup = new FormGroup({
      roleControl: new FormControl(this.group.roles ? [this.group.roles[0]] : []),
    });
    this.editTitle = new FormGroup({
      title: new FormControl(this.group.name),
    });
  }

  ngOnDestroy() {
    this.saveClicked.emit(this.group);
  }

  ngOnChanges() {
    this.filteredUsers = this.group.users.map(id => this.userMap[id]);
  }

  onChange(e, i) {
    // Bad Method need to rework when we have more roles..
    this.group.roles[0] = `${this.orgType}_${e.value}`;
  }

  chooseRole(e) {
    this.group.roles = [e.value];
  }

  filterItems() {
    if (this.filterText.length === 0) {
      this.filteredUsers = this.group.users.map(id => this.userMap[id]);
    }
    this.filteredUsers = this.group.users
      .map(id => this.userMap[id])
      .filter(user => {
        const filterLower = this.filterText.toLowerCase();
        if (user.firstName.toLowerCase().includes(filterLower) || user.lastName.toLowerCase().includes(filterLower)) {
          return user;
        }
      });
  }

  handleBack() {
    this.backClicked.emit();
  }

  handleButtonClicked() {
    const dialog = this.dialog.open(EditGroupAddUserDialogComponent, {
      width: '660px',
      height: '700px',
      data: {
        allUsers: this.allUsers,
        group: this.group,
        userMap: this.userMap,
      },
    });
    dialog.afterClosed().subscribe(data => {
      if (data && data.group) {
        this.group = data.group;
        this.filteredUsers = this.group.users.map(id => this.userMap[id]);
        this.saveClicked.emit(this.group);
      }
    });
  }

  handleEditClicked(e) {
    this.editingTitle = true;
  }

  handleEditSaveClicked(e) {
    const newTitle = this.editTitle.controls.title.value;
    if (newTitle && newTitle.length > 1) {
      this.editingTitle = false;
      if (newTitle !== this.group.name) {
        this.group.name = newTitle;
        this.saveClicked.emit(this.group);
      }
    }
  }
}
