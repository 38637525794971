import { Action } from '@ngrx/store';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';

export enum FincenActionTypes {
  LoadFincen = '[Fincen] Load Fincen',
  FincenLoaded = '[Fincen] Fincen Loaded',

  UpdateFincen = '[Fincen] Update Fincen',
  FincenUpdated = '[Fincen] Fincen Updated',

  ExportReportAsXml = '[Fincen] Export Report as Xml',

  CreateFincen = '[Fincen] Create Fincen',
  FincenCreated = '[Fincen] Fincen Created',

  ResetFincen = '[Fincen] Reset Fincen',
}

export class ExportReportAsXml implements Action {
  readonly type = FincenActionTypes.ExportReportAsXml;

  constructor(public payload: { reports: (FincenCtrReport | FincenSarReport)[]; bankName: string }) {}
}

export class LoadFincen implements Action {
  readonly type = FincenActionTypes.LoadFincen;

  constructor(public payload: { bankId: string; dispensaryId: string }) {}
}

export class FincenLoaded implements Action {
  readonly type = FincenActionTypes.FincenLoaded;

  constructor(public payload: (FincenCtrReport | FincenSarReport)[]) {}
}

export class UpdateFincen implements Action {
  readonly type = FincenActionTypes.UpdateFincen;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class FincenUpdated implements Action {
  readonly type = FincenActionTypes.FincenUpdated;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class CreateFincen implements Action {
  readonly type = FincenActionTypes.CreateFincen;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class FincenCreated implements Action {
  readonly type = FincenActionTypes.FincenCreated;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class ResetFincen implements Action {
  readonly type = FincenActionTypes.ResetFincen;
}

export type FincenActions =
  | LoadFincen
  | FincenLoaded
  | UpdateFincen
  | FincenUpdated
  | CreateFincen
  | FincenCreated
  | ResetFincen
  | ExportReportAsXml;
