import { trigger, transition, style, query, animateChild, animate, group, animation } from '@angular/animations';

export const slideInAnimation = animation([
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ],
    { optional: true }
  ),
  query(':enter', [style({ left: '-100%' })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate('200ms ease-out', style({ left: '100%', opacity: 0, display: 'none' }))], {
      optional: true,
    }),
    query(':enter', [animate('500ms ease-out', style({ left: '0%', opacity: 1 }))], { optional: true }),
  ]),
  query(':enter', animateChild(), { optional: true }),
]);
