// tslint:disable max-line-length variable-name
export class Config {
  logLevel: string;
  globalStyle: string;
  amplify: AuthConfig;
  dataConfig: DataConfig;
  storageConfig: StorageConfig;
  env: string;
}

export class StorageConfig {
  document: string;
  salesFile: string;
  regulatoryGuide: string;
}

export class AuthConfig {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
}

export class DataConfig {
  cognitoDomains: CognitoSyncSource[]; // user data - UI will route to the 'secure' route once the first domain in this array is sync-ed.
  // lambdaDomains: LambdaDataSource[];   // typicially application data
  gcvDomains: GcvApiSource[];
}

export class GcvApiSource {
  apiEndpoint: string;
  domainName: string;
  apiResourceTemplate: string; // the service API apiResourceTemplate string. example:  fi: 'id', 'mrbId': 'fi/:id/managed/:mrbId'
  keyNames: string[]; // the parent and object key names in the order they appear in API resource templates. example: fi: [id, propertyName]
  getAppModelKey: (obj: object) => string; // function used by the reducer to produce an object key in the appState
  isDomainKey: (key: string) => boolean; // function used by the reducer to check if a given key is a key for an object in this domain
}

export class CognitoSyncSource {
  datasetName: string;
  domainName: string; // the cognito sync dataset name. also indicates the URL to get the schema i.e. http://APP_URL/domainName.json
  keyNames: string[]; // the parent and object key names in the order they appear in routes and resource strings. example: application: [accountKey, applicationKey]
  schemaVersion: string;
  getAppModelKey: (obj: object) => string; // function used by the reducer to produce an object key in the appState
  isDomainKey: (key: string) => boolean; // function used by the reducer to check if a given key is a key for an object in this domain
}
/*
export class LambdaDataSource {
  domainName: string;
  keyNames: string[];          // the parent and object key names in the order they appear in routes and resource strings. example: application: [accountKey, applicationKey]
  schemaVersion: string;
  region: string;
  functionName: string;        // account-user-crud | app-user-crud
  apiResourceTemplate: string; // the service API apiResourceTemplate string. example:  account: 'accounts', application: 'accounts/:accountKey/applications'
  getAppModelKey: (obj: object) => string;  // function used by the reducer to produce an object key in the appState
  isDomainKey: (key: string) => boolean;    // function used by the reducer to check if a given key is a key for an object in this domain
}
*/

// converts the persisted form of the config to the runtime form by changing the getAppModel and isDomainKey definitions to functions.
export function parseGcvConfig(config) {
  /*
  for (const lambdaDomain of config.dataConfig.lambdaDomains) {
    lambdaDomain.getAppModelKey = new Function(lambdaDomain.getAppModelKey.arguments, lambdaDomain.getAppModelKey.body);
    lambdaDomain.isDomainKey = new Function(lambdaDomain.isDomainKey.arguments, lambdaDomain.isDomainKey.body);
  }*/
  return config;
}
