import { Action } from '@ngrx/store';
import { Notification } from '@gcv/shared';

export enum NotificationActionTypes {
  LoadNotifications = '[Notification] Load Notifications',
  NotificationsLoaded = '[Notification] Notifications Loaded',

  UpdateNotification = '[Notification] Update Notification',
  NotificationUpdated = '[Notification] Notification Updated',

  ResetNotifications = '[Notification] Reset',
}

export class LoadNotifications implements Action {
  readonly type = NotificationActionTypes.LoadNotifications;

  constructor(public payload: { userId: string }) {}
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationActionTypes.NotificationsLoaded;

  constructor(public payload: Notification[]) {}
}

export class UpdateNotification implements Action {
  readonly type = NotificationActionTypes.UpdateNotification;

  constructor(public payload: Notification) {}
}

export class NotificationUpdated implements Action {
  readonly type = NotificationActionTypes.NotificationUpdated;

  constructor(public payload: Notification) {}
}

export class ResetNotifications implements Action {
  readonly type = NotificationActionTypes.ResetNotifications;
}

export type NotificationActions =
  | LoadNotifications
  | NotificationsLoaded
  | UpdateNotification
  | NotificationUpdated
  | ResetNotifications;
