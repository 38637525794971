import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { User } from '@gcv/shared';
import { UserGroupService } from '../../../services/user-groups-service/user-groups.service';

@Component({
  selector: 'gcv-user-group-overview',
  templateUrl: './user-group-overview.component.html',
  styleUrls: ['./user-group-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupOverviewComponent implements OnChanges {
  @Input()
  imgSrc: string;
  @Input()
  filteredUsers: User[];
  @Output()
  handleRowClick = new EventEmitter();
  @Output()
  handleSelect = new EventEmitter();
  @Output()
  handleBtnClick = new EventEmitter();
  data: any;
  btnText = 'Invite User';
  title = 'Invite Users';
  selectedItems = [];
  subTitle = 'Invite your staff members to start using Green Check now.';
  displayedColumns: string[] = ['select', 'image', 'fullName', 'invitation_status', 'viewRole', 'lastLogin'];
  dataMap = {
    fullName: 'Name',
    invitation_status: 'Status',
    select: '',
    image: '',
    viewRole: 'Role',
    lastLogin: 'Last Login',
  };

  constructor(private cdr: ChangeDetectorRef, private groupApp: UserGroupService) {
    this.groupApp.selectedUsers.subscribe(users => {
      if (users.length === 0) {
        this.selectedItems = [];
        this.cdr.markForCheck();
      }
    });
  }

  ngOnChanges() {
    this.data = this.filteredUsers.map(user => {
      user.fullName = user.firstName + ' ' + user.lastName;
      if (user.role === 'dispensary_admin' || user.role === 'bank_admin') {
        user.viewRole = 'Admin';
      } else if (user.role === 'dispensary_user' || user.role === 'bank_user') {
        user.viewRole = 'Standard';
      }
      return user;
    });
  }

  handleRowclicked(e) {
    this.handleRowClick.emit(e);
  }

  itemSelected(e) {
    this.selectedItems = e;
    this.handleSelect.emit(e);
  }

  handleButtonClicked() {
    this.handleBtnClick.emit();
  }
}
