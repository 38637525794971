import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Dispensary } from '@gcv/shared';
import { DispensaryActionTypes } from './dispensary.actions';

export interface DispensaryState extends EntityState<Dispensary> {}

export const adapterDispensary: EntityAdapter<Dispensary> = createEntityAdapter<Dispensary>();

export const initialDispensaryState = adapterDispensary.getInitialState();

export function dispensaryReducer(state = initialDispensaryState, action): DispensaryState {
  switch (action.type) {
    case DispensaryActionTypes.DispensaryLoaded:
      return adapterDispensary.upsertOne(action.payload, state);
    case DispensaryActionTypes.AllDispensaryLoaded:
      return adapterDispensary.addMany(action.payload, state);
    case DispensaryActionTypes.DispensaryUpdated:
      return adapterDispensary.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case DispensaryActionTypes.DispensaryCreated:
      return adapterDispensary.addOne(action.payload, state);
    case DispensaryActionTypes.DispensaryGroupsUpdated:
      return adapterDispensary.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case DispensaryActionTypes.bankDueDiligenceApproved:
      return adapterDispensary.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case DispensaryActionTypes.gcvDueDiligenceApproved:
      return adapterDispensary.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case DispensaryActionTypes.ResetDispensary:
      return initialDispensaryState;
    default:
      return state;
  }
}

export const {
  selectIds: selectDispensaryIds,
  selectEntities: selectDispensaryEntities,
  selectAll: selectAllDispensary,
} = adapterDispensary.getSelectors();
