import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@gcv/shared';
import { PrimaryContactActionTypes } from './primaryContact.action';

export interface PrimaryContactState extends EntityState<User> {}

export const adapterPrimaryContact: EntityAdapter<User> = createEntityAdapter<User>();

export const initialDispensaryPrimaryContact = adapterPrimaryContact.getInitialState();

export function dispensaryPrimaryContactReducer(state = initialDispensaryPrimaryContact, action): PrimaryContactState {
  switch (action.type) {
    case PrimaryContactActionTypes.PrimaryContactLoaded:
      return adapterPrimaryContact.addOne(action.payload, state);
    case PrimaryContactActionTypes.PrimaryContactApproved:
      return adapterPrimaryContact.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case PrimaryContactActionTypes.ResetPrimaryContact:
      return initialDispensaryPrimaryContact;
    default:
      return state;
  }
}

export const {
  selectIds: selectPrimaryContactIds,
  selectEntities: selectPrimaryContactEntities,
  selectAll: selectPrimaryContactAll,
} = adapterPrimaryContact.getSelectors();
