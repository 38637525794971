import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gcv-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationButtonComponent {
  @Input() logo: string;
  @Input() navBtnText: string;
  @Input() isActive = false;

  constructor() {}
}
