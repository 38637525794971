import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { Dispensary, DocumentChecker } from '@gcv/shared';
import { getDefaultCompany } from '../../utils';
import { CsvExportService } from '../../services/csv-export/csv-export.service';

@Component({
  selector: 'gcv-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnInit, OnChanges {
  @Output()
  rowClicked = new EventEmitter();
  @Output()
  itemSelected = new EventEmitter();
  @Output()
  paginatedChanged = new EventEmitter();
  @Output()
  deleteClicked = new EventEmitter();
  @Input()
  data: any[];
  @Input()
  columnNames: any[];
  @Input()
  dataMap: any;
  @Input()
  selectedItems: any;
  @Input()
  clickable = false;
  @Input()
  exportable = false;
  @Input()
  deletable = false;
  @Input()
  deleteText = 'Delete Selected';
  @Input()
  altMap;
  @Input()
  dispensaryName = '';
  @Input()
  dispensaryNameMap = {};
  @Input()
  organizationMap = {};
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('paginator')
  paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  dataSource: any;
  displayedColumns: string[] = [];

  constructor(private cdr: ChangeDetectorRef, private exportAsCsv: CsvExportService) {}

  ngOnInit() {
    this.displayedColumns = this.columnNames;
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.displayedColumns = this.columnNames;
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.selectedItems && this.selectedItems.length === 0) {
      this.selection.clear();
      this.cdr.markForCheck();
    }
  }

  editProfile(user) {
    this.rowClicked.emit(user);
  }

  handleClick(data) {
    this.rowClicked.emit(data);
  }

  subscribeToPage($event) {
    this.paginatedChanged.emit($event);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.itemSelected.emit(this.selection.selected);
  }

  handleChange(e, data) {
    if (e) {
      this.selection.toggle(data);
      this.itemSelected.emit(this.selection.selected);
    }
  }

  export() {
    this.exportAsCsv.exportFileAsCsv(
      _.sortBy(this.selection.selected, 'dispensary_id'),
      'sales',
      this.dispensaryName,
      this.dispensaryNameMap
    );
    this.selection.clear();
  }

  deleteSelection(event) {
    event.stopPropagation();
    this.deleteClicked.emit(this.selection.selected);
  }

  isBankReviewed(e: Dispensary) {
    return DocumentChecker.isBankReviewed(e, e.documentContext);
  }

  isGcvReviewed(e: Dispensary) {
    return DocumentChecker.isGcvReviewed(e);
  }

  isDispensaryReviewed(e) {
    return DocumentChecker.isDispensaryReviewed(e, e.documentContext);
  }

  documentsUploaded(e) {
    if (e.documents) {
      return DocumentChecker.areDocumentsUploaded(e);
    } else {
      return false;
    }
  }

  getOrganization(data) {}

  getCellContents() {
    const companies = this.data['companies'];
    return companies && getDefaultCompany(companies) && getDefaultCompany(companies).companyType
      ? this.organizationMap[getDefaultCompany(companies).companyType][getDefaultCompany(companies).id]
      : '';
  }
}
