import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { countries, states } from '../../constants';
import { filterOutEmptyProperties } from '../../utils';
import { Router } from '@angular/router';
import { FormatPhonePipe } from '../../../../../../apps/user-interface/src/pipes/phone-formatter.pipe';
import { ZipPipe } from '../../../../../../apps/user-interface/src/pipes/zip.pipe';
import { EinPipe } from '../../../../../../apps/user-interface/src/pipes/ein.pipe';

@Component({
  selector: 'gcv-create-transportation-vendor',
  templateUrl: './create-transportation-vendor.html',
  styleUrls: ['./create-transportation-vendor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTransportationVendorComponent {
  transportationGroup: FormGroup;
  section = 0;
  states = states;
  countries = countries;
  showHelp = true;
  vendor: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private matDialogRef: MatDialogRef<CreateTransportationVendorComponent>,
    private phonePipe: FormatPhonePipe,
    private zipPipe: ZipPipe,
    private einPipe: EinPipe
  ) {
    this.vendor = data.edit;
    this.transportationGroup = new FormGroup({
      name: new FormControl(this.vendor && this.vendor.name ? this.vendor.name : '', [
        Validators.required,
        Validators.pattern("[a-zA-Z 0-9 ,&':;.#!-]{2,50}"),
      ]),
      street: new FormControl(this.vendor && this.vendor.street ? this.vendor.street : '', [
        Validators.required,
        Validators.pattern("[a-zA-Z 0-9 ,'./#&-]{2,50}"),
      ]),
      city: new FormControl(this.vendor && this.vendor.city ? this.vendor.city : '', [
        Validators.required,
        Validators.pattern("[a-zA-Z ,&'.-]{2,50}"),
      ]),
      state: new FormControl(this.vendor && this.vendor.state ? this.vendor.state : '', Validators.required),
      zipCode: new FormControl(this.vendor && this.vendor.zipCode ? this.vendor.zipCode : '', [
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-?[0-9]{4})?$'),
      ]),
      country: new FormControl(this.vendor && this.vendor.country ? this.vendor.country : '', Validators.required),
      tin: new FormControl(this.vendor && this.vendor.tin ? this.vendor.tin : '', [
        Validators.minLength(9),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]{2}(?:-?[0-9]{7})?$'),
      ]),
      phone: new FormControl(this.vendor && this.vendor.phone ? this.vendor.phone : '', [
        Validators.required,
        Validators.pattern('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'),
      ]),
    });
    this.section = data.section;
  }

  goToTransportation() {
    this.router.navigate(['/secure/dispensary/users/groups']);
  }

  cancel() {
    this.matDialogRef.close();
  }

  save() {
    this.matDialogRef.close({
      data: { ...this.vendor, ...filterOutEmptyProperties(this.transportationGroup.getRawValue()) },
    });
  }

  formatPhone() {
    const defaultValue = this.transportationGroup.get('phone').value;
    this.transportationGroup.get('phone').setValue(this.phonePipe.transform(defaultValue));
  }

  unformatPhone() {
    const defaultValue = this.transportationGroup.get('phone').value;
    if (defaultValue !== undefined) {
      this.transportationGroup.get('phone').setValue(this.phonePipe.parse(defaultValue));
    }
  }

  formatLongZip() {
    const defaultValue = this.transportationGroup.get('zipCode').value;
    if (defaultValue !== undefined && defaultValue.length === 9) {
      this.transportationGroup.get('zipCode').setValue(this.zipPipe.transform(defaultValue));
    }
  }

  unformatLongZip() {
    const defaultValue = this.transportationGroup.get('zipCode').value;
    if (defaultValue !== undefined && defaultValue.length === 10) {
      this.transportationGroup.get('zipCode').setValue(this.zipPipe.parse(defaultValue));
    }
  }

  formatEin() {
    const defaultValue = this.transportationGroup.get('tin').value;
    if (defaultValue !== undefined && defaultValue.length === 9) {
      this.transportationGroup.get('tin').setValue(this.einPipe.transform(defaultValue));
    }
  }

  unformatEin() {
    const defaultValue = this.transportationGroup.get('tin').value;
    if (defaultValue !== undefined && defaultValue.length === 10) {
      this.transportationGroup.get('tin').setValue(this.einPipe.parse(defaultValue));
    }
  }
}
