export enum LogLevelEnum {
  OFF = 0,
  ERROR,
  WARN,
  INFO,
  LOG,
  TRACE,
  DEBUG,
  ALL,
}
