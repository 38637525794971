import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  public createGroup = new BehaviorSubject({});
  public archiveUsers = new BehaviorSubject([]);
  public selectedUsers = new BehaviorSubject([]);
  public removeUserFromArchive = new BehaviorSubject([]);
  public filterText = new BehaviorSubject('');

  constructor() {}
}
