import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from '@gcv/shared';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dynamicNotification' })
export class DynamicNotificationPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(notification: Notification, type: string): string {
    if (type === 'title') {
      switch (notification.type) {
        case 'bank_dispensary_review':
          return `${notification.data.name} is awaiting review`;
        case 'new_deposit':
          return `${notification.email_data.dispensary_name} has a new deposit`;
        case 'new_fincen':
          return `${notification.email_data.dispensary_name} has a new ${notification.data.report_type}`;
        case 'completed_dispensary_review':
          return `You have been approved! You can now start banking!`;
        case 'complete_evs':
          return 'Please verify your identity';
        case 'assign_fincen_report':
          return 'You have been assigned a FinCEN report';
        default:
          return '';
      }
    } else {
      switch (notification.type) {
        case 'bank_dispensary_review':
          return `Submitted On: ${this.datePipe.transform(notification.data.dueDiligence.gcvReview[0].timestamp)}`;
        case 'new_deposit':
          return `Submitted On: ${this.datePipe.transform(notification.data.deposit_date)}`;
        case 'new_fincen':
          return `Received On: ${this.datePipe.transform(notification.data.received_date)}`;
        case 'completed_dispensary_review':
          return `Approved On: ${this.datePipe.transform(notification.created_date)}`;
        case 'complete_evs':
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        case 'assign_fincen_report':
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        default:
          return '';
      }
    }
  }
}
