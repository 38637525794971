import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/route-guards/auth.guard';
import { PreloadSelectedModulesList } from '../custom-module-preload-strategy';
import { CognitoComponent } from './auth/cognito/cognito.component';
import { NewPasswordComponent } from './auth/cognito/new-password/new.password.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: CognitoComponent },
  { path: 'cognito', component: CognitoComponent },
  {
    path: 'user',
    children: [
      {
        path: 'registration',
        component: NewPasswordComponent,
      },
    ],
  },
  { path: 'secure', loadChildren: './secure.module#SecureModule', canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadSelectedModulesList })],
  providers: [PreloadSelectedModulesList],
  exports: [RouterModule],
})
export class AppRoutingModule {}
