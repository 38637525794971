import { NgModule } from '@angular/core';
import { CalculateTotalDepositedPipe } from './calculate-total-deposit.pipe';
import { EinPipe } from './ein.pipe';
import { IntegerPipe } from './integer-formatter.pipe';
import { ZipPipe } from './zip.pipe';
import { FormatPhonePipe } from './phone-formatter.pipe';
import { FormatPercentCustom } from './formatPercent.pipe';
import { FormatCurrencyCustom } from './formatCurrency.pipe';
import { FormatDynamicTitlePipe } from './format-dynamic-title.pipe';
import { TimeFormatterPipe } from './time-formatter.pipe';
import { TruncatePipe } from './truncate.pipe';
import { FormatReportDatePipe } from './format-report-date.pipe';
import { FormatNamePipe } from './mrb-name.pipe';
import { CalculateReportDaysRemaining } from './calculateReportDaysRemaining.pipe';

@NgModule({
  declarations: [
    FormatDynamicTitlePipe,
    TimeFormatterPipe,
    TruncatePipe,
    FormatReportDatePipe,
    CalculateTotalDepositedPipe,
    FormatCurrencyCustom,
    FormatPercentCustom,
    FormatPhonePipe,
    FormatNamePipe,
    CalculateReportDaysRemaining,
    ZipPipe,
    IntegerPipe,
    EinPipe],
  providers: [
    FormatDynamicTitlePipe,
    TimeFormatterPipe,
    TruncatePipe,
    FormatNamePipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    CalculateTotalDepositedPipe,
    FormatCurrencyCustom,
    FormatPercentCustom,
    FormatPhonePipe,
    ZipPipe,
    IntegerPipe,
    EinPipe],
  exports: [
    FormatDynamicTitlePipe,
    TimeFormatterPipe,
    TruncatePipe,
    FormatNamePipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    CalculateTotalDepositedPipe,
    FormatCurrencyCustom,
    FormatPercentCustom,
    FormatPhonePipe,
    ZipPipe,
    IntegerPipe,
    EinPipe]
})

export class PipesModule {}