import * as fromDispensary from './dispensary.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationRoleResolver } from '@gcv/shared';

export const selectDispensaryState = createFeatureSelector<fromDispensary.DispensaryState>('dispensary');

export const selectDispensaryIds = createSelector(
  selectDispensaryState,
  fromDispensary.selectDispensaryIds
);

export const selectDispensaryEntities = createSelector(
  selectDispensaryState,
  fromDispensary.selectDispensaryEntities
);

export const selectDispensaryAll = createSelector(
  selectDispensaryState,
  fromDispensary.selectAllDispensary
);

export const selectDispensaryById = createSelector(
  selectDispensaryEntities,
  (dispensaryDictionary, props) => {
    return dispensaryDictionary[props.dispensaryId];
  }
);

export const selectDispensaryAccountOwnerIds = createSelector(
  selectDispensaryById,
  dispensary => {
    return new OrganizationRoleResolver().getGroupsWithRole(dispensary.groups, 'dispensary_account_owner')[0];
  }
);
