import { select, Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Dispensary, Group } from '@gcv/shared';
import { Observable } from 'rxjs';
import { DispensaryState } from './dispensary.reducer';
import { selectDispensaryAll, selectDispensaryById, selectDispensaryEntities } from './dispensary.selectors';
import {
  ApproveBankDueDiligence,
  ApproveGcvDueDiligence,
  CreateDispensary,
  DispensaryUpdateGroups,
  LoadAllDispensary,
  LoadDispensary,
  ResetDispensary,
  UpdateDispensary,
} from './dispensary.actions';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DispensaryFacade {
  dispensary$: Observable<Dictionary<Dispensary>>;

  constructor(private store: Store<DispensaryState>) {
    this.dispensary$ = store.pipe(select(selectDispensaryEntities));
  }

  getDispensarybyId(dispensaryId: string) {
    this.store.dispatch(new LoadDispensary({ dispensaryId }));
  }

  getAllDispensaries() {
    this.store.dispatch(new LoadAllDispensary());
  }

  putDispensary(dispensary: Dispensary) {
    this.store.dispatch(new UpdateDispensary(dispensary));
  }

  postDispensary(dispensary: Partial<Dispensary>) {
    this.store.dispatch(new CreateDispensary(dispensary));
  }

  putDispensaryGroups(dispensaryId: string, groups: Group[]) {
    this.store.dispatch(new DispensaryUpdateGroups({ dispensaryId, groups }));
  }

  putBankDueDiligence(
    dispensaryId: string,
    bankId: string,
    data: { documentName: string; reviewer: string; status: string }
  ) {
    this.store.dispatch(new ApproveBankDueDiligence({ dispensaryId, bankId, data }));
  }

  putGcvDueDiligence(dispensaryId: string, data: { reviewer: string; status: string }) {
    this.store.dispatch(new ApproveGcvDueDiligence({ dispensaryId, data }));
  }

  resetDispensary() {
    this.store.dispatch(new ResetDispensary());
  }

  selectDispensaryById(dispensaryId) {
    return this.store.pipe(select(selectDispensaryById, { dispensaryId }));
  }

  selectAllDispensaries() {
    return this.store.pipe(select(selectDispensaryAll));
  }

  selectCurrentDispensary() {
    return this.store.pipe(
      select(selectDispensaryAll),
      map((dispensary: Dispensary[]) => dispensary[0])
    );
  }

  selectDispensaryDictionary() {
    return this.store.pipe(select(selectDispensaryEntities));
  }
}
