import { mockNotifications } from './mock.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications = {};

  constructor() {
    mockNotifications.forEach(notification => {
      this.notifications[notification.id] = notification;
    });
  }

  getNotificationsByUserId(id) {
    return Object.values(this.notifications).filter((notification: any) => notification.user_id === id);
  }

  clearNotification(id) {
    this.notifications[id] = { ...this.notifications[id], completed: !this.notifications[id].completed };
    return this.notifications[id];
  }

  getAllNotifications() {
    return Object.values(this.notifications);
  }
}
