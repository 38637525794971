import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { Sale } from '@gcv/shared';
import { select, Store } from '@ngrx/store';
import { SalesState } from './sales.reducer';
import { selectSalesAll, selectSalesEntities } from './sales.selectors';
import {
  LoadAllUndepositedSales,
  LoadPaginatedSalesByDateRange,
  LoadSalesByDepositId,
  ResetSales,
} from './sales.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SalesFacade {
  sales$: Observable<Dictionary<Sale>>;

  constructor(private store: Store<SalesState>) {
    this.sales$ = store.pipe(select(selectSalesEntities));
  }

  getSalesByDepositIdAndDispensaryId(depositId: string, dispensaryId: string) {
    this.store.dispatch(new LoadSalesByDepositId({ depositId, dispensaryId }));
  }

  getPaginatedSalesByDateRange(endDate: string, startDate: string, dispensaryId: string, limit: string) {
    this.store.dispatch(new LoadPaginatedSalesByDateRange({ endDate, startDate, dispensaryId, limit }));
  }

  getAllUndepositedSalesByDispensaryId(dispensaryId: string) {
    this.store.dispatch(new LoadAllUndepositedSales({ dispensaryId }));
  }

  selectSalesByDepositId(depositId: string) {
    return this.store.pipe(
      select(selectSalesAll),
      map((sales: Sale[]) => sales.filter((sale: Sale) => sale.deposit_id === depositId))
    );
  }

  selectUndepositedSales() {
    return this.store.pipe(
      select(selectSalesAll),
      map((sales: Sale[]) => sales.filter((sale: Sale) => !sale.hasOwnProperty('deposit_id')))
    );
  }

  resetSales() {
    this.store.dispatch(new ResetSales());
  }
}
