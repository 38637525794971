import { Observable } from 'rxjs';
import { User } from '@gcv/shared';
import { Dictionary } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { PrimaryContactState } from './primaryContact.reducer';
import { selectPrimaryContactById, selectPrimaryContactEntities } from './primaryContact.selectors';
import { concatMap, map } from 'rxjs/operators';
import { ApprovePrimaryContact, LoadPrimaryContact, ResetPrimaryContact } from './primaryContact.action';
import { Injectable } from '@angular/core';
import { selectDispensaryAccountOwnerIds } from '../dispensary/dispensary.selectors';
import { AddError } from '../error';

@Injectable({
  providedIn: 'root',
})
export class PrimaryContactFacade {
  primaryContact$: Observable<Dictionary<User>>;

  constructor(private store: Store<PrimaryContactState>) {
    this.primaryContact$ = store.pipe(select(selectPrimaryContactEntities));
  }

  getPrimaryContactsByDispensaryId(dispensaryId) {
    return this.store.pipe(
      select(selectDispensaryAccountOwnerIds, { dispensaryId }),
      map(group => group.users),
      map(users => {
        users.forEach(user => this.store.dispatch(new LoadPrimaryContact({ primaryContactId: user })));
      }),
      concatMap(() => this.primaryContact$.pipe(map(users => Object.values(users))))
    );
  }

  putApprovePrimaryContact(user: User) {
    this.store.dispatch(new ApprovePrimaryContact(user));
  }

  resetPrimaryContact() {
    this.store.dispatch(new ResetPrimaryContact());
  }

  selectPrimaryContactById(primaryContactId: string) {
    try {
      return this.primaryContact$.pipe(select(selectPrimaryContactById, { primaryContactId }));
    } catch (err) {
      this.store.dispatch(new AddError(err));
      return {};
    }
  }
}
