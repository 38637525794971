import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffect } from './user';
import { NotificationsEffect } from './notifications';
import { PrimaryContactEffect } from './primaryContact';
import { StaffEffect } from './staff';
import { DepositsEffect } from './deposits';
import { FincenEffect } from './fincen';
import { BankEffect } from './bank';
import { DispensaryEffect } from './dispensary';
import { SalesEffect } from './sales';
import { SalesFileEffect } from './salesFile';
import { VendorEffect } from './vendors';
import { AggregatesEffect } from './aggregates';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    EffectsModule.forRoot([
      UserEffect,
      NotificationsEffect,
      PrimaryContactEffect,
      StaffEffect,
      DepositsEffect,
      FincenEffect,
      BankEffect,
      DispensaryEffect,
      SalesEffect,
      VendorEffect,
      SalesFileEffect,
      AggregatesEffect,
    ]),
  ],
  declarations: [],
  exports: [StoreModule, StoreDevtoolsModule],
})
export class StateModule {}
