import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Dispensary, User } from '@gcv/shared';
import { DispensaryFacade, StaffFacade } from '@user-interface/gcv-state';
import { filter, mergeMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DispUserRouteResolver implements Resolve<any> {
  constructor(private dispFacade: DispensaryFacade, private staffFacade: StaffFacade) {}

  resolve(route: ActivatedRouteSnapshot) {
    const dispensary$ = this.dispFacade.selectCurrentDispensary();

    return dispensary$.pipe(
      filter((dispensary: Dispensary) => dispensary !== undefined),
      tap((dispensary: Dispensary) => this.staffFacade.getOrganizationStaffByOrgId(dispensary.id)),
      mergeMap(() => this.staffFacade.selectAllStaff().pipe(filter((users: User[]) => users.length > 0))),
      take(1)
    );
  }
}
