import { Action } from '@ngrx/store';
import { Error } from './error.reducer';

export enum ErrorActionTypes {
  AddError = '[Error] Add Error',
  DismissError = '[Error] Dismiss Error',
}

export class AddError implements Action {
  readonly type = ErrorActionTypes.AddError;

  constructor(public payload: Error) {}
}

export class DismissError implements Action {
  readonly type = ErrorActionTypes.DismissError;
}

export type ErrorActions = AddError | DismissError;
