import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'gcv-create-new-dd-modal',
  templateUrl: './create-new-dd-modal.component.html',
  styleUrls: ['./create-new-dd-modal.component.scss'],
})
export class CreateNewDdModalComponent implements OnInit {
  section = 0;
  results: FormGroup;

  constructor(private matDialogRef: MatDialogRef<CreateNewDdModalComponent>) {}

  ngOnInit() {
    this.results = new FormGroup({
      title: new FormControl(
        '',
        Validators.compose([Validators.minLength(3), Validators.maxLength(30), Validators.required])
      ),
      description: new FormControl(
        '',
        Validators.compose([Validators.minLength(5), Validators.maxLength(300), Validators.required])
      ),
      category: new FormControl('', Validators.required),
    });
  }

  handleCommentInput(e) {
    this.results.controls.description.setValue(e.srcElement.value);
  }

  handleNextClick() {
    this.section++;
  }

  handleBackClick() {
    this.section--;
  }

  handleCancelClick() {
    this.matDialogRef.close();
  }

  handleCreateClick() {
    this.matDialogRef.close({
      data: this.results.getRawValue(),
    });
  }
}
