import { select, Store } from '@ngrx/store';
import { StaffState } from './staff.reducer';
import { Observable } from 'rxjs';
import { User } from '@gcv/shared';
import { Dictionary } from '@ngrx/entity';
import { selectAllStaff, selectStaffEntities } from './staff.selectors';
import {
  CreateOrganizationStaff,
  LoadAllStaff,
  LoadOrganizationStaff,
  ManuallyVerifyStaff,
  ResetStaff,
  UpdateOrganizationStaff,
} from './staff.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StaffFacade {
  staff$: Observable<Dictionary<User>>;

  constructor(private store: Store<StaffState>) {
    this.staff$ = store.pipe(select(selectStaffEntities));
  }

  getOrganizationStaffByOrgId(orgId: string) {
    this.store.dispatch(new LoadOrganizationStaff({ orgId }));
  }

  getAllOrganizationStaff() {
    this.store.dispatch(new LoadAllStaff());
  }

  postOrganizationStaff(user: Partial<User>) {
    this.store.dispatch(new CreateOrganizationStaff(user));
  }

  putOrganizationStaff(user: User) {
    this.store.dispatch(new UpdateOrganizationStaff(user));
  }

  putManuallyVerifyStaff(userId: string) {
    this.store.dispatch(new ManuallyVerifyStaff({ userId }));
  }

  resetStaff() {
    this.store.dispatch(new ResetStaff());
  }

  selectStaffById(userId: string) {
    return this.staff$.pipe(map(staffDictionary => staffDictionary[userId]));
  }

  selectAllStaff(): Observable<User[]> {
    return this.store.pipe(select(selectAllStaff));
  }

  selectStaffDictionary() {
    return this.store.pipe(select(selectStaffEntities));
  }
}
