import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent implements OnInit {
  @Input() tabs: string[];
  @Input() styles: any;
  @Output() handleTabChanged = new EventEmitter();
  @Input() tabActive;

  constructor() {}

  ngOnInit() {}

  changeTab(tab) {
    this.tabActive = tab;
    this.handleTabChanged.emit(tab);
  }
}
