import { NgModule } from '@angular/core';
import { FormatCurrencyCustom } from './format-currency/formatCurrency.pipe';
import { TimeFormatterPipe } from './format-time/time-formatter.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { FormatDynamicTitlePipe } from './format-dynamic-tittle/format-dynamic-title.pipe';
import { FormatPercentCustom } from './formatPercent/formatPercent.pipe';
import { DynamicNotificationPipe } from './format-dynamic-notification/format-dynamic-notification.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { EinPipe } from './format-ein/format-ein.pipe';
import { ZipPipe } from './format-zipcode/zip.pipe';
import { IntegerPipe } from './format-integer/integer-formatter.pipe';
import { CalculateReportDaysRemaining } from './calculate-report-days/calculateReportDaysRemaining.pipe';
import { FormatReportDatePipe } from './format-report-date/format-report-date.pipe';
import { FormatPhonePipe } from './format-phone/phone-formatter.pipe';

@NgModule({
  declarations: [
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
  ],
  imports: [CommonModule],
  providers: [
    DatePipe,
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
  ],
  exports: [
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
  ],
})
export class PipesModule {}
