import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateTotalDeposited',
})
export class CalculateTotalDepositedPipe implements PipeTransform {
  constructor() {}
  transform(transactions: any, args?: any): any {
    return (
      transactions.reduce((acc, next) => {
        return (acc += next.final_deposit);
      }, 0) / 100
    );
  }
}
