import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@gcv/shared';
import { ConfirmDialogComponent } from '../confirm-dialog/confirmDialog.component';
import { StaffFacade } from '@user-interface/gcv-state';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserComponent implements OnInit {
  message = '';
  userDetails: FormGroup;
  user: User;
  roles = [{ viewValue: 'Admin', value: 'bank_admin' }, { viewValue: 'Standard', value: 'bank_user' }];
  constructor(
    private matDialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private staffFacade: StaffFacade
  ) {
    if (data.user) {
      this.user = data.user;
    } else {
      this.close();
    }
  }

  ngOnInit() {
    this.userDetails = new FormGroup({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      email: new FormControl(this.user.email, Validators.required),
      role: new FormControl(this.user.role ? this.user.role : '', Validators.required),
    });
  }

  saveUser() {
    const updatedUser = { ...this.user, ...this.userDetails.getRawValue() };
    this.staffFacade.putOrganizationStaff(updatedUser);
    this.close();
    this.snackBar.open('User has been updated', '', {
      duration: 3000,
    });
  }

  close() {
    this.matDialogRef.close();
  }

  archiveUser() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you wish to archive this user?' },
    });
    dialog.afterClosed().subscribe(data => {
      if (data.confirmed) {
        this.staffFacade.putOrganizationStaff({
          ...this.user,
          ...{
            invitation_status: 'archived',
            archive_date: new Date().toISOString(),
          },
        });
        this.close();
        this.snackBar.open('User has been archived', '', {
          duration: 3000,
        });
      } else {
        this.close();
      }
    });
  }
}
