/** SAR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateCurrencyTransactionActvityDetailCodeType"]
 */
enum SarValidateSuspiciousActivitySubtypeID {
  'Suspicious inquiry by customer regarding BSA reporting or recordkeeping requirements' = '106',
  'Alters or cancels transaction to avoid BSA recordkeeping requirement' = '111',
  'Alters or cancels transaction to avoid CTR requirement' = '112',
  'Transaction(s) below BSA recordkeeping threshold' = '113',
  'Transaction(s) below CTR threshold' = '114',
  'Check' = '301',
  'Consumer Loan' = '304',
  'Credit/Debit Card' = '305',
  'Mail' = '308',
  'Mass-marketing' = '309',
  'Pyramid scheme' = '310',
  'Wire transfer' = '312',
  'ACH' = '320',
  'Business loan' = '321',
  'Advance fee' = '322',
  'Healthcase/Public or private health insurance' = '323',
  'Ponzi scheme' = '324',
  'Securities fraud' = '325',
  'Changes spelling or arrangement of name' = '401',
  'Multiple individuals with same or similar identities' = '402',
  'Provided questionable or false documentation' = '403',
  'Refused or avoided request for documentation' = '404',
  'Single individual with multiple identities' = '405',
  'Provided questionable or false identification' = '409',
  'Excessive insurance' = '501',
  'Excessive or unusal cash borrowing against policy/annuity' = '502',
  'Proceeds sent to unrelated third party' = '504',
  "Suspicious life settlement sales insurance (e.g., STOLI's, Viaticals)" = '505',
  'Suspicious termination of policy or contract' = '506',
  'Unclear or no insurable interest' = '507',
  'Insider trading' = '601',
  'Misappropriation' = '603',
  'Unauthorized pooling' = '604',
  'Market manipulation' = '608',
  'Wash trading' = '609',
  'Known or suspected terrorist/terrorist organization' = '701',
  'Exchanges small bills for large bills or vice versa' = '801',
  'Suspicious designation of beneficiaries, assignees or joint owners' = '804',
  'Suspicious EFT/wire transfers' = '805',
  'Suspicious receipt of government payments/benefits' = '806',
  'Suspicious use of multiple accounts' = '807',
  'Suspicious use of noncash monetary instruments' = '808',
  'Suspicious use of third-party transactors (straw-man)' = '809',
  'Transaction out of pattern for customer(s)' = '812',
  'Suspicious concerning the physical condition of funds' = '820',
  'Suspicious concerning the source of funds' = '821',
  'Suspicious exchange of currencies' = '822',
  'Trade Based Money Laundering/Black Market Peso Exchange' = '823',
  'Funnel account' = '824',
  'Bribery or gratuity' = '901',
  'Embezzlement/theft/disappearance of funds' = '903',
  'Forgeries' = '904',
  'Identity theft' = '905',
  'Suspected public/private corruption (domestic)' = '907',
  'Suspected public/private corruption (foreign)' = '908',
  'Suspicious use of informal value transfer system' = '909',
  'Suspicious use of multiple locations' = '910',
  'Two or more individuals working together' = '911',
  'Unlicensed or unregistered MSB' = '913',
  'Counterfeit Instrument (other)' = '917',
  'Account takeover' = '920',
  'Elder financial exploitation' = '921',
  'Little or no concern for product performance penalites, fees, or tax consequences' = '922',
  'Misuse of position or self-dealing' = '924',
  'Transaction with no apparent economic, business, or lawful purpose' = '925',
  'Human smuggling' = '926',
  'Human trafficking' = '927',
  'Transaction(s) involving foreign high risk jurisdiction' = '928',
  'Appraisal fraud' = '1001',
  'Loan Modification fraud' = '1003',
  'Application fraud' = '1005',
  'Foreclosure/Short sale fraud' = '1006',
  'Origination fraud' = '1007',
  'Against financial institution(s)' = '1101',
  'Against financial institution customer(s)' = '1102',
  'Chip walking' = '1201',
  'Minimal gaming with large transactions' = '1202',
  'Suspicious use of counter checks or markers' = '1203',
  'Unknown source of chips' = '1204',
  // 'Other' = '1999', // TODO ?
  // 'Other' = '3999', // TODO ?
  // 'Other' = '4999', // TODO ?
  // 'Other' = '5999', // TODO ?
  // 'Other' = '6999', // TODO ?
  // 'Other' = '7999', // TODO ?
  // 'Other' = '8999', // TODO ?
  'Other' = '9999',
  // 'Other' = '10999', // TODO ?
  // 'Other' = '11999', // TODO ?
  // 'Other' = '12999', // TODO ?
}

/**
 * CTR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateCurrencyTransactionActvityDetailCodeType"]
 */
enum ValidateCurrencyTransactionActvityDetailCodeType {
  'Deposit(s)' = '55',
  'Payment(s)' = '46',
  'Currency received from wire transfer(s) out' = '23',
  'Negotiable instrument(s) purchased' = '12',
  'Currency exchange in' = '14',
  'Currency to prepaid access' = '49',
  'Purchase(s) of casino chips, tokens, and other gaming instruments' = '18',
  'Currency wager(s) (including money plays)' = '21',
  'Bills inserted into gaming devices' = '25',
  'Other cash in' = '997',
  'Withdrawal(s)' = '56',
  'Advance(s) on credit (including markers)' = '30',
  'Currency paid from wire transfer(s) in' = '32',
  'Negotiable instrument(s) cashed' = '13',
  'Currency exchange out' = '15',
  'Currency from prepaid access' = '48',
  'Redemption(s) of casino chips, tokens, TITO tickets, and other gaming instruments' = '28',
  'Payment(s) on wager(s)/bet(s) (Including race book, slot jackpot(s) and OTB or sports pool)' = '31',
  'Travel and complimentary expenses and gaming incentives' = '33',
  'Payment for tournament, contest or other promotions' = '34',
  'Other cash out' = '998',
  'Foreign currency in' = '53',
  'Foreign currency out' = '54',
}

/**
 * SAR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateSuspiciousActivityTypeID"]
 */
enum ValidateSuspiciousActivityTypeID {
  'Structuring' = '1',
  'Gaming activities' = '12',
  'Fraud' = '3',
  'Identification documentation' = '4',
  'Insurance' = '5',
  'Securities/Futures/Options' = '6',
  'Terrorist financing' = '7',
  'Money laundering' = '8',
  'Other suspicious activities' = '9',
  'Mortgage Fraud' = '10',
  'Cyber event' = '11',
}

/**
 * SAR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateActivityPartyCodeType"]
 */
enum SARValidateActivityPartyCodeType {
  'Transmitter' = '35',
  'Transmitter Contact' = '37',
  'Reporting financial institution' = '30',
  'Subject' = '33',
  'Transaction location business' = '34',
  'Contact for assistance' = '8',
  'Transaction location branch' = '46',
  'Law enforcement or regulator contact' = '18',
  'Name of person contacted at Law enforcement or regulator' = '19',
  'Financial Institution in which account is held' = '41',
}

/**
 * CTR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateActivityPartyCodeType"]
 */
enum ValidateActivityPartyCodeType {
  'Transmitter' = '35',
  'Transmitter Contact' = '37',
  'Reporting financial institution' = '30',
  'Transaction location business' = '34',
  'Person conducting transaction on own behalf' = '50',
  'Person conducting transaction for another' = '17',
  'Person on whose behalf this transaction was conducted' = '23',
  'Common carrier' = '58',
  'Contact for assistance' = '8',
}

/**
 * https://www.freeformatter.com/xpath-tester.html
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidatePartyIdentificationCodeType"]
 */
enum ValidatePartyIdentificationCodeType {
  'SSN/ITIN' = '1',
  'EIN' = '2',
  'TIN' = '4',
  "Driver's license/State ID" = '5',
  'Passport' = '6',
  'Alien registration' = '7',
  'Foreign' = '9',
  'CRD number' = '10',
  'IARD number' = '11',
  'NFA ID number' = '12',
  'SEC number' = '13',
  'RSSD number' = '14',
  'Transmitter Control Code' = '28',
  'NAIC number' = '32',
  'NMLS Id number' = '33',
  'Internal Control Number' = '29',
  'Other' = '999',
}

/**
 * CTR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateFederalRegulatorCodeType"]
 */
enum CTRValidateFederalRegulatorCodeType {
  'CFTC' = '9',
  'Federal Reserve' = '1',
  'FDIC' = '2',
  'IRS' = '7',
  'NCUA' = '3',
  'OCC' = '4',
  'SEC' = '6',
  'Unknown' = '14',
}

/**
 * SAR
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateFederalRegulatorCodeType"]
 */
export enum SARValidateFederalRegulatorCodeType {
  'CFTC' = '9',
  'Federal Reserve' = '1',
  'FDIC' = '2',
  'IRS' = '7',
  'NCUA' = '3',
  'OCC' = '4',
  'SEC' = '6',
  'FHFA' = '13',
  'Not applicable' = '99',
}

/**
 * XPath: /xsd:schema/xsd:simpleType[@name = "ValidateOrganizationCodeType"]
 */
enum ValidateOrganizationCodeType {
  'Casino/Card club' = '1',
  'Depository institution' = '2',
  'Insurance company' = '3',
  'Money Services Business (MSB)' = '4',
  'Securities/Futures' = '5',
  'Loan or Finance Company' = '11',
  'Housing GSE' = '12',
  'Other' = '999',
}

export interface EntityType {
  value: string;
  viewValue: string;
}

export interface Hour {
  value: string;
  viewValue: string;
}

export interface IsOpen {
  value: string;
  viewValue: string;
}

export interface State {
  viewValue: string;
}

export interface IdType {
  value: string;
  viewValue: string;
}

export interface Country {
  value: string;
  viewValue: string;
}

export const entitytypes: EntityType[] = [
  { value: 'sole', viewValue: 'Sole Proprietorship' },
  {
    value: 'partnership',
    viewValue: 'General Partnership',
  },
  { value: 'corporation', viewValue: 'Corporation' },
  { value: 'llc', viewValue: 'Limited Liability Company' },
];

export const isOpen: IsOpen[] = [{ value: 'open', viewValue: 'Open' }, { value: 'closed', viewValue: 'Closed' }];

export const states: any[] = [
  { viewValue: 'Alabama', value: 'AL' },
  { viewValue: 'Alaska', value: 'AK' },
  { viewValue: 'Arizona', value: 'AZ' },
  { viewValue: 'Arkansas', value: 'AR' },
  { viewValue: 'California', value: 'CA' },
  { viewValue: 'Colorado', value: 'CO' },
  { viewValue: 'Connecticut', value: 'CT' },
  { viewValue: 'Delaware', value: 'DE' },
  { viewValue: 'Florida', value: 'FL' },
  { viewValue: 'Georgia', value: 'GA' },
  { viewValue: 'Hawaii', value: 'HI' },
  { viewValue: 'Idaho', value: 'ID' },
  { viewValue: 'Illinois', value: 'IL' },
  { viewValue: 'Indiana', value: 'IN' },
  { viewValue: 'Iowa', value: 'IA' },
  { viewValue: 'Kansas', value: 'KS' },
  { viewValue: 'Kentucky', value: 'KY' },
  { viewValue: 'Louisiana', value: 'LA' },
  { viewValue: 'Maine', value: 'ME' },
  { viewValue: 'Maryland', value: 'MD' },
  { viewValue: 'Massachusetts', value: 'MA' },
  { viewValue: 'Michigan', value: 'MI' },
  { viewValue: 'Minnesota', value: 'MN' },
  { viewValue: 'Mississippi', value: 'MS' },
  { viewValue: 'Missouri', value: 'MO' },
  { viewValue: 'Montana', value: 'MT' },
  { viewValue: 'Nebraska', value: 'NE' },
  { viewValue: 'Nevada', value: 'NV' },
  { viewValue: 'New Hampshire', value: 'NH' },
  { viewValue: 'New Jersey', value: 'NJ' },
  { viewValue: 'New Mexico', value: 'NM' },
  { viewValue: 'New York', value: 'NY' },
  { viewValue: 'North Carolina', value: 'NC' },
  { viewValue: 'North Dakota', value: 'ND' },
  { viewValue: 'Ohio', value: 'OH' },
  { viewValue: 'Oklahoma', value: 'OK' },
  { viewValue: 'Oregon', value: 'OR' },
  { viewValue: 'Pennsylvania', value: 'PA' },
  { viewValue: 'Rhode Island', value: 'RI' },
  { viewValue: 'South Carolina', value: 'SC' },
  { viewValue: 'South Dakota', value: 'SD' },
  { viewValue: 'Tennessee', value: 'TN' },
  { viewValue: 'Texas', value: 'TX' },
  { viewValue: 'Utah', value: 'UT' },
  { viewValue: 'Vermont', value: 'VT' },
  { viewValue: 'Virginia', value: 'VA' },
  { viewValue: 'Washington', value: 'WA' },
  { viewValue: 'West Virginia', value: 'WV' },
  { viewValue: 'Wisconsin', value: 'WI' },
  { viewValue: 'Wyoming', value: 'WY' },
];

export const hours: Hour[] = [
  { value: `am12`, viewValue: `12:00 AM` },
  { value: `am1230`, viewValue: `12:30 AM` },
  { value: `am1`, viewValue: `1:00 AM` },
  { value: `am130`, viewValue: `1:30 AM` },
  { value: `am2`, viewValue: `2:00 AM` },
  { value: `am230`, viewValue: `2:30 AM` },
  { value: `am3`, viewValue: `3:00 AM` },
  { value: `am330`, viewValue: `3:30 AM` },
  { value: `am4`, viewValue: `4:00 AM` },
  { value: `am430`, viewValue: `4:30 AM` },
  { value: `am5`, viewValue: `5:00 AM` },
  { value: `am530`, viewValue: `5:30 AM` },
  { value: `am6`, viewValue: `6:00 AM` },
  { value: `am630`, viewValue: `6:30 AM` },
  { value: `am7`, viewValue: `7:00 AM` },
  { value: `am730`, viewValue: `7:30 AM` },
  { value: `am8`, viewValue: `8:00 AM` },
  { value: `am830`, viewValue: `8:30 AM` },
  { value: `am9`, viewValue: `9:00 AM` },
  { value: `am930`, viewValue: `9:30 AM` },
  { value: `am10`, viewValue: `10:00 AM` },
  { value: `am1030`, viewValue: `10:30 AM` },
  { value: `am11`, viewValue: `11:00 AM` },
  { value: `am1130`, viewValue: `11:30 AM` },
  { value: `pm12`, viewValue: `12:00 PM` },
  { value: `pm1230`, viewValue: `12:30 PM` },
  { value: `pm1`, viewValue: `1:00 PM` },
  { value: `pm130`, viewValue: `1:30 PM` },
  { value: `pm2`, viewValue: `2:00 PM` },
  { value: `pm230`, viewValue: `2:30 PM` },
  { value: `pm3`, viewValue: `3:00 PM` },
  { value: `pm330`, viewValue: `3:30 PM` },
  { value: `pm4`, viewValue: `4:00 PM` },
  { value: `pm430`, viewValue: `4:30 PM` },
  { value: `pm5`, viewValue: `5:00 PM` },
  { value: `pm530`, viewValue: `5:30 PM` },
  { value: `pm6`, viewValue: `6:00 PM` },
  { value: `pm630`, viewValue: `6:30 PM` },
  { value: `pm7`, viewValue: `7:00 PM` },
  { value: `pm730`, viewValue: `7:30 PM` },
  { value: `pm8`, viewValue: `8:00 PM` },
  { value: `pm830`, viewValue: `8:30 PM` },
  { value: `pm9`, viewValue: `9:00 PM` },
  { value: `pm930`, viewValue: `9:30 PM` },
  { value: `pm10`, viewValue: `10:00 PM` },
  { value: `pm1030`, viewValue: `10:30 PM` },
  { value: `pm11`, viewValue: `11:00 PM` },
  { value: `pm1130`, viewValue: `11:30 PM` },
];

export const idtypes: IdType[] = [
  { value: 'passport', viewValue: 'Passport' },
  { value: 'dlicense', viewValue: `Driver's License` },
  { value: 'nondriverid', viewValue: 'Non-Driver State ID' },
  { value: 'ssnumber', viewValue: 'Social Security Number' },
];

export const countries: Country[] = [
  { value: `unitedstates`, viewValue: `United States` },
  { value: `afghanistan`, viewValue: `Afghanistan` },
  { value: `albania`, viewValue: `Albania` },
  { value: `algeria`, viewValue: `Algeria` },
  { value: `americansamoa`, viewValue: `American Samoa` },
  { value: `andorra`, viewValue: `Andorra` },
  { value: `angola`, viewValue: `Angola` },
  { value: `anguilla`, viewValue: `Anguilla` },
  { value: `antiguabarbuda`, viewValue: `Antigua & Barbuda` },
  { value: `argentina`, viewValue: `Argentina` },
  { value: `armenia`, viewValue: `Armenia` },
  { value: `aruba`, viewValue: `Aruba` },
  { value: `australia`, viewValue: `Australia` },
  { value: `austria`, viewValue: `Austria` },
  { value: `azerbaijan`, viewValue: `Azerbaijan` },
  { value: `bahamasthe`, viewValue: `Bahamas, The` },
  { value: `bahrain`, viewValue: `Bahrain` },
  { value: `bangladesh`, viewValue: `Bangladesh` },
  { value: `barbados`, viewValue: `Barbados` },
  { value: `belarus`, viewValue: `Belarus` },
  { value: `belgium`, viewValue: `Belgium` },
  { value: `belize`, viewValue: `Belize` },
  { value: `benin`, viewValue: `Benin` },
  { value: `bermuda`, viewValue: `Bermuda` },
  { value: `bhutan`, viewValue: `Bhutan` },
  { value: `bolivia`, viewValue: `Bolivia` },
  { value: `bosniaherzegovina`, viewValue: `Bosnia & Herzegovina` },
  { value: `botswana`, viewValue: `Botswana` },
  { value: `brazil`, viewValue: `Brazil` },
  { value: `britishvirginis`, viewValue: `British Virgin Is.` },
  { value: `brunei`, viewValue: `Brunei` },
  { value: `bulgaria`, viewValue: `Bulgaria` },
  { value: `burkinafaso`, viewValue: `Burkina Faso` },
  { value: `burma`, viewValue: `Burma` },
  { value: `burundi`, viewValue: `Burundi` },
  { value: `cambodia`, viewValue: `Cambodia` },
  { value: `cameroon`, viewValue: `Cameroon` },
  { value: `canada`, viewValue: `Canada` },
  { value: `capeverde`, viewValue: `Cape Verde` },
  { value: `caymanislands`, viewValue: `Cayman Islands` },
  { value: `centralafricanrep`, viewValue: `Central African Rep.` },
  { value: `chad`, viewValue: `Chad` },
  { value: `chile`, viewValue: `Chile` },
  { value: `china`, viewValue: `China` },
  { value: `colombia`, viewValue: `Colombia` },
  { value: `comoros`, viewValue: `Comoros` },
  { value: `congodemrep`, viewValue: `Congo, Dem. Rep.` },
  { value: `congorepubofthe`, viewValue: `Congo, Repub. of the` },
  { value: `cookislands`, viewValue: `Cook Islands` },
  { value: `costarica`, viewValue: `Costa Rica` },
  { value: `coted'ivoire`, viewValue: `Cote d'Ivoire` },
  { value: `croatia`, viewValue: `Croatia` },
  { value: `cuba`, viewValue: `Cuba` },
  { value: `cyprus`, viewValue: `Cyprus` },
  { value: `czechrepublic`, viewValue: `Czech Republic` },
  { value: `denmark`, viewValue: `Denmark` },
  { value: `djibouti`, viewValue: `Djibouti` },
  { value: `dominica`, viewValue: `Dominica` },
  { value: `dominicanrepublic`, viewValue: `Dominican Republic` },
  { value: `easttimor`, viewValue: `East Timor` },
  { value: `ecuador`, viewValue: `Ecuador` },
  { value: `egypt`, viewValue: `Egypt` },
  { value: `elsalvador`, viewValue: `El Salvador` },
  { value: `equatorialguinea`, viewValue: `Equatorial Guinea` },
  { value: `eritrea`, viewValue: `Eritrea` },
  { value: `estonia`, viewValue: `Estonia` },
  { value: `ethiopia`, viewValue: `Ethiopia` },
  { value: `faroeislands`, viewValue: `Faroe Islands` },
  { value: `fiji`, viewValue: `Fiji` },
  { value: `finland`, viewValue: `Finland` },
  { value: `france`, viewValue: `France` },
  { value: `frenchguiana`, viewValue: `French Guiana` },
  { value: `frenchpolynesia`, viewValue: `French Polynesia` },
  { value: `gabon`, viewValue: `Gabon` },
  { value: `gambiathe`, viewValue: `Gambia, The` },
  { value: `gazastrip`, viewValue: `Gaza Strip` },
  { value: `georgia`, viewValue: `Georgia` },
  { value: `germany`, viewValue: `Germany` },
  { value: `ghana`, viewValue: `Ghana` },
  { value: `gibraltar`, viewValue: `Gibraltar` },
  { value: `greece`, viewValue: `Greece` },
  { value: `greenland`, viewValue: `Greenland` },
  { value: `grenada`, viewValue: `Grenada` },
  { value: `guadeloupe`, viewValue: `Guadeloupe` },
  { value: `guam`, viewValue: `Guam` },
  { value: `guatemala`, viewValue: `Guatemala` },
  { value: `guernsey`, viewValue: `Guernsey` },
  { value: `guinea`, viewValue: `Guinea` },
  { value: `guinea-bissau`, viewValue: `Guinea-Bissau` },
  { value: `guyana`, viewValue: `Guyana` },
  { value: `haiti`, viewValue: `Haiti` },
  { value: `honduras`, viewValue: `Honduras` },
  { value: `hongkong`, viewValue: `Hong Kong` },
  { value: `hungary`, viewValue: `Hungary` },
  { value: `iceland`, viewValue: `Iceland` },
  { value: `india`, viewValue: `India` },
  { value: `indonesia`, viewValue: `Indonesia` },
  { value: `iran`, viewValue: `Iran` },
  { value: `iraq`, viewValue: `Iraq` },
  { value: `ireland`, viewValue: `Ireland` },
  { value: `isleofman`, viewValue: `Isle of Man` },
  { value: `israel`, viewValue: `Israel` },
  { value: `italy`, viewValue: `Italy` },
  { value: `jamaica`, viewValue: `Jamaica` },
  { value: `japan`, viewValue: `Japan` },
  { value: `jersey`, viewValue: `Jersey` },
  { value: `jordan`, viewValue: `Jordan` },
  { value: `kazakhstan`, viewValue: `Kazakhstan` },
  { value: `kenya`, viewValue: `Kenya` },
  { value: `kiribati`, viewValue: `Kiribati` },
  { value: `koreanorth`, viewValue: `Korea, North` },
  { value: `koreasouth`, viewValue: `Korea, South` },
  { value: `kuwait`, viewValue: `Kuwait` },
  { value: `kyrgyzstan`, viewValue: `Kyrgyzstan` },
  { value: `laos`, viewValue: `Laos` },
  { value: `latvia`, viewValue: `Latvia` },
  { value: `lebanon`, viewValue: `Lebanon` },
  { value: `lesotho`, viewValue: `Lesotho` },
  { value: `liberia`, viewValue: `Liberia` },
  { value: `libya`, viewValue: `Libya` },
  { value: `liechtenstein`, viewValue: `Liechtenstein` },
  { value: `lithuania`, viewValue: `Lithuania` },
  { value: `luxembourg`, viewValue: `Luxembourg` },
  { value: `macau`, viewValue: `Macau` },
  { value: `macedonia`, viewValue: `Macedonia` },
  { value: `madagascar`, viewValue: `Madagascar` },
  { value: `malawi`, viewValue: `Malawi` },
  { value: `malaysia`, viewValue: `Malaysia` },
  { value: `maldives`, viewValue: `Maldives` },
  { value: `mali`, viewValue: `Mali` },
  { value: `malta`, viewValue: `Malta` },
  { value: `marshallislands`, viewValue: `Marshall Islands` },
  { value: `martinique`, viewValue: `Martinique` },
  { value: `mauritania`, viewValue: `Mauritania` },
  { value: `mauritius`, viewValue: `Mauritius` },
  { value: `mayotte`, viewValue: `Mayotte` },
  { value: `mexico`, viewValue: `Mexico` },
  { value: `micronesiafedst`, viewValue: `Micronesia, Fed. St.` },
  { value: `moldova`, viewValue: `Moldova` },
  { value: `monaco`, viewValue: `Monaco` },
  { value: `mongolia`, viewValue: `Mongolia` },
  { value: `montserrat`, viewValue: `Montserrat` },
  { value: `morocco`, viewValue: `Morocco` },
  { value: `mozambique`, viewValue: `Mozambique` },
  { value: `namibia`, viewValue: `Namibia` },
  { value: `nauru`, viewValue: `Nauru` },
  { value: `nepal`, viewValue: `Nepal` },
  { value: `netherlands`, viewValue: `Netherlands` },
  { value: `netherlandsantilles`, viewValue: `Netherlands Antilles` },
  { value: `newcaledonia`, viewValue: `New Caledonia` },
  { value: `newzealand`, viewValue: `New Zealand` },
  { value: `nicaragua`, viewValue: `Nicaragua` },
  { value: `niger`, viewValue: `Niger` },
  { value: `nigeria`, viewValue: `Nigeria` },
  { value: `nmarianaislands`, viewValue: `N. Mariana Islands` },
  { value: `norway`, viewValue: `Norway` },
  { value: `oman`, viewValue: `Oman` },
  { value: `pakistan`, viewValue: `Pakistan` },
  { value: `palau`, viewValue: `Palau` },
  { value: `panama`, viewValue: `Panama` },
  { value: `papuanewguinea`, viewValue: `Papua New Guinea` },
  { value: `paraguay`, viewValue: `Paraguay` },
  { value: `peru`, viewValue: `Peru` },
  { value: `philippines`, viewValue: `Philippines` },
  { value: `poland`, viewValue: `Poland` },
  { value: `portugal`, viewValue: `Portugal` },
  { value: `puertorico`, viewValue: `Puerto Rico` },
  { value: `qatar`, viewValue: `Qatar` },
  { value: `reunion`, viewValue: `Reunion` },
  { value: `romania`, viewValue: `Romania` },
  { value: `russia`, viewValue: `Russia` },
  { value: `rwanda`, viewValue: `Rwanda` },
  { value: `sainthelena`, viewValue: `Saint Helena` },
  { value: `saintkittsnevis`, viewValue: `Saint Kitts & Nevis` },
  { value: `saintlucia`, viewValue: `Saint Lucia` },
  { value: `stpierremiquelon`, viewValue: `St Pierre & Miquelon` },
  {
    value: `saintvincentandthegrenadines`,
    viewValue: `Saint Vincent and the Grenadines`,
  },
  { value: `samoa`, viewValue: `Samoa` },
  { value: `sanmarino`, viewValue: `San Marino` },
  { value: `saotomeprincipe`, viewValue: `Sao Tome & Principe` },
  { value: `saudiarabia`, viewValue: `Saudi Arabia` },
  { value: `senegal`, viewValue: `Senegal` },
  { value: `serbia`, viewValue: `Serbia` },
  { value: `seychelles`, viewValue: `Seychelles` },
  { value: `sierraleone`, viewValue: `Sierra Leone` },
  { value: `singapore`, viewValue: `Singapore` },
  { value: `slovakia`, viewValue: `Slovakia` },
  { value: `slovenia`, viewValue: `Slovenia` },
  { value: `solomonislands`, viewValue: `Solomon Islands` },
  { value: `somalia`, viewValue: `Somalia` },
  { value: `southafrica`, viewValue: `South Africa` },
  { value: `spain`, viewValue: `Spain` },
  { value: `srilanka`, viewValue: `Sri Lanka` },
  { value: `sudan`, viewValue: `Sudan` },
  { value: `suriname`, viewValue: `Suriname` },
  { value: `swaziland`, viewValue: `Swaziland` },
  { value: `sweden`, viewValue: `Sweden` },
  { value: `switzerland`, viewValue: `Switzerland` },
  { value: `syria`, viewValue: `Syria` },
  { value: `taiwan`, viewValue: `Taiwan` },
  { value: `tajikistan`, viewValue: `Tajikistan` },
  { value: `tanzania`, viewValue: `Tanzania` },
  { value: `thailand`, viewValue: `Thailand` },
  { value: `togo`, viewValue: `Togo` },
  { value: `tonga`, viewValue: `Tonga` },
  { value: `trinidadtobago`, viewValue: `Trinidad & Tobago` },
  { value: `tunisia`, viewValue: `Tunisia` },
  { value: `turkey`, viewValue: `Turkey` },
  { value: `turkmenistan`, viewValue: `Turkmenistan` },
  { value: `turkscaicosis`, viewValue: `Turks & Caicos Is` },
  { value: `tuvalu`, viewValue: `Tuvalu` },
  { value: `uganda`, viewValue: `Uganda` },
  { value: `ukraine`, viewValue: `Ukraine` },
  { value: `unitedarabemirates`, viewValue: `United Arab Emirates` },
  { value: `unitedkingdom`, viewValue: `United Kingdom` },
  { value: `uruguay`, viewValue: `Uruguay` },
  { value: `uzbekistan`, viewValue: `Uzbekistan` },
  { value: `vanuatu`, viewValue: `Vanuatu` },
  { value: `venezuela`, viewValue: `Venezuela` },
  { value: `vietnam`, viewValue: `Vietnam` },
  { value: `virginislands`, viewValue: `Virgin Islands` },
  { value: `wallisandfutuna`, viewValue: `Wallis and Futuna` },
  { value: `westbank`, viewValue: `West Bank` },
  { value: `westernsahara`, viewValue: `Western Sahara` },
  { value: `yemen`, viewValue: `Yemen` },
  { value: `zambia`, viewValue: `Zambia` },
  { value: `zimbabwe`, viewValue: `Zimbabwe` },
];

export interface SelectValues {
  value: string;
  viewValue?: string;
}

export const financialInstitutions: SelectValues[] = [
  { value: '0', viewValue: 'Casino/Card Club' },
  { value: 'Depository Institution', viewValue: 'Depository Institution' },
  { value: '2', viewValue: 'Insurance Company' },
  { value: '3', viewValue: 'MSB' },
  { value: '4', viewValue: 'Securities/Futures' },
  { value: '5', viewValue: 'Loan or Finance Company' },
  { value: '6', viewValue: 'Housing GSE' },
  { value: '7', viewValue: 'Other' },
];

export const sarFinancialInstitutions: SelectValues[] = [
  {
    value: ValidateOrganizationCodeType['Casino/Card club'],
    viewValue: 'Casino/Card Club',
  },
  {
    value: ValidateOrganizationCodeType['Depository institution'],
    viewValue: 'Depository Institution',
  },
  {
    value: ValidateOrganizationCodeType['Insurance company'],
    viewValue: 'Insurance Company',
  },
  {
    value: ValidateOrganizationCodeType['Money Services Business (MSB)'],
    viewValue: 'MSB',
  },
  {
    value: ValidateOrganizationCodeType['Securities/Futures'],
    viewValue: 'Securities/Futures',
  },
  {
    value: ValidateOrganizationCodeType['Loan or Finance Company'],
    viewValue: 'Loan or Finance Company',
  },
  {
    value: ValidateOrganizationCodeType['Housing GSE'],
    viewValue: 'Housing GSE',
  },
  { value: ValidateOrganizationCodeType.Other, viewValue: 'Other' },
];

export const primaryFederalRegulators: SelectValues[] = [
  { value: 'FRB', viewValue: 'FRB - Federal Reserve Board' },
  {
    value: 'FDIC',
    viewValue: 'FDIC - Federal Deposit Insurance Corporation',
  },
  { value: 'IRS', viewValue: 'IRS - Internal Revenue Service' },
  { value: 'NCUA', viewValue: 'NCUA - National Credit Union Administration' },
  {
    value: 'OCC',
    viewValue: 'OCC - Office of the Comptroller of the Currency',
  },
];

export const sarPrimaryRegulatorTypeCode: SelectValues[] = [
  {
    value: SARValidateFederalRegulatorCodeType.CFTC,
    viewValue: 'CFTC (Commodities Futures Trading Commission)',
  },
  {
    value: SARValidateFederalRegulatorCodeType['Federal Reserve'],
    viewValue: 'Federal Reserve',
  },
  {
    value: SARValidateFederalRegulatorCodeType.FDIC,
    viewValue: 'FDIC (Federal Deposit Insurance Corporation)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.FHFA,
    viewValue: 'FHFA (Federal Housing Finance Agency)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.IRS,
    viewValue: 'IRS (Internal Revenue Service)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.NCUA,
    viewValue: 'NCUA (National Credit Union Administration)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.OCC,
    viewValue: 'OCC (Office of the Comptroller of the Currency)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.SEC,
    viewValue: 'SEC (Securities and Exchange Commission)',
  },
  {
    value: SARValidateFederalRegulatorCodeType['Not applicable'],
    viewValue: 'Not Applicable',
  },
];

export const tinTypes: SelectValues[] = [
  { value: 'EIN', viewValue: 'EIN' },
  { value: 'SSN-ITIN', viewValue: 'SSN-ITIN' },
  { value: 'Foreign', viewValue: 'Foreign' },
];

export const sarTinTypes: SelectValues[] = [
  { value: ValidatePartyIdentificationCodeType.EIN, viewValue: 'EIN' },
  {
    value: ValidatePartyIdentificationCodeType['SSN/ITIN'],
    viewValue: 'SSN-ITIN',
  },
  { value: ValidatePartyIdentificationCodeType.Foreign, viewValue: 'Foreign' },
];

export const financialInstitutionIdentificationTypes: SelectValues[] = [
  { value: 'CRD', viewValue: 'Central Registration Depository (CRD) number' },
  {
    value: 'IARD',
    viewValue: 'Investment Adviser Registration Depository (IARD) number',
  },
  { value: 'NFA', viewValue: 'National Futures Association (NFA) number' },
  {
    value: 'RSSD',
    viewValue: 'Research, Statistics, Supervision and Discount (RSSD) number',
  },
  {
    value: 'SEC',
    viewValue: 'Securities and Exchange Commission (SEC) number',
  },
  {
    value: 'NAIC',
    viewValue: 'National Association of Insurance Commissioners (NAIC Code)',
  },
  { value: 'NMLS', viewValue: 'Mortgage (NMLS ID)' },
];

export const sarFinancialInstitutionIdentificationTypes: SelectValues[] = [
  {
    value: ValidatePartyIdentificationCodeType['CRD number'],
    viewValue: 'Central Registration Depository (CRD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['IARD number'],
    viewValue: 'Investment Adviser Registration Depository (IARD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['NFA ID number'],
    viewValue: 'National Futures Association (NFA) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['RSSD number'],
    viewValue: 'Research, Statistics, Supervision and Discount (RSSD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['SEC number'],
    viewValue: 'Securities and Exchange Commission (SEC) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['NAIC number'],
    viewValue: 'National Association of Insurance Commissioners (NAIC Code)',
  },
  {
    value: ValidatePartyIdentificationCodeType['NMLS Id number'],
    viewValue: 'Mortgage (NMLS ID)',
  },
];

export const cyberEventIndicators: SelectValues[] = [
  {
    value: 'commandAndControlIpAddress',
    viewValue: 'Command and control IP address',
  },
  {
    value: 'commandAndControlIpAddress',
    viewValue: 'Command and control URL/domain',
  },
  {
    value: 'malware',
    viewValue: 'Malware MD5, Malware SHA-1, or Malware SHA-256',
  },
  { value: 'MAC', viewValue: 'Media Access Control (MAC) Address' },
  { value: 'port', viewValue: 'Port' },
  { value: 'suspiciousEmailAddress', viewValue: 'Suspicious email address' },
  { value: 'suspiciousFileName', viewValue: 'Suspicious file name' },
  { value: 'suspiciousIpAddress', viewValue: 'Suspicious IP address' },
  { value: 'suspiciousUrlDomain', viewValue: 'Suspicious URL/domain' },
  { value: 'targetedSystem', viewValue: 'Targeted system' },
  { value: 'other', viewValue: 'Other' },
];

export const DEFAULT_TIME_FILTER = 'last30Days';
export const DEFAULT_SELECTED_RANGE_OUTPUT = 'Last 30 days';

export enum DATE_TIME_RANGES {
  'today',
  'yesterday',
  'thisWeek',
  'thisMonth',
  'lastWeek',
  'lastMonth',
  'last30Days',
  'thisQuarter',
  'thisYear',
  'lastQuarter',
  'lastYear',
}
