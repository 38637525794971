import * as Rollbar from 'rollbar';
import { environment } from '../../environments/environment';
import { LoggerService } from '../../services/logger/logger.service';
// const LogRocket = require('logrocket');
const logger = new LoggerService();

const rollbarConfig = {
  accessToken: '31e48d6526254d2089099404af72d635',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

// if (environment.env === 'production') {
//   // logger.log('Logrocket Enabled');
//   // let logrocketSessionUrl;
//   // LogRocket.getSessionURL(sessionURL => {
//   //   logrocketSessionUrl = sessionURL;
//   // });
//   // LogRocket.init('er8frg/gcv');
//   // rollbarConfig['sessionURL'] = logrocketSessionUrl;
// } else {
//   logger.log('Logrocket Disabled');
// }

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export function updateConfig(data) {
  rollbarConfig['payload'] = {
    person: {
      id: data.userId,
      username: data.username,
      email: data.email,
    },
    environment: data.env,
  };

  // if (data.env === 'production') {
  //   LogRocket.identify(data.userId, {
  //     name: data.username,
  //     email: data.email,
  //   });
  // }
}
