import { AggregatesActionTypes } from '../aggregates/aggregates.actions';
import { BankActionTypes } from '../bank/bank.actions';
import { DispensaryActionTypes } from '../dispensary/dispensary.actions';
import { DepositActionTypes } from '../deposits/deposits.actions';
import { FincenActionTypes } from '../fincen/fincen.actions';
import { NotificationActionTypes } from '../notifications/notifications.actions';
import { PrimaryContactActionTypes } from '../primaryContact/primaryContact.action';
import { SalesActionTypes } from '../sales/sales.actions';
import { SalesFileActionTypes } from '../salesFile/salesFile.actions';
import { StaffActionTypes } from '../staff/staff.actions';
import { UserActionTypes } from '../user/user.actions';
import { VendorsActionTypes } from '../vendors/vendors.actions';
import { ErrorActionTypes } from './error.actions';

export type ActionTypes =
  | AggregatesActionTypes
  | BankActionTypes
  | DispensaryActionTypes
  | DepositActionTypes
  | FincenActionTypes
  | NotificationActionTypes
  | PrimaryContactActionTypes
  | SalesActionTypes
  | SalesFileActionTypes
  | StaffActionTypes
  | UserActionTypes
  | VendorsActionTypes;

export interface Error {
  action: ActionTypes | string;
  message: any;
}

export interface ErrorState {
  error: Error | null;
}

export const initialErrorState: ErrorState = { error: null };

export function errorReducer(state = initialErrorState, action) {
  switch (action.type) {
    case ErrorActionTypes.AddError:
      return {
        error: action.payload,
      };
    case ErrorActionTypes.DismissError:
      return initialErrorState;
    default:
      return state;
  }
}
