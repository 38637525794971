import * as fromSales from './sales.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectSalesState = createFeatureSelector<fromSales.SalesState>('sales');

export const selectSalesIds = createSelector(
  selectSalesState,
  fromSales.selectSalesIds
);

export const selectSalesEntities = createSelector(
  selectSalesState,
  fromSales.selectSalesEntities
);

export const selectSalesAll = createSelector(
  selectSalesState,
  fromSales.selectAllSales
);
