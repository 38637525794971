import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdType } from '../../../../../../apps/user-interface/src/constants';

@Component({
  selector: 'gcv-user-interface-identification-document-card',
  templateUrl: './identification-document-card.component.html',
  styleUrls: ['./identification-document-card.component.scss'],
})
export class IdentificationDocumentCardComponent implements OnInit {
  public idTypes: IdType[] = [{ viewValue: "Driver's License", value: 'dlicense' }];
  @Input() identificationForm: FormGroup;

  //: TODO PASS AS INPUT

  //  public identificationForm = new FormGroup({
  //   idType: new FormControl('', [Validators.required,  Validators.pattern("[a-zA-Z 0-9,'.#-]{2,30}")]),
  //   idNumber: new FormControl('', Validators.required),
  //   idFront: new FormGroup(
  //     {
  //       name: new FormControl('idFront'),
  //       filename: new FormControl('', Validators.required),
  //       s3Key: new FormControl(),
  //       status: new FormControl('review'),
  //       data: new FormControl(''),
  //     },
  //     Validators.required
  //   ),
  //   idBack: new FormGroup(
  //     {
  //       name: new FormControl('idBack'),
  //       filename: new FormControl('', Validators.required),
  //       s3Key: new FormControl(),
  //       status: new FormControl('review'),
  //       data: new FormControl(''),
  //     },
  //     Validators.required
  //   ),
  // });
  constructor() {}

  ngOnInit() {}
}
