import { DataPersistence } from '../../utils/data.persistance';
import { VendorState } from './vendors.reducer';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import {
  AssignVendorToAllDispensaries,
  AssignVendorToSingleDispensary,
  CreateBankVendor,
  CreateDispensaryVendor,
  DeleteBankVendor,
  FavoriteDispensaryVendorByTypeAndId,
  LoadVendorById,
  UnassignVendorToSingleDispensary,
  UpdateBankVendor,
  UpdateDispensaryVendor,
  VendorLoadedById,
  VendorsActionTypes,
} from './vendors.actions';
import { concatMap, map, tap } from 'rxjs/operators';
import { Bank, Dispensary, TransportVendor } from '@gcv/shared';
import { DispensaryUpdated, LoadDispensary, UpdateDispensary } from '../dispensary/dispensary.actions';
import { BankUpdated, LoadBank, UpdateBank } from '../bank/bank.actions';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class VendorEffect {
  @Effect() loadVendorById$ = this.dataPersistance.fetch(VendorsActionTypes.LoadVendorById, {
    run: (action: LoadVendorById, state: VendorState) => {
      console.log(action);
      return this.sdk
        .transportVendorIdGet(action.payload.vendorId)
        .pipe(map((res: TransportVendor) => new VendorLoadedById(res)));
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.LoadVendorById, message: err });
    },
  });

  @Effect() createDispensaryVendor$ = this.dataPersistance.fetch(VendorsActionTypes.CreateDispensaryVendor, {
    run: (action: CreateDispensaryVendor, state: VendorState) => {
      return this.sdk
        .dispensariesIdTransportVendorPost(action.payload.dispensaryId, action.payload.vendor)
        .pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.CreateDispensaryVendor, message: err });
    },
  });

  @Effect() updateDispensaryVendor$ = this.dataPersistance.fetch(VendorsActionTypes.UpdateDispensaryVendor, {
    run: (action: UpdateDispensaryVendor, state: VendorState) => {
      return this.sdk
        .dispensariesIdTransportVendorVendorTypeVendorIdPut(
          action.payload.vendorId,
          action.payload.vendorType,
          action.payload.dispensaryId,
          action.payload.vendor
        )
        .pipe(
          tap(a => console.log(a)),
          map((res: Dispensary) => new UpdateDispensary(res))
        );
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.UpdateDispensaryVendor, message: err });
    },
  });

  @Effect() updateBankVendor$ = this.dataPersistance.fetch(VendorsActionTypes.UpdateBankVendor, {
    run: (action: UpdateBankVendor, state: VendorState) => {
      return this.sdk
        .banksIdTransportVendorVendorIdPut(action.payload.vendorId, action.payload.bankId, action.payload.vendor)
        .pipe(map((res: Bank) => new UpdateBank(res)));
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.UpdateBankVendor, message: err });
    },
  });

  @Effect() createBankVendor$ = this.dataPersistance.fetch(VendorsActionTypes.CreateBankVendor, {
    run: (action: CreateBankVendor, state: VendorState) => {
      return this.sdk
        .banksIdTransportVendorPost(action.payload.bankId, action.payload.vendor)
        .pipe(
          concatMap((res: Bank) =>
            !action.payload.dispensaryId
              ? [new BankUpdated(res)]
              : [new BankUpdated(res), new LoadDispensary({ dispensaryId: action.payload.dispensaryId })]
          )
        );
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.CreateBankVendor, message: err });
    },
  });

  @Effect() deleteBankVendor$ = this.dataPersistance.fetch(VendorsActionTypes.DeleteBankVendor, {
    run: (action: DeleteBankVendor, state: VendorState) => {
      return this.sdk
        .banksIdTransportVendorVendorIdDelete(action.payload.vendorId, action.payload.bankId)
        .pipe(map((res: Bank) => new BankUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: VendorsActionTypes.DeleteBankVendor, message: err });
    },
  });

  @Effect() favoriteDispensaryVendorByTypeAndId$ = this.dataPersistance.fetch(
    VendorsActionTypes.FavoriteDispensaryVendorByTypeAndId,
    {
      run: (action: FavoriteDispensaryVendorByTypeAndId, state: VendorState) => {
        return this.sdk
          .dispensariesIdTransportVendorVendorTypeVendorIdFavoritePut(
            action.payload.vendorId,
            action.payload.type,
            action.payload.dispensaryId
          )
          .pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: VendorsActionTypes.FavoriteDispensaryVendorByTypeAndId, message: err });
      },
    }
  );

  @Effect() assignVendorToSingleDispensary$ = this.dataPersistance.fetch(
    VendorsActionTypes.AssignVendorToSingleDispensary,
    {
      run: (action: AssignVendorToSingleDispensary, state: VendorState) => {
        return this.sdk
          .banksIdTransportVendorVendorIdDispensariesDispensaryIdPut(
            action.payload.dispensaryId,
            action.payload.vendorId,
            action.payload.bankId
          )
          .pipe(
            concatMap((res: Bank) => [
              new BankUpdated(res),
              new LoadDispensary({ dispensaryId: action.payload.dispensaryId }),
            ])
          );
      },
      onError: err => {
        return new AddError({ action: VendorsActionTypes.AssignVendorToSingleDispensary, message: err });
      },
    }
  );

  @Effect() UnassignVendorToSingleDispensary$ = this.dataPersistance.fetch(
    VendorsActionTypes.UnassignVendorToSingleDispensary,
    {
      run: (action: UnassignVendorToSingleDispensary, state: VendorState) => {
        return this.sdk
          .banksIdTransportVendorVendorIdDispensariesDispensaryIdDelete(
            action.payload.dispensaryId,
            action.payload.vendorId,
            action.payload.bankId
          )
          .pipe(
            concatMap((res: Bank) => [
              new BankUpdated(res),
              new LoadDispensary({ dispensaryId: action.payload.dispensaryId }),
            ])
          );
      },
      onError: err => {
        return new AddError({ action: VendorsActionTypes.UnassignVendorToSingleDispensary, message: err });
      },
    }
  );

  @Effect() assignVendorToAllDispensaries$ = this.dataPersistance.fetch(
    VendorsActionTypes.AssignVendorToAllDispensaries,
    {
      run: (action: AssignVendorToAllDispensaries, state: VendorState) => {
        return this.sdk
          .banksIdTransportVendorVendorIdDispensariesPut(action.payload.vendorId, action.payload.bankId)
          .pipe(map((res: Bank) => new LoadBank({ bankId: action.payload.bankId })));
      },
      onError: err => {
        return new AddError({ action: VendorsActionTypes.AssignVendorToAllDispensaries, message: err });
      },
    }
  );

  constructor(private dataPersistance: DataPersistence<VendorState>, private sdk: DefaultService) {}
}
