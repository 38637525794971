import * as fromSalesFile from './salesFile.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectSalesFileState = createFeatureSelector<fromSalesFile.SalesFileState>('salesfile');

export const selectSalesFileIds = createSelector(
  selectSalesFileState,
  fromSalesFile.selectSalesFileIds
);

export const selectSalesFileEntities = createSelector(
  selectSalesFileState,
  fromSalesFile.selectSalesFileEntities
);

export const selectAllSalesFiles = createSelector(
  selectSalesFileState,
  fromSalesFile.selectAllSalesFiles
);
