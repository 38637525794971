import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { initialize, LDClient, LDFlagSet, LDUser } from 'ldclient-js';
import * as uuidv4 from 'uuid/v4';
import { User } from '@gcv/shared';
import { FeatureFlags } from './ldconstants';
import { LoggerService } from '../logger/logger.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<object> = new Subject<object>();
  constructor(private logger: LoggerService) {
    this.flags = { 'toh-modify': false, 'toh-search': false };
    const anonymousUser: LDUser = {
      key: uuidv4(),
      anonymous: true,
    };
    if (environment.gcvConfig.launchDarklyClientSideId) {
      this.ldClient = initialize(environment.gcvConfig.launchDarklyClientSideId, anonymousUser);
      this.ldClient.on('change', flags => {
        this.setFlag(FeatureFlags.FINCEN_FEATURE_MODULE_BLACKLIST, flags);
        this.setFlag(FeatureFlags.WEB_SOCKETS_ENABLED, flags);
        this.flagChange.next(this.flags);
        this.logger.log('Flags updated.');
      });

      this.ldClient.on('ready', () => {
        this.setFlags();
      });
    }
  }

  setFlags() {
    console.log();
    this.flags = this.ldClient.allFlags();
    this.logger.log('Flags set.');
  }

  changeUser(
    isAnonymous: boolean,
    user: User,
    hash?: string,
    onDone?: (err: Error | null, flags: LDFlagSet | null) => void
  ) {
    if (isAnonymous) {
      const anonymousUser: LDUser = {
        key: uuidv4(),
        anonymous: true,
      };
      this.ldClient.identify(anonymousUser);
    } else {
      const launchDarklyUser: LDUser = {
        key: user.id,
        anonymous: false,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
      };
      this.ldClient.identify(launchDarklyUser, hash, onDone);
    }
  }

  setFlag(flagName, flags) {
    if (flags[flagName] !== undefined) {
      this.flags[flagName] = flags[flagName];
    }
  }
}

// Example Usage

/*

constructor(private ld: LaunchDarkleyService) {
  this.gs450 = ld.flags[FeatureFlags.GS_450];
  this.flagSug = ld.flagChange.subscribe(flags => {
    this.gs450 = flags[FeatureFlags.GS_450].current;
  });
}

*/
