import { Action } from '@ngrx/store';
import { Dispensary, Group } from '@gcv/shared';

export enum DispensaryActionTypes {
  LoadDispensary = '[Dispensary] Load Dispensary',
  DispensaryLoaded = '[Dispensary] Dispensary Loaded',

  LoadAllDispensary = '[Dispensary] Load All Dispensaries',
  AllDispensaryLoaded = '[Dispensary] All Dispensary Loaded',

  UpdateDispensary = '[Dispensary] Update Dispensary',
  DispensaryUpdated = '[Dispensary] Dispensary Updated',

  CreateDispensary = '[Dispensary] Create Dispensary',
  DispensaryCreated = '[Dispensary] Dispensary Created',

  approveBankDueDiligence = '[Dispensary] Approve Bank Due Diligence',
  bankDueDiligenceApproved = '[Dispensary] Bank Due Diligence Approved',

  approveGcvDueDiligence = '[Dispensary] Approve Gcv Due Diligence',
  gcvDueDiligenceApproved = '[Dispensary] Gcv Due Diligence Approved',

  DispensaryUpdateGroups = '[Dispensary] Update Groups',
  DispensaryGroupsUpdated = '[Dispensary] Groups Updated',

  ResetDispensary = '[Dispensary] Reset Dispensary',
}

export class ApproveBankDueDiligence implements Action {
  readonly type = DispensaryActionTypes.approveBankDueDiligence;

  constructor(
    public payload: {
      dispensaryId: string;
      bankId: string;
      data: { documentName: string; reviewer: string; status: string };
    }
  ) {}
}

export class BankDueDiligenceApproved implements Action {
  readonly type = DispensaryActionTypes.bankDueDiligenceApproved;

  constructor(public payload: Dispensary) {}
}

export class ApproveGcvDueDiligence implements Action {
  readonly type = DispensaryActionTypes.approveGcvDueDiligence;

  constructor(public payload: { dispensaryId: string; data: { reviewer: string; status: string } }) {}
}

export class GcvDueDiligenceApproved implements Action {
  readonly type = DispensaryActionTypes.gcvDueDiligenceApproved;

  constructor(public payload: Dispensary) {}
}

export class LoadDispensary implements Action {
  readonly type = DispensaryActionTypes.LoadDispensary;

  constructor(public payload: { dispensaryId: string }) {}
}

export class DispensaryLoaded implements Action {
  readonly type = DispensaryActionTypes.DispensaryLoaded;

  constructor(public payload: Dispensary) {}
}

export class LoadAllDispensary implements Action {
  readonly type = DispensaryActionTypes.LoadAllDispensary;
}

export class AllDispensaryLoaded implements Action {
  readonly type = DispensaryActionTypes.AllDispensaryLoaded;

  constructor(public payload: Dispensary[]) {}
}

export class UpdateDispensary implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensary;

  constructor(public payload: Dispensary) {}
}

export class DispensaryUpdated implements Action {
  readonly type = DispensaryActionTypes.DispensaryUpdated;

  constructor(public payload: Dispensary) {}
}

export class CreateDispensary implements Action {
  readonly type = DispensaryActionTypes.CreateDispensary;

  constructor(public payload: Partial<Dispensary>) {}
}

export class DispensaryCreated implements Action {
  readonly type = DispensaryActionTypes.DispensaryCreated;

  constructor(public payload: Dispensary) {}
}

export class DispensaryUpdateGroups implements Action {
  readonly type = DispensaryActionTypes.DispensaryUpdateGroups;

  constructor(public payload: { dispensaryId: string; groups: Group[] }) {}
}

export class DispensaryGroupsUpdated implements Action {
  readonly type = DispensaryActionTypes.DispensaryGroupsUpdated;

  constructor(public payload: Dispensary) {}
}

export class ResetDispensary implements Action {
  readonly type = DispensaryActionTypes.ResetDispensary;
}

export type DispensaryActions =
  | LoadDispensary
  | DispensaryLoaded
  | LoadAllDispensary
  | AllDispensaryLoaded
  | UpdateDispensary
  | DispensaryUpdated
  | CreateDispensary
  | DispensaryCreated
  | DispensaryUpdateGroups
  | DispensaryGroupsUpdated
  | ResetDispensary
  | ApproveBankDueDiligence
  | BankDueDiligenceApproved
  | GcvDueDiligenceApproved
  | ApproveGcvDueDiligence;
