import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormatter',
})
export class TimeFormatterPipe implements PipeTransform {
  transform(time: any): any {
    time = time.split(':'); // convert to array

    // fetch
    const hours = Number(time[0]);
    const minutes = Number(time[1]);
    // const seconds = Number(time[2]);

    // calculate
    let timeValue;

    if (hours > 0 && hours <= 12) {
      timeValue = '' + hours;
    } else if (hours > 12) {
      timeValue = '' + (hours - 12);
    } else if (hours === 0) {
      timeValue = '12';
    }

    timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
    // timeValue += seconds < 10 ? ':0' + seconds : ':' + seconds; // get seconds
    timeValue += hours >= 12 ? ' P.M.' : ' A.M.'; // get AM/PM
    return timeValue;
  }
}
