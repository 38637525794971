import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from 'aws-amplify';
Amplify.configure(environment.amplify);
import * as AWS from 'aws-sdk';
AWS.config.region = environment.amplify.aws_cognito_region;

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
