import { Component, Input } from '@angular/core';
import { Bank } from '@gcv/shared';

@Component({
  selector: 'gcv-user-interface-bank-status-indicator',
  templateUrl: './bank-status-indicator.component.html',
  styleUrls: ['./bank-status-indicator.component.scss'],
})
export class BankStatusIndicatorComponent {
  @Input() hasBank: boolean;
  @Input() bank: Bank;

  public expanded = false;

  constructor() {}

  expandToolTip() {
    if (this.hasBank) {
      this.expanded = !this.expanded;
    }
  }
}
