import * as fromVendors from './vendors.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectVendorState = createFeatureSelector<fromVendors.VendorState>('vendors');

export const selectVendorIds = createSelector(
  selectVendorState,
  fromVendors.selectVendorIds
);

export const selectVendorEntities = createSelector(
  selectVendorState,
  fromVendors.selectVendorEntities
);

export const selectAllVendors = createSelector(
  selectVendorState,
  fromVendors.selectAllVendors
);
