import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  src: string;
  isDispensary: boolean;
  constructor(private router: Router) {
    this.isDispensary = this.router.url.search('bank') !== 1;
    this.src = this.isDispensary ? '../../assets/bank-404.svg' : '../../assets/dispensary-404.svg';
  }

  ngOnInit() {}

  handleBack() {
    const org = this.isDispensary ? 'dispensary' : 'bank';
    this.router.navigate([`/secure/${org}/dashboard/overview`]);
  }
}
