import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '@gcv/shared';
import { catchError, distinctUntilChanged, map, mergeMap, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { UserFacade } from '@user-interface/gcv-state';

@Component({
  selector: 'app-dispensary-admin-verification-container',
  templateUrl: './dispensary-admin-verification-container.component.html',
  styleUrls: ['./dispensary-admin-verification-container.component.scss'],
})
export class DispensaryAdminVerificationContainerComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  questions$: Observable<any>;
  loading = true;

  constructor(
    public dialogRef: MatDialogRef<DispensaryAdminVerificationContainerComponent>,
    private userFacade: UserFacade
  ) {}

  ngOnInit() {
    this.user$ = this.userFacade.selectCurrentUser().pipe(take(1));
    this.questions$ = this.user$.pipe(
      mergeMap((user: User) => {
        return this.userFacade.verifyUserByKba(user.id).pipe(
          take(1),
          map((results: any) => {
            if (results.length > 0) {
              return results;
            } else {
              this.close();
            }
          }),
          catchError(a => {
            return of(this.close());
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.loading = false;
  }

  verifyUser(params: { answers: any; user: User }) {
    this.userFacade
      .verifyUserKbaAnswers(params.user.id, params.answers)
      .pipe(distinctUntilChanged((a, b) => b.gcv_version_num > a.gcv_version_num))
      .subscribe((user: User) => {
        this.close(user);
      });
  }

  close(user?) {
    this.dialogRef.close(user);
  }
}
