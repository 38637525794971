import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '../../services/util-service/utils.service';
import { DEFAULT_SELECTED_RANGE_OUTPUT } from '../../constants';

@Component({
  selector: 'gcv-timefilter',
  templateUrl: './timefilter.component.html',
  styleUrls: ['./timefilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimefilterComponent implements OnInit {
  timeSpanChange$ = new BehaviorSubject(null);
  @Input()
  items: any;
  @Output()
  selectedRange: any = DEFAULT_SELECTED_RANGE_OUTPUT;
  @Output()
  startDateControl: any;
  @Output()
  endDateControl: any;
  @Output()
  filterChanged = new EventEmitter();

  constructor(private utils: UtilsService) {}

  ngOnInit() {}

  dateTimeRangeChange(dateRange) {
    this.selectedRange = dateRange;
    this.timeSpanChange$.next(this.selectedRange);
    const dateTimeRangeSelection = this.selectedRange[0];
    this.filterChanged.next(this.selectedRange);

    // if (dateTimeRangeSelection !== 'Custom') {
    //   const dateTimeRange = this.getDateTimeRange(dateTimeRangeSelection);
    //   const localDateStart = this.utils.getLocalFromUtc(dateTimeRange.start);
    //   const localDateEnd = this.utils.getLocalFromUtc(dateTimeRange.end);
    //   this.startDateControl = localDateStart;
    //   this.endDateControl = localDateEnd;
    //   this.timeSpanChange$.next(null);
    // }
  }

  getDateTimeRange(dateTimeRangeSelection) {
    let timeRange = null;
    if (dateTimeRangeSelection === 'Today') {
      timeRange = this.utils.getUtcDateTimeRangeToday();
    } else if (dateTimeRangeSelection === 'Yesterday') {
      timeRange = this.utils.getUtcDateTimeRangeYesterday();
    } else if (dateTimeRangeSelection === 'This week') {
      timeRange = this.utils.getUtcDateTimeRangeThisWeek();
    } else if (dateTimeRangeSelection === 'This month') {
      timeRange = this.utils.getUtcDateTimeRangeThisMonth();
    } else if (dateTimeRangeSelection === 'This quarter') {
      timeRange = this.utils.getUtcDateTimeRangeThisQuarter();
    } else if (dateTimeRangeSelection === 'This year') {
      timeRange = this.utils.getUtcDateTimeRangeThisYear();
    } else if (dateTimeRangeSelection === 'Last week') {
      timeRange = this.utils.getUtcDateTimeRangeLastWeek();
    } else if (dateTimeRangeSelection === 'Last month') {
      timeRange = this.utils.getUtcDateTimeRangeLastMonth();
    } else if (dateTimeRangeSelection === 'Last quarter') {
      timeRange = this.utils.getUtcDateTimeRangeLastQuarter();
    } else if (dateTimeRangeSelection === 'Last year') {
      timeRange = this.utils.getUtcDateTimeRangeLastYear();
    } else if (dateTimeRangeSelection === 'Last 30 days') {
      timeRange = this.utils.getUtcDateTimeRangeLastThirtyDays();
    } else {
      return;
    }

    return { start: timeRange.start, end: timeRange.end };
  }

  getLocalDateTimeRange() {
    let dateTimeRangeSelection = this.selectedRange;
    if (!dateTimeRangeSelection) {
      dateTimeRangeSelection = 'Last 30 days';
    }
  }
}
