import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js/min';

@Pipe({
  name: 'formatPhonePipe',
})
export class FormatPhonePipe implements PipeTransform {
  transform(phoneValue: string): any {
    try {
      if (phoneValue) {
        const phoneNumber = new AsYouType('US').input(phoneValue);
        return phoneNumber;
      }
    } catch (error) {
      return phoneValue;
    }
  }

  parse(phoneValue: string): any {
    if (phoneValue !== '') {
      const phoneNumber = phoneValue.replace(/[- )(]/g, '');
      return phoneNumber;
    } else {
      return '';
    }
  }
}
