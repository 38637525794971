import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'gcv-create-new-license-modal',
  templateUrl: './create-new-license-modal.component.html',
  styleUrls: ['./create-new-license-modal.component.scss'],
})
export class CreateNewLicenseModalComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  @ViewChild('form2') form2: ElementRef;

  @Input() licenseType: 'business' | 'employee';
  @Input() needReminder = false;
  @Output() formEmitter = new EventEmitter<FormGroup>();

  licenseInfo: FormGroup;
  ctrl: FormGroup;
  section = 0;

  public uploadedDocuments = [];
  public documentName = '';
  public documentDescription = '';
  objUrl;

  licenses = [
    {
      value: 'retail_license',
      viewValue: 'Retail License',
    },
    { value: 'llc_license', viewValue: 'LLC License' },
  ];
  reminderTimePeriods = [
    {
      value: '1',
      viewValue: '1 week',
    },
    {
      value: '2',
      viewValue: '2 weeks',
    },
    {
      value: '3',
      viewValue: '3 weeks',
    },
    {
      value: '4',
      viewValue: '4 weeks',
    },
  ];

  constructor(
    private matDialogRef: MatDialogRef<CreateNewLicenseModalComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.licenseType) {
      this.section = 1;
    }

    if (data.needReminder !== undefined) {
      this.needReminder = data.needReminder;
    }
    if (this.needReminder) {
      this.licenseInfo = new FormGroup({
        name: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required),
        expiration: new FormControl('', Validators.required),
        reminder: new FormControl(''),
      });
    } else {
      this.licenseInfo = new FormGroup({
        name: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required),
        expiration: new FormControl('', Validators.required),
      });
    }

    this.ctrl = new FormGroup({
      s3Key: new FormControl(''),
      filename: new FormControl(''),
      data: new FormControl(''),
      name: new FormControl(''),
    });
  }

  ngOnInit() {}

  isPart1Valid() {
    return (
      this.licenseInfo.controls['name'].valid &&
      this.licenseInfo.controls['number'].valid &&
      this.licenseInfo.controls['expiration'].valid &&
      this.uploadedDocuments.length > 0
    );
  }

  onFileEvent(event) {
    const file = event.target.files[0];
    if (file && /(.pdf)/.test(file.name)) {
      this.ctrl.controls['filename'].setValue(file.name);
      const dataReader = new FileReader();
      dataReader.onload = e => {
        const arrayBuffer = e.target['result'];
        const base64 = base64arraybuffer.encode(arrayBuffer);
        this.ctrl.controls['data'].setValue(base64);
        this.uploadedDocuments.push(this.ctrl.getRawValue());
        this.reset();
      };
      dataReader.readAsArrayBuffer(file);
      const urlReader = new FileReader();
      urlReader.onload = e => {
        this.objUrl = window.URL.createObjectURL(file);
        this.cdr.markForCheck();
      };
      urlReader.readAsDataURL(file);
    } else {
      if (!this.ctrl.controls['filename'].value && !this.ctrl.controls['data'].value) {
        this.ctrl.controls['filename'].setValue('');
        this.ctrl.controls['data'].setValue(null);
        this.cdr.markForCheck();
      }
    }
  }

  selectLicense(type) {
    this.licenseType = type;
    this.section = 1;
  }

  close(results = null) {
    const forms = this.licenseInfo.getRawValue();
    this.reset();
    this.matDialogRef.close({ results, forms });
    this.uploadedDocuments = [];
  }

  cancel() {
    this.reset();
    this.matDialogRef.close(null);
  }

  back() {
    this.section--;
  }

  removeDocument(i: number) {
    this.uploadedDocuments.splice(i, 1);
  }

  submitForm() {
    if (this.section === 1 && this.needReminder) {
      this.section = 2;
    } else {
      this.formEmitter.emit(this.licenseInfo);
      this.close({
        documents: this.uploadedDocuments,
      });
    }
  }

  save() {
    this.close({
      documents: this.uploadedDocuments,
    });
  }

  private reset() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.filename.setValue('');
    if (this.form) {
      this.form.nativeElement.reset();
    }

    if (this.form2) {
      this.form2.nativeElement.reset();
    }
  }
}
