import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable } from 'rxjs';
import { filter, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { AmplifyService } from 'aws-amplify-angular';
import { SecureAppFamilyService } from '../../../services/secure.service';
import { Bank, Dispensary, User } from '@gcv/shared';
import { Router } from '@angular/router';
import {
  AggregatesFacade,
  BankFacade,
  DepositsFacade,
  DispensaryFacade,
  FincenFacade,
  NotificationsFacade,
  PrimaryContactFacade,
  SalesFacade,
  StaffFacade,
  UserFacade,
} from '@user-interface/gcv-state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loading = new BehaviorSubject(false);
  loggedIn = false;

  private user: User;
  private company: { companyType: string; id: string };
  private authGuard = false;

  constructor(
    private amplifyService: AmplifyService,
    private secureService: SecureAppFamilyService,
    private router: Router,
    private userFacade: UserFacade,
    private dispFacade: DispensaryFacade,
    private aggFacade: AggregatesFacade,
    private notificationFacade: NotificationsFacade,
    private staffFacade: StaffFacade,
    private bankFacade: BankFacade,
    private saleFacade: SalesFacade,
    private primaryContactFacade: PrimaryContactFacade,
    private depositFacade: DepositsFacade,
    private fincenFacade: FincenFacade
  ) {}

  public set loggin(val) {
    this.loggedIn = val;
  }

  public get loggin() {
    return this.loggedIn;
  }

  public initLogin(authUser, changePassword, authGuard = false): Observable<boolean> {
    this.authGuard = authGuard;
    return this.userFacade.getUserById(authUser.username).pipe(
      filter((user: User) => user !== undefined),
      tap((user: User) => {
        this.user = user;
        this.company = user.companies[0];
        this.notificationFacade.getNotificationsByUserId(user.id);
      }),
      first(),
      mergeMap((user: User) => this.determineCompany(user))
    );
  }

  determineCompany(user: User) {
    if (user) {
      this.userFacade.putUserById({ ...user, lastLogin: new Date().toISOString() });
      switch (user.companies[0].companyType) {
        case 'bank':
          return this.setupBank(user, user.companies[0].id);
        case 'dispensary':
          return this.setupDispensary(user, user.companies[0]);
        case 'gcv':
          return this.setupGcv();
        default:
          this.loading.next(false);
          break;
      }
    } else {
      this.loading.next(false);
    }
  }

  setupBank(user: User, companyId: string) {
    this.bankFacade.getBankById(companyId);
    this.aggFacade.loadBankAggregate(companyId);
    this.aggFacade.loadFincenReportAggregate(companyId);
    this.aggFacade.loadFincenDepositAggregate(companyId);
    return this.bankFacade.selectBankById(companyId).pipe(
      filter((bank: Bank) => bank !== undefined),
      mergeMap((bank: Bank) => {
        // Each of the dispensaries * the number of requests;
        const dispensaryObservables = [];
        bank.dispensaries.forEach((dispensaryId: string) => {
          this.dispFacade.getDispensarybyId(dispensaryId);
          this.aggFacade.loadDispensaryAggregate(dispensaryId);
          this.aggFacade.loadSalesAggregate(dispensaryId);
          dispensaryObservables.push(
            this.dispFacade.selectDispensaryById(dispensaryId).pipe(
              filter((disp: Dispensary) => disp !== undefined),
              take(1)
            )
          );
          dispensaryObservables.push(
            this.aggFacade.selectDispensaryAggregatesById(dispensaryId).pipe(
              filter((agg: any) => agg !== undefined),
              take(1)
            )
          );
          dispensaryObservables.push(
            this.aggFacade.selectSalesAggregateById(dispensaryId).pipe(
              filter((agg: any) => agg !== undefined),
              take(1)
            )
          );
        });
        return forkJoin(dispensaryObservables).pipe(
          filter((results: any[]) => results.length === bank.dispensaries.length * 3),
          map(() => {
            return bank;
          })
        );
      }),
      mergeMap((bank: Bank) => this.routeBank(bank))
    );
  }

  routeBank(bank: Bank) {
    if (bank.onboarding && bank.onboarding.complete) {
      return from(this.handleAuthGuard('/secure/bank/dashboard/overview')).pipe(map(() => true));
    } else {
      return from(this.handleAuthGuard('/secure/onboarding')).pipe(map(() => true));
    }
  }

  setupGcv() {
    this.dispFacade.getAllDispensaries();
    this.bankFacade.getAllBanks();
    this.staffFacade.getAllOrganizationStaff();
    return from(this.handleAuthGuard('/secure/gcv/dashboard')).pipe(map(() => true));
  }

  setupDispensary(user, company) {
    this.aggFacade.loadSalesAggregate(company.id);
    this.aggFacade.loadDispensaryAggregate(company.id);
    this.dispFacade.getDispensarybyId(company.id);
    return this.dispFacade.selectDispensaryById(company.id).pipe(
      filter((dispensary: Dispensary) => dispensary !== undefined),
      first(),
      mergeMap((dispensary: Dispensary) => this.routeDispensary(dispensary))
    );
  }

  routeDispensary(dispensary: Dispensary) {
    if (dispensary.onBoardingComplete) {
      if (dispensary && dispensary.dueDiligenceStatus === 'submitted') {
        return from(this.handleAuthGuard('/secure/dispensary/dashboard/overview')).pipe(map(() => true));
      } else {
        return from(this.handleAuthGuard('/secure/dispensary/dashboard/overview-onboarding')).pipe(map(() => true));
      }
    } else {
      return from(this.handleAuthGuard('/secure/registration')).pipe(map(() => true));
    }
  }

  handleAuthGuard(route) {
    return new Promise((resolve, reject) => {
      if (!this.authGuard) {
        this.router
          .navigate([route])
          .then(() => {
            this.loading.next(false);
            resolve();
          })
          .catch(err => console.error(err));
      } else {
        this.loading.next(false);
        resolve();
      }
    });
  }

  logout(): void {
    this.amplifyService.auth().signOut();
    this.loggin = false;
    this.dispFacade.resetDispensary();
    this.userFacade.resetUser();
    this.saleFacade.resetSales();
    this.depositFacade.resetDeposits();
    this.primaryContactFacade.resetPrimaryContact();
    this.fincenFacade.resetFincen();
    this.aggFacade.resetAggregateStore();
    this.notificationFacade.resetNotifications();
    this.staffFacade.resetStaff();
    this.bankFacade.resetBankState();
    this.amplifyService.setAuthState({ state: 'signedOut', user: null });
    this.loading.next(false);
  }

  isUserLoggedIn() {
    return from(this.amplifyService.auth().currentAuthenticatedUser());
  }
}
