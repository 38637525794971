import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sale } from '@gcv/shared';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'gcv-sales-side-drawer',
  templateUrl: './sales-side-drawer.component.html',
  styleUrls: ['./sales-side-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesSideDrawerComponent {
  @ViewChild('sidenavs') sideNav: MatSidenav;
  @Input() opened = false;
  @Input() items: Sale;
  @Output() toggled = new EventEmitter<boolean>();

  constructor() {}

  toggle() {
    this.toggled.emit(this.sideNav.opened);
  }

  close() {
    this.sideNav.close();
    this.toggled.emit(false);
  }
}
