import { Action } from '@ngrx/store';
import { SalesFileHash } from './salesFile.reducer';

export enum SalesFileActionTypes {
  CheckSalesFileHash = '[SalesFile] Check Sales File Hash',
  SalesFileHashChecked = '[SalesFile] Sales File Hash Checked',

  ResetSalesFile = '[SalesFile] Reset Sales File',
}

export class CheckSalesFileHash implements Action {
  readonly type = SalesFileActionTypes.CheckSalesFileHash;

  constructor(public payload: { hash: string }) {}
}

export class SalesFileHashChecked implements Action {
  readonly type = SalesFileActionTypes.SalesFileHashChecked;

  constructor(public payload: SalesFileHash) {}
}

export class ResetSalesFile implements Action {
  readonly type = SalesFileActionTypes.ResetSalesFile;
}

export type SalesFileActions = ResetSalesFile | SalesFileHashChecked | CheckSalesFileHash;
