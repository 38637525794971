import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ErrorState } from './error.reducer';
import { Observable } from 'rxjs';
import { AddError, DismissError } from './error.actions';
import { selectDispensaryState } from './error.selectors';

@Injectable({
  providedIn: 'root',
})
export class ErrorFacade {
  error$: Observable<ErrorState>;

  constructor(private store: Store<ErrorState>) {
    this.error$ = store.pipe(select(selectDispensaryState));
  }

  addNewError(action: string, message: string) {
    this.store.dispatch(new AddError({ action: action, message }));
  }

  clearError() {
    this.store.dispatch(new DismissError());
  }
}
