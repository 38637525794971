import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as base64arraybuffer from 'base64-arraybuffer';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'gcv-user-interface-due-diligence-file-upload-modal',
  templateUrl: './due-diligence-file-upload-modal.component.html',
  styleUrls: ['./due-diligence-file-upload-modal.component.scss'],
})
export class DueDiligenceFileUploadModalComponent {
  @ViewChild('form') form: ElementRef;
  @ViewChild('form2') form2: ElementRef;
  ctrl = new FormGroup({
    s3Key: new FormControl(''),
    filename: new FormControl(''),
    data: new FormControl(''),
    name: new FormControl(''),
  });
  disabledCtrl = new FormGroup({
    disabled: new FormControl(false, Validators.required),
    comment: new FormControl('', Validators.required),
  });

  public documentName = '';
  public documentDescription = '';
  public uploadedDocuments = [];
  public uploadedDocsObjUrls = [];

  constructor(
    private matDialogRef: MatDialogRef<DueDiligenceFileUploadModalComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.documentName = data.documentName;
    this.documentDescription = data.documentDescription;
  }

  onFileEvent(event) {
    const file = event.target.files[0];
    if (file && /(.pdf)/.test(file.name)) {
      this.ctrl.controls['filename'].setValue(file.name);
      const dataReader = new FileReader();
      dataReader.onload = e => {
        const arrayBuffer = e.target['result'];
        const base64 = base64arraybuffer.encode(arrayBuffer);
        this.ctrl.controls['data'].setValue(base64);
        this.uploadedDocuments.push(this.ctrl.getRawValue());
        this.reset();
      };
      dataReader.readAsArrayBuffer(file);
      const urlReader = new FileReader();
      urlReader.onload = e => {
        this.uploadedDocsObjUrls.push(window.URL.createObjectURL(file));
        this.cdr.markForCheck();
      };
      urlReader.readAsDataURL(file);
    } else {
      if (!this.ctrl.controls['filename'].value && !this.ctrl.controls['data'].value) {
        this.ctrl.controls['filename'].setValue('');
        this.ctrl.controls['data'].setValue(null);
        this.cdr.markForCheck();
      }
    }
  }

  removeDocument(i: number) {
    this.uploadedDocuments.splice(i, 1);
  }

  close(results = null) {
    this.reset();
    this.matDialogRef.close({ results });
    this.uploadedDocuments = [];
  }

  save() {
    if (this.disabledCtrl.get('disabled').value) {
      this.close({
        isDisabled: this.disabledCtrl.get('disabled').value,
        comments: this.disabledCtrl.get('comment').value,
        documents: this.uploadedDocuments,
      });
    } else {
      this.close({
        isDisabled: false,
        documents: this.uploadedDocuments,
      });
    }
  }

  viewDocument(index: number) {
    window.open(this.uploadedDocsObjUrls[index], '_blank');
  }

  private reset() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.filename.setValue('');
    this.disabledCtrl.reset();
    if (this.form) {
      this.form.nativeElement.reset();
    }

    if (this.form2) {
      this.form2.nativeElement.reset();
    }
    this.uploadedDocsObjUrls = [];
  }
}
