import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'gcv-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSelectComponent implements OnInit {
  @Input() control;
  @Input() group;
  @Input() options: any[];
  @Input() placeholder = '';
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.group.controls[this.control].valueChanges.pipe(
      startWith<string | any>(''),
      map((value: any) => (typeof value === 'string' ? value : value.viewValue)),
      map(name => (name ? this._filter(name) : this.options.slice()))
    );
  }

  displayFn(option: any): string | undefined {
    return option ? option.viewValue : undefined;
  }

  private _filter(value: any): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.viewValue.toLowerCase().indexOf(filterValue) === 0);
    }
  }
}
