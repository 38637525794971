import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DepositsFacade, SalesFacade } from '@user-interface/gcv-state';
import { Deposit } from '@gcv/shared';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DepositSalesRouteResolver implements Resolve<any> {
  constructor(private depositFacade: DepositsFacade, private salesFacade: SalesFacade) {}

  resolve(route: ActivatedRouteSnapshot) {
    const depositId = route.queryParams['id'];
    const deposit$ = this.depositFacade.selectDepositById(depositId).pipe(
      filter((deposit: Deposit) => deposit !== undefined),
      take(1)
    );
    return deposit$.pipe(
      map((deposit: Deposit) =>
        this.salesFacade.getSalesByDepositIdAndDispensaryId(deposit.deposit_id, deposit.dispensary_id)
      )
    );
  }
}
