import { Injectable } from '@angular/core';
import { DataPersistence } from '../../utils/data.persistance';
import { FincenState } from './fincen.reducer';
import { Effect } from '@ngrx/effects';
import {
  CreateFincen,
  ExportReportAsXml,
  FincenActionTypes,
  FincenCreated,
  FincenLoaded,
  FincenUpdated,
  LoadFincen,
  UpdateFincen,
} from './fincen.actions';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';
import { map } from 'rxjs/operators';
import { AddError } from '../error';
import { XmlExportService } from '../../services/xmlExport.service';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class FincenEffect {
  @Effect() loadFincen$ = this.dataPersistance.fetch(FincenActionTypes.LoadFincen, {
    run: (action: LoadFincen, state: FincenState) => {
      return this.sdk
        .fincenReportsBankIdDispensaryIdGet(action.payload.bankId, action.payload.dispensaryId)
        .pipe(map((res: (FincenCtrReport | FincenSarReport)[]) => new FincenLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.LoadFincen, message: err });
    },
  });

  @Effect() updateFincen$ = this.dataPersistance.fetch(FincenActionTypes.UpdateFincen, {
    run: (action: UpdateFincen, state: FincenState) => {
      return this.sdk
        .fincenReportsBankIdPut(action.payload.id, action.payload)
        .pipe(map((res: FincenSarReport | FincenCtrReport) => new FincenUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.UpdateFincen, message: err });
    },
  });

  @Effect() createFincen$ = this.dataPersistance.fetch(FincenActionTypes.CreateFincen, {
    run: (action: CreateFincen, state: FincenState) => {
      return this.sdk
        .fincenReportsPost(action.payload)
        .pipe(map((res: FincenSarReport | FincenCtrReport) => new FincenCreated(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.CreateFincen, message: err });
    },
  });

  @Effect() exportReportAsXml$ = this.dataPersistance.fetch(FincenActionTypes.ExportReportAsXml, {
    run: (action: ExportReportAsXml, state: FincenState) => {
      const body = {
        fiName: action.payload.bankName,
        selected: action.payload.reports,
      };
      return this.sdk
        .fincenReportsXmlPost(body)
        .pipe(map((res: any) => this.xmlExportService.export(res, action.payload.reports)))
        .pipe(
          map(
            () =>
              new LoadFincen({
                bankId: action.payload.reports[0].bank_id,
                dispensaryId: action.payload.reports[0].dispensary_id,
              })
          )
        );
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.ExportReportAsXml, message: err });
    },
  });

  constructor(
    private dataPersistance: DataPersistence<FincenState>,
    private sdk: DefaultService,
    private xmlExportService: XmlExportService
  ) {}
}
