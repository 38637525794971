import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDynamicTitle',
})
export class FormatDynamicTitlePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return `${value} (${args})`;
  }
}
