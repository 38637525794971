import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-onboarding-checklist-menu',
  templateUrl: './onboarding-checklist-menu.component.html',
  styleUrls: ['./onboarding-checklist-menu.component.scss'],
})
export class OnboardingChecklistMenuComponent implements OnInit {
  @Input() isActive;
  @Input() menuItems: { text: string; complete: boolean }[];
  @Output() itemClickEvent = new EventEmitter<{ index: number }>();
  @Output() buttonClickEvent = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  handleMenuItemClicked(index: number) {
    this.itemClickEvent.emit({ index });
  }

  handleButtonClicked() {
    this.buttonClickEvent.emit();
  }
}
