import { Action } from '@ngrx/store';
import { User } from '@gcv/shared';

export enum PrimaryContactActionTypes {
  LoadPrimaryContact = '[User] Load Primary Contact',
  PrimaryContactLoaded = '[User] Primary Contact Loaded',

  ApprovePrimaryContact = '[User] Approve Primary Contact',
  PrimaryContactApproved = '[User] Primary Contact Approved',
  ResetPrimaryContact = '[User] Clear Primary Contact',
}

export class LoadPrimaryContact implements Action {
  readonly type = PrimaryContactActionTypes.LoadPrimaryContact;

  constructor(public payload: { primaryContactId: string }) {}
}

export class ApprovePrimaryContact implements Action {
  readonly type = PrimaryContactActionTypes.ApprovePrimaryContact;

  constructor(public payload: User) {}
}

export class PrimaryContactApproved implements Action {
  readonly type = PrimaryContactActionTypes.PrimaryContactApproved;

  constructor(public payload: User) {}
}

export class PrimaryContactLoaded implements Action {
  readonly type = PrimaryContactActionTypes.PrimaryContactLoaded;

  constructor(public payload: User) {}
}

export class ResetPrimaryContact implements Action {
  readonly type = PrimaryContactActionTypes.ResetPrimaryContact;
}

export type PrimaryContactActions =
  | LoadPrimaryContact
  | PrimaryContactLoaded
  | ResetPrimaryContact
  | ApprovePrimaryContact
  | PrimaryContactApproved;
