import { Injectable } from '@angular/core';
import { mockDispensary } from './mock.data';

@Injectable({
  providedIn: 'root',
})
export class DispensaryService {
  dispensaries = {};
  groups = {};

  constructor() {
    const dispensary = mockDispensary('123456');
    this.dispensaries[dispensary.id] = dispensary;
    this.dispensaries[dispensary.id].groups.forEach(group => {
      this.groups[group.id] = group;
    });
  }

  getDispensaryById(id) {
    return this.dispensaries[id];
  }

  putDispensary(data) {
    this.dispensaries[data.id] = data;
    return this.dispensaries[data.id];
  }

  createDispensary(data) {
    const dispensary = mockDispensary(data.id);
    this.dispensaries[dispensary.id] = dispensary;
    return this.dispensaries[dispensary.id];
  }

  updateGroups(id, groups) {
    this.dispensaries[id].groups = groups;
    return this.dispensaries[id];
  }

  loadAllDispensaries() {
    return Object.values(this.dispensaries);
  }

  bankdd(id) {
    const disp = (this.dispensaries[id].dueDiligence = {
      bankReview: [
        {
          documentName: 'cannabisLicenseCopy',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-12T15:34:21.010Z',
        },
      ],
    });
    this.dispensaries[id] = disp;
    return disp;
  }

  gcvdd(id) {
    const disp = (this.dispensaries[id].dueDiligence = {
      gcvReview: [
        {
          status: 'reviewed',
          timestamp: '2019-04-12T15:27:50.188Z',
        },
      ],
    });
    this.dispensaries[id] = disp;
    return disp;
  }
}
