import { Notification } from '@gcv/shared';

export function mockBank(id) {
  return {
    phoneNumber: '1121121111',
    certificateOrCharterNumber: '12345',
    orgName: '0410 MK Bank',
    ein: '111111111',
    address: '456 high st',
    financialInstOffice: 'compliance',
    country: 'United States',
    orgType: 'bank',
    dispensaries: ['40d1c652-2648-4fc6-b22c-5fa0accbee27', '464a0552-97d8-4d49-a39c-f187ee93a482'],
    state: 'Connecticut',
    personal: {
      firstName: 'Mike',
      lastName: 'Kennedy',
      emailAddress: 'mkennedy+0410bank@greencheckverified.com',
      phoneNumber: '1231231232',
      title: 'Administrator',
    },
    city: 'new haven',
    groups: [
      {
        name: 'Admin Users',
        id: '4c32df4a-05d8-46e3-ae25-37ff408c00fd',
        type: 'administration',
        users: ['a12de251-3390-40b0-8abe-12b0e1d440c2', '89133fb8-52fb-4b20-8ab8-fb17db964feb'],
        roles: ['bank_admin'],
      },
      {
        name: 'Account Owner',
        id: '5bbab97b-0eb7-4713-afbf-051c73701d15',
        type: 'account_owner',
        users: ['a12de251-3390-40b0-8abe-12b0e1d440c2'],
        roles: ['bank_primary_contact'],
      },
      {
        name: 'Standard Users',
        id: '99084ec0-f951-4292-b241-7f421db47c4c',
        type: 'standard_users',
        users: ['0c0a6bc2-7c00-465d-a78d-ff1b9d55a6a7'],
        roles: ['bank_user'],
      },
    ],
    transportVendors: [
      {
        name: 'mike moverrs',
        active: true,
        id: 'db47fd3c-d171-4089-ad1d-e290a2f1b80e',
        favorite: true,
      },
    ],
    financialInstOfficeNum: '7',
    primaryReg: 'FDIC',
    assignedVendors: {
      '40d1c652-2648-4fc6-b22c-5fa0accbee27': 'db47fd3c-d171-4089-ad1d-e290a2f1b80e',
      '464a0552-97d8-4d49-a39c-f187ee93a482': 'db47fd3c-d171-4089-ad1d-e290a2f1b80e',
    },
    onboarding: {
      complete: true,
      section: 2,
    },
    id: id,
    postalCode: '06515',
    gcv_version_num: 12,
  };
}

export const mockUser = userId => {
  return {
    address: 'Hamden',
    city: 'new milford',
    cognitoIdentityId: 'us-east-1:4ae665c5-1e61-44d0-83ad-5a798bf56d4e',
    companies: [
      {
        companyType: 'dispensary',
        id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      },
    ],
    country: 'United States',
    dateOfBirth: '1991-05-04T00:00:00.000Z',
    email: 'jrinciari+aprild3@greencheckverified.com',
    firstName: 'Jon',
    gcv_version_num: 427,
    history: {
      '/login': {
        firstDateAccessed: '2019-04-04T14:32:01.077Z',
        lastDateAccessed: '2019-04-08T20:34:25.300Z',
        timesVisited: 23,
      },
      '/secure/dispensary/company/overview': {
        firstDateAccessed: '2019-04-04T14:28:36.167Z',
        lastDateAccessed: '2019-04-08T12:58:57.989Z',
        timesVisited: 19,
      },
      '/secure/dispensary/compliance': {
        firstDateAccessed: '2019-04-04T18:31:20.375Z',
        lastDateAccessed: '2019-04-08T12:50:06.943Z',
        timesVisited: 3,
      },
      '/secure/dispensary/dashboard/deposits': {
        firstDateAccessed: '2019-04-04T14:58:23.347Z',
        lastDateAccessed: '2019-04-08T13:07:25.495Z',
        timesVisited: 5,
      },
      '/secure/dispensary/dashboard/overview': {
        firstDateAccessed: '2019-04-04T14:27:48.802Z',
        lastDateAccessed: '2019-04-08T16:53:57.140Z',
        timesVisited: 57,
      },
      '/secure/dispensary/dashboard/sales': {
        firstDateAccessed: '2019-04-04T14:38:04.782Z',
        lastDateAccessed: '2019-04-08T13:07:23.812Z',
        timesVisited: 16,
      },
      '/secure/dispensary/deposit': {
        firstDateAccessed: '2019-04-04T14:38:32.493Z',
        lastDateAccessed: '2019-04-08T16:22:49.130Z',
        timesVisited: 16,
      },
      '/secure/dispensary/users/group/edit?id=c56fd128-a1f2-4c54-aa8d-73a163581ef3': {
        firstDateAccessed: '2019-04-04T18:19:58.227Z',
        lastDateAccessed: '2019-04-04T18:21:25.169Z',
        timesVisited: 3,
      },
      '/secure/dispensary/users/groups': {
        firstDateAccessed: '2019-04-04T18:26:30.314Z',
        lastDateAccessed: '2019-04-04T18:31:11.964Z',
        timesVisited: 4,
      },
      '/secure/dispensary/users/overview': {
        firstDateAccessed: '2019-04-04T18:31:19.237Z',
        lastDateAccessed: '2019-04-08T12:50:07.577Z',
        timesVisited: 5,
      },
      '/secure/registration': {
        firstDateAccessed: '2019-04-04T14:23:09.134Z',
        lastDateAccessed: '2019-04-04T15:56:11.655Z',
        timesVisited: 2,
      },
    },
    id: userId,
    identification: {
      idBack: {
        filename: 'Screen Shot 2019-03-09 at 2.08.12 PM.png',
        name: 'idBack',
        s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/idBack',
        status: 'review',
      },
      idFront: {
        filename: 'Screen Shot 2019-04-02 at 12.49.44 PM.png',
        name: 'idFront',
        s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/idFront',
        status: 'review',
      },
    },
    identityVerified: false,
    idNumber: '235235',
    idType: 'dlicense',
    invitation_status: 'pending',
    isCitizen: 'true',
    lastLogin: '2019-04-08T20:34:23.654Z',
    lastName: 'Rinciari',
    phone: '2037330495',
    primaryContact: true,
    role: 'dispensary_admin',
    state: 'Connecticut',
    username: 'jrinciari+aprild3@greencheckverified.com',
    zipcode: '06776',
  };
};

export function mockDispensary(id) {
  return {
    businessHours: {
      friday: {
        closeHour: '17:00',
        isOpen: true,
        openHour: '09:00',
      },
      monday: {
        closeHour: '17:00',
        isOpen: true,
        openHour: '09:00',
      },
      saturday: {
        closeHour: '17:00',
        isOpen: false,
        openHour: '09:00',
      },
      sunday: {
        closeHour: '17:00',
        isOpen: false,
        openHour: '09:00',
      },
      thursday: {
        closeHour: '17:00',
        isOpen: true,
        openHour: '09:00',
      },
      tuesday: {
        closeHour: '17:00',
        isOpen: true,
        openHour: '09:00',
      },
      wednesday: {
        closeHour: '17:00',
        isOpen: true,
        openHour: '09:00',
      },
    },
    cannabisLicenseExpirationDate: '2027-03-04T05:00:00.000Z',
    cannabisLicenseNumber: 'CT12345',
    cannabisLicenseType: 'Medical',
    city: 'New Haven',
    country: 'United States',
    documents: [
      {
        filename: 'Cannabis Liscense.pdf',
        name: 'cannabisLicenseCopy',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/cannabisLicenseCopy',
        status: 'approve',
      },
      {
        filename: 'Certificate of Good Standing.pdf',
        name: 'certificateOfGoodStanding',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/certificateOfGoodStanding',
        status: 'approve',
      },
      {
        filename: 'Tax Return.pdf',
        name: 'taxDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/taxDoc',
        status: 'approve',
      },
      {
        filename: 'Balance Sheet.pdf',
        name: 'balanceDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/balanceDoc',
        status: 'approve',
      },
      {
        filename: 'Income Statement.pdf',
        name: 'incomeDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/incomeDoc',
        status: 'approve',
      },
      {
        filename: 'Policy and Procedure Manual.pdf',
        name: 'policyDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/policyDoc',
        status: 'approve',
      },
      {
        filename: 'Commercial Lease.pdf',
        name: 'leaseDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/leaseDoc',
        status: 'approve',
      },
      {
        filename: 'Beneficial Ownership.pdf',
        name: 'beneficialOwnerDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/beneficialOwnerDoc',
        status: 'approve',
      },
      {
        filename: 'Articles of Organization.pdf',
        name: 'articlesDoc',
        s3Key: '24799898-348d-42d2-b447-45131b29aa17/documents/articlesDoc',
        status: 'approve',
      },
    ],
    dueDiligence: {
      bankReview: [
        {
          documentName: 'cannabisLicenseCopy',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:44.668Z',
        },
        {
          documentName: 'balanceDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:46.818Z',
        },
        {
          documentName: 'leaseDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:49.123Z',
        },
        {
          documentName: 'certificateOfGoodStanding',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:52.230Z',
        },
        {
          documentName: 'incomeDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:54.732Z',
        },
        {
          documentName: 'beneficialOwnerDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:56.668Z',
        },
        {
          documentName: 'taxDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:28:59.798Z',
        },
        {
          documentName: 'policyDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:29:02.233Z',
        },
        {
          documentName: 'articlesDoc',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:29:05.193Z',
        },
        {
          documentName: 'idFront',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:29:11.407Z',
        },
        {
          documentName: 'idBack',
          reviewer: 'Mike Kennedy',
          status: 'reviewed',
          timestamp: '2019-04-04T20:29:13.816Z',
        },
      ],
      gcvReview: [
        {
          status: 'reviewed',
          timestamp: '2019-04-04T20:27:16.150Z',
        },
      ],
    },
    dueDiligenceStatus: 'submitted',
    ein: '999999999',
    entityType: 'partnership',
    establishedDate: '2019-04-03T04:00:00.000Z',
    ftEmployees: '12',
    gcv_version_num: 31,
    groups: [
      {
        id: '7e270c3c-a079-44b2-9761-4e12eacefd5d',
        name: 'Admin Users',
        roles: ['dispensary_admin'],
        type: 'administration',
        users: ['dd54202a-319a-4b26-9a9c-7a273761d0a7'],
      },
      {
        id: '8542137a-4480-4c79-ad79-fbb5c111527c',
        name: 'Account Owner',
        roles: ['dispensary_account_owner'],
        type: 'account_owner',
        users: ['123456'],
      },
      {
        id: 'f92b52d4-ef5e-4811-aed6-55a0e750aadb',
        name: 'Standard Users',
        roles: ['dispensary_user'],
        type: 'standard_users',
        users: ['335fd7be-1b14-45a2-a8eb-ac85dac03401', '6ed4571d-dc62-40ce-bedc-3af6d5883c3c'],
      },
      {
        id: '8bc0ed77-f622-431e-8fff-aba3b879f78c',
        name: 'Deposit Transportation',
        roles: ['dispensary_deposit_transport'],
        type: 'deposit_transport',
        users: [
          'dd54202a-319a-4b26-9a9c-7a273761d0a7',
          '335fd7be-1b14-45a2-a8eb-ac85dac03401',
          '6ed4571d-dc62-40ce-bedc-3af6d5883c3c',
        ],
      },
    ],
    id: id,
    initial_sar_filed: true,
    initialPercentageCashOnHand: '12',
    methodOfTransportation: {
      bank: [],
      user: [
        {
          active: true,
          favorite: false,
          id: 'dd54202a-319a-4b26-9a9c-7a273761d0a7',
          name: 'Mike Kennedy',
        },
        {
          active: false,
          favorite: false,
          id: '335fd7be-1b14-45a2-a8eb-ac85dac03401',
          name: 'Jane Doe',
        },
        {
          active: false,
          favorite: false,
          id: '6ed4571d-dc62-40ce-bedc-3af6d5883c3c',
          name: 'Frank Kich',
        },
      ],
      vendor: [],
    },
    monthlyCustomers: '1212',
    monthlySales: '1,000,000.00',
    name: '0404 MK3 Dispensary',
    onBoardingComplete: true,
    orgType: 'dispensary',
    phone: '2222222222',
    posName: 'Leaf Logix',
    postalCode: '06515',
    ptEmployees: '1',
    state: 'Connecticut',
    streetAddress: '123 Main St',
    website: 'https://www.mike.com',
  };
}

export const BankAggregate = {
  today: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  yesterday: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  thisWeek: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  thisMonth: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  thisQuarter: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  thisYear: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  lastWeek: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  lastMonth: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  lastQuarter: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  lastYear: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
  last30Days: {
    totalSales: Math.floor(Math.random() * 10000),
    verifiedSales: Math.floor(Math.random() * 10000),
    verifiedSalesPercent: Math.floor(Math.random() * 10000),
    unverifiedSales: Math.floor(Math.random() * 10000),
    unverifiedSalesPercent: Math.floor(Math.random() * 10000),
    salePercentIncrease: Math.floor(Math.random() * 10000),
    totalPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchases: Math.floor(Math.random() * 10000),
    verifiedPurchasePercent: Math.floor(Math.random() * 10000),
    unverifiedPurchases: Math.floor(Math.random() * 10000),
    unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  },
};

export const FincenDepositAggregate = {
  unverifiedSalesPercent: Math.floor(Math.random() * 10000),
  verifiedPurchases: Math.floor(Math.random() * 10000),
  unverifiedSales: Math.floor(Math.random() * 10000),
  verifiedSalesPercent: Math.floor(Math.random() * 10000),
  totalPurchases: Math.floor(Math.random() * 10000),
  verifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  totalDeposits: Math.floor(Math.random() * 10000),
  unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  unverifiedPurchases: Math.floor(Math.random() * 10000),
  totalSales: Math.floor(Math.random() * 10000),
  totalSalesDeposited: Math.floor(Math.random() * 10000),
  verifiedSales: Math.floor(Math.random() * 10000),
};

export const FincenAggregate = {
  status: 'active',
  name: 'AC 0410 Dispensary 3',
  dispensaryId: '6607fa9c-b988-4d2b-bbb4-a81b68e651cc',
  sarPeriod: 1,
  sarPeriodStart: '2019-04-10T17:30:31.178Z',
  sarPeriodEnd: '2019-07-09T17:30:31.178Z',
  totalDeposits: 0,
  depositCount: 0,
  openReports: 1,
  ctrCount: 0,
  depositIds: [],
};

export const SalesAggregate = {
  '2019': {
    bankable: 1204472,
    unbankable: 59182,
    bankableRecords: 334,
    unbankableRecords: 18,
  },
  '2019-04-08': {
    bankable: 1204472,
    unbankable: 59182,
    bankableRecords: 334,
    unbankableRecords: 18,
  },
  '2019-04': {
    bankable: 1204472,
    unbankable: 59182,
    bankableRecords: 334,
    unbankableRecords: 18,
  },
};

export const DispensaryAggregate = {
  totalSales: Math.floor(Math.random() * 10000),
  verifiedSales: Math.floor(Math.random() * 10000),
  verifiedSalesPercent: Math.floor(Math.random() * 10000),
  unverifiedSales: Math.floor(Math.random() * 10000),
  unverifiedSalesPercent: Math.floor(Math.random() * 10000),
  salePercentIncrease: Math.floor(Math.random() * 10000),
  totalPurchases: Math.floor(Math.random() * 10000),
  verifiedPurchases: Math.floor(Math.random() * 10000),
  verifiedPurchasePercent: Math.floor(Math.random() * 10000),
  unverifiedPurchases: Math.floor(Math.random() * 10000),
  unverifiedPurchasesPercent: Math.floor(Math.random() * 10000),
  acceptedDeposits: Math.floor(Math.random() * 10000),
  pendingDeposits: Math.floor(Math.random() * 10000),
  maxDeposit: Math.floor(Math.random() * 10000),
};

export function mockDeposit(dispId) {
  return {
    dispensary_id: dispId,
    end_date: '2019-04-08T20:59:59Z',
    status: 'pending',
    comments: 'Dispensary 3 comment',
    final_deposit: Math.floor(Math.random() * 100000),
    delMethod: {
      name: "Pete's Boosted Board",
      active: true,
      id: '85458100-5dc3-47f6-97b5-23bf32312742',
      type: 'bank',
      favorite: true,
    },
    total_dollars_compliant: Math.floor(Math.random() * 100000),
    total_amount_green_checked: Math.floor(Math.random() * 100000),
    total_amount_warning: Math.floor(Math.random() * 100000),
    deposit_date: '2019-04-10T18:32:24.684Z',
    num_records_red_flagged: Math.floor(Math.random() * 100),
    num_records_green_checked: Math.floor(Math.random() * 100),
    max_deposit: Math.floor(Math.random() * 100000),
    start_date: '2019-04-08T08:00:00Z',
    total_amount_red_flagged: Math.floor(Math.random() * 100000),
    num_records_warning: Math.floor(Math.random() * 100),
    deposit_id: `${Math.floor(Math.random() * 1000000)}`,
    gcv_version_num: 1,
    total_dollars_sold: Math.floor(Math.random() * 100000),
  };
}

export function mockReport(bankId, dispensaryId, type, reportId) {
  if (type === 'CTR') {
    return {
      dispensary_id: dispensaryId,
      part3FormArray: [
        {
          multipleTransactions: false,
          transactorAddress: '234 St',
          tinType: 'EIN',
          transactorCity: 'Greenwich',
          transactorCountry: 'unitedstates',
          unknownIdentity: false,
          identificationCountry: 'unitedstates',
          identificationMethod: 'driversLicense',
          cashOutAmount: 0,
          subjectDescription: '50',
          tin: '1234567',
          contactNumber: '123456789',
          cashInAmount: 1239533,
          ifEntity: true,
          transactorState: 'Connecticut',
          transactorZip: '06830',
          identificationNumber: '123212212',
          legalNameOrLastName: 'AC 0410 Dispensary 2',
          identificationState: 'Connecticut',
        },
        {
          multipleTransactions: false,
          transactorAddress: '13 St',
          tinType: 'EIN',
          transactorCity: 'Greenwich',
          transactorCountry: 'unitedstates',
          unknownIdentity: false,
          identificationCountry: 'unitedstates',
          identificationMethod: 'driversLicense',
          cashOutAmount: 0,
          subjectDescription: '58',
          tin: '1234567',
          contactNumber: '12345',
          cashInAmount: 12395.33,
          ifEntity: true,
          transactorState: 'Connecticut',
          transactorZip: '06830',
          identificationNumber: '123212212',
          legalNameOrLastName: "Andreea's Boosted Board",
          identificationState: 'Connecticut',
        },
      ],
      status: 'complete',
      exportAudit: [
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T19:42:30.353Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T19:48:12.173Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T19:48:47.717Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:49:05.816Z',
        },
      ],
      due_date: '2019-04-25T00:00:00.000Z',
      part1FormGroup: {
        insitutionCity: 'new haven',
        institutionState: 'Connecticut',
        country: 'unitedstates',
        primaryFederalReg: 'FRB',
        ein: '123456y',
        insitutionAddress: '123 St',
        otherGamingInst: false,
        zipcode: '06830',
        contactOffice: 'Hello',
        legalNameOfFilingInstitution: 'AC 0410 Bank',
        filingInstitutionIdType: 'RSSD',
        stateCasino: false,
        financialInstitutionSelect: 'Depository institution',
        contactPhoneNumber: '1234567',
        cardClub: false,
        tribalAuthorizedCasino: false,
      },
      green_check_transactions: [
        {
          end_date: '2019-04-08T20:59:23Z',
          dispensary_id: 'a637494e-4ee2-480a-9d7e-8c33ab6ae49d',
          total_amount_green_checked: 1202357,
          comments: 'Dispensary 2 comment',
          total_amount_red_flagged: 109924,
          final_deposit: 1239533,
          total_dollars_sold: 1349457,
          total_dollars_compliant: 1239533,
          gcv_version_num: 2,
          delMethod: {
            name: "Andreea's Boosted Board",
            active: true,
            id: 'b7b26eb2-cc1c-46d8-8599-82347c51c33a',
            type: 'vendor',
            favorite: false,
          },
          deposit_date: '2019-04-10T18:20:05.124Z',
          num_records_red_flagged: 26,
          total_amount_warning: 37176,
          num_records_green_checked: 310,
          accepted_date: '2019-04-10T18:40:58.769Z',
          bank_comments: 'Bank comment',
          arrived_date: '2019-04-10T04:00:00.000Z',
          num_records_warning: 10,
          max_deposit: 1239533,
          deposit_id: 'bb0fd007-386e-48d2-adea-4a1e22adce6c',
          status: 'accepted',
          start_date: '2019-04-08T08:00:00Z',
        },
      ],
      part2FormArray: [
        {
          stateLicensedCasino: false,
          transactionCity: 'Greenwich',
          tribalCasino: false,
          transactionAltName: '345',
          transactionFinancialId: '345',
          otherTransactionType: false,
          transactionFinancialIns: 'AC 0410 Bank',
          transactionAddress: '123 St',
          transactionCashIn: 123.9533,
          transactionEin: '123456y',
          transactionCardClub: false,
          transactionCountry: 'unitedstates',
          typeOfFinancialInstitution: 'Depository Institution',
          transactionState: 'Connecticut',
          transactionFinancialIdType: 'RSSD',
          transactionCashOut: '34345',
          transactionFederalRegulator: 'FRB',
          transactionZip: '06830345',
        },
      ],
      part4FormGroup: {
        mailDeposit: false,
        currencyWagers: 0,
        nightDeposit: false,
        otherDeposits: 0,
        paymentOnWagers: 0,
        payments: 0,
        cashOutExchangedCurrency: 0,
        sharedBranching: false,
        paymentForPromotions: 0,
        travelAndCompExpenses: 0,
        currencyExchange: 0,
        armoredCar: false,
        totalCashIn: 1239533,
        aggregatedTransaction: false,
        atm: false,
        cashOutWithdrawls: 0,
        foreignCashOut: 0,
        totalCashOut: 0,
        currencyReceived: 0,
        casinoChipRedemption: 0,
        foreignCashIn: 0,
        currencyPaidFromTransfers: 0,
        deposits: 12395.33,
        billsInsertedIntoGamingDevice: 0,
        currencyFromPrepaidAccess: 0,
        dateOfTransaction: '2019-04-08',
        purchaseOfCasinoChipsEtc: 0,
        negotiableInstrumentsCashed: 0,
        otherWithdrawls: 0,
        prepaidAccess: 0,
        cashOutAdvances: 0,
        purchaseOfNegotiableInstruments: 0,
      },
      bank_id: bankId,
      amount: 1239533,
      report_type: 'CTR',
      received_date: '2019-04-11T00:00:13.330Z',
      id: reportId,
      gcv_version_num: 55,
      home: {
        backFiling: false,
        filingName: 'AC 0410 Dispensary 2',
        initialReport: true,
        correctOrAmendPriorReport: false,
      },
    };
  } else {
    return {
      dispensary_id: dispensaryId,
      exported: true,
      part2FormGroup: {
        unknownTin: false,
        legalNameOfFinancialInstitution: 'AC 0410 Bank',
        stateOfBranchOrOfficeWhereActivityOccurred: 'Connecticut',
        financialInstitutionIdentificationType: 'RSSD',
        addressOfBranchOrOfficeWhereActivityOccurred: '123 St',
        holdingCompany: false,
        financialInstitutionAddress: '123 St',
        unknownLegalNameOfFinancialInstitution: false,
        introducingBrokerCommodities: false,
        unknownFinancialInstitutionCity: false,
        financialInstitutionCity: 'Greenwich',
        futuresCommissionMerchant: false,
        tin: '123456y',
        cityOfBranchOrOfficeWhereActivityOccurred: 'Greenwich',
        securitiesAndFuturesInstitutionOrIndividualTypeOther: false,
        unknownFinancialInstitutionCountry: false,
        cardClub: false,
        investmentAdviser: false,
        tribalAuthorizedCasino: false,
        tinTypeSelect: 'EIN',
        subsidiaryofFinancialOrBankHoldingCompany: false,
        clearingBrokerSecurities: false,
        branchsRoleInTransactionPayingLocation: false,
        branchsRoleInTransactionBoth: false,
        stateLicensedCasino: false,
        financialInstitutionZipOrPostalCode: '06830',
        primaryFederalRegulatorSelect: '1',
        financialInstitutionCountry: 'unitedstates',
        investmentCompany: false,
        branchsRoleInTransactionSellingLocation: false,
        retailForeignExchangeDealer: false,
        unknownFinancialInstitutionZipOrPostalCode: false,
        noBranchActivityInvolved: false,
        both: false,
        zipOrPostalCodeOfBranchOrOfficeWhereActivityOccurred: '06830',
        typeOfGamingInstitutionOther: false,
        executionOnlyBrokerSecurities: false,
        introducingBrokerSecurities: false,
        financialInstitutionSelect: 'Depository Institution',
        countryOfBranchOrOfficeWhereActivityOccurred: 'unitedstates',
        payingLocation: false,
        sellingLocation: false,
        unknownFinancialInstitutionAddress: false,
        financialInstitutionState: 'Connecticut',
        selfClearingBrokerSecurities: false,
      },
      status: 'complete',
      homeGroup: {
        filingName: 'Initial SAR for notTest',
        continuingActivityReport: false,
        initialReport: true,
        correctOrAmendPriorReport: false,
        jointReport: false,
      },
      exportAudit: [
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:12:26.283Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:12:38.816Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:14:35.168Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:15:52.769Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:17:52.330Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:18:44.570Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:19:37.629Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:20:16.917Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:21:38.253Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:23:19.640Z',
        },
        {
          userId: '145195a9-0ef1-48e8-999e-4fb175dffc9a',
          timestamp: '2019-04-11T20:25:59.299Z',
        },
      ],
      due_date: '2019-05-11T20:11:19.890Z',
      part5FormGroup: {
        suspiciousActivityInformationNarrative: 'MARIJUANA_LIMITED',
      },
      green_check_transactions: [],
      part1FormGroup: {
        filerCity: 'Greenwich',
        filerState: 'Connecticut',
        financialInstitutionIdentificationType: 'RSSD',
        holdingCompany: false,
        introducingBrokerCommodities: false,
        sroFutures: false,
        futuresCommissionMerchant: false,
        tin: '123456y',
        securitiesAndFuturesInstitutionOrIndividualTypeOther: false,
        investmentAdviser: false,
        tinTypeSelect: 'EIN',
        subsidiaryofFinancialOrBankHoldingCompany: false,
        filerName: 'AC 0410 Bank',
        clearingBrokerSecurities: false,
        filerCountry: 'unitedstates',
        primaryFederalRegulatorSelect: '1',
        sroSecurities: false,
        investmentCompany: false,
        cpoOrCta: false,
        filerZipOrPostalCode: '06830',
        retailForeignExchangeDealer: false,
        executionOnlyBrokerSecurities: false,
        filingInstitutionContactPhoneNumber: '1234567',
        introducingBrokerSecurities: false,
        filerAddress: '123 St',
        financialInstitutionSelect: 'Depository Institution',
        filingInstitutionContactOffice: 'Hello',
        selfClearingBrokerSecurities: false,
      },
      part4FormGroup: {
        amountUnknown: false,
        amountInvolvedInThisReport: 0,
        altersOrCancelsTransactionToAvoidCtrRequirement: false,
        knownOrSuspectedTerroristOrTerroristOrganization: false,
        altersOrCancelsTransactionToAvoidBsaRecordkeepingRequirement: false,
        transactionsBelowBsaRecordkeepingThreshold: false,
        suspiciousActivityFromDate: '2019-04-11T20:11:19.890Z',
        terroristFinancingOther: false,
        suspiciousInquiryByCustomerRegardingBsaReportingOrRecordkeepingRequirements: false,
        structuringOther: false,
        noAmountInvolved: true,
        depositAccount: true,
        cumulativeAmount: 0,
        transactionsBelowCtrThreshold: false,
        usCurrency: true,
      },
      bank_id: bankId,
      part3FormGroup: {
        subjectInformationTin: '234234',
        subjectRelationshipAttorney: false,
        isSubjectInformationEntity: true,
        subjectInformationTinType: 'EIN',
        subjectRelationshipInstitutionTin: '123456y',
        subjectRelationshipOwnerOrControllingShareholder: false,
        subjectInformationCountry: 'unitedstates',
        subjectInformationFormOfIdentificationUnknown: false,
        isSubjectInformationCityUnknown: false,
        subjectInformationState: 'Connecticut',
        isSubjectRelationshipAccountClosed: false,
        subjectInformationFormOfIdentificationCountry: 'unitedstates',
        subjectRelationshipEmployee: false,
        affectedInstitutionAccountNumber: '345',
        isSubjectInformationStateUnknown: false,
        isSubjectInformationFirstNameUnknown: false,
        subjectInformationTinUnknown: false,
        isSubjectInformationAddressUnknown: false,
        isSubjectInformationCountryUnknown: false,
        subjectRelationshipNoRelationshipToInstitution: false,
        individualsLastNameOrEntitysLegalName: 'notTest',
        subjectRelationshipAccountant: false,
        subjectInformationFormOfIdentificationNumber: '234234',
        subjectInformationPhoneNumber: '123124124',
        isIndividualsLastNameOrEntitysLegalNameUnknown: false,
        subjectRelationshipAppraiser: false,
        subjectRelationshipAgent: false,
        subjectInformationCity: 'new milf',
        subjectRelationshipBorrower: false,
        subjectInformationPhoneNumberType: 'Work',
        subjectInformationAddress: '234',
        subjectRelationshipOfficer: false,
        relatedAffectedFinancialInstitutionTin: '123456y',
        isSubjectInformationZipOrPostalCodeUnknown: false,
        subjectInformationZipOrPostalCode: '05665',
        subjectRelationshipDirector: false,
        isSubjectInformationDateOfBirthUnknown: false,
        subjectInformationFormOfIdentificationIssuingState: 'Connecticut',
        isallCriticalSubjectInformationIsUnavailable: false,
        subjectInformationFormOfIdentificationType: 'Other',
        subjectRelationshipCustomer: true,
        subjectInformationFormOfIdentificationTypeOther: 'Business License',
      },
      amount: 0,
      report_type: 'SAR',
      received_date: '2019-04-11T20:11:19.890Z',
      id: reportId,
      gcv_version_num: 15,
    };
  }
}

export const mockNotifications: Notification[] = [
  {
    completed: true,
    user_id: '1234567',
    data: {
      end_date: '2019-04-03T20:54:53Z',
      dispensary_id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      total_amount_green_checked: 8009,
      total_amount_red_flagged: 0,
      total_dollars_sold: 8009,
      final_deposit: 8009,
      total_dollars_compliant: 8009,
      gcv_version_num: 1,
      deposit_date: '2019-04-04T21:03:12.339Z',
      delMethod: {
        name: 'Jon rinciari',
        active: true,
        id: '9af88a08-b059-4275-b550-604148eafdd5',
        type: 'user',
        favorite: false,
      },
      num_records_red_flagged: 0,
      num_records_green_checked: 3,
      total_amount_warning: 0,
      num_records_warning: 0,
      max_deposit: 8009,
      deposit_id: '5cdb8a2e-4f5e-4116-b019-5368ba51154d',
      start_date: '2019-04-03T20:45:37Z',
      status: 'pending',
    },
    created_date: '2019-04-04T21:03:14.731Z',
    email_data: {
      base_url: 'https://integration.greencheckverified.com',
      dispensary_name: 'aprild3',
    },
    id: '1d7b8855-51da-458f-983c-961c6cf389a0',
    send_date: '2019-04-04T21:03:14.470Z',
    gcv_version_num: 1,
    email_address: 'jrinciari+april2@greencheckverified.com',
    type: 'new_deposit',
  },
  {
    completed: true,
    user_id: '1234567',
    data: {
      country: 'United States',
      cannabisLicenseNumber: '345',
      establishedDate: '2019-04-04T04:00:00.000Z',
      city: 'new milford',
      documents: [
        {
          name: 'cannabisLicenseCopy',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/cannabisLicenseCopy',
          status: 'review',
        },
        {
          name: 'certificateOfGoodStanding',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/certificateOfGoodStanding',
          status: 'review',
        },
        {
          name: 'taxDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/taxDoc',
          status: 'review',
        },
        {
          name: 'balanceDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/balanceDoc',
          status: 'review',
        },
        {
          name: 'incomeDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/incomeDoc',
          status: 'review',
        },
        {
          name: 'policyDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/policyDoc',
          status: 'review',
        },
        {
          name: 'leaseDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/leaseDoc',
          status: 'review',
        },
        {
          name: 'beneficialOwnerDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/beneficialOwnerDoc',
          status: 'review',
        },
        {
          name: 'articlesDoc',
          filename: 'Inspection.pdf',
          s3Key: '40df1343-daa4-4817-ba0c-0c4a04a491b0/documents/articlesDoc',
          status: 'review',
        },
      ],
      ftEmployees: '34',
      postalCode: '06776',
      dueDiligence: {
        gcvReview: [
          {
            timestamp: '2019-04-04T14:29:28.685Z',
            status: 'reviewed',
          },
        ],
      },
      ein: '345',
      monthlySales: '345.00',
      gcv_version_num: 10,
      methodOfTransportation: {
        user: [
          {
            name: 'Jon Rinciari',
            active: false,
            id: '477b6199-4a30-4ba7-8ea3-bf1dde6d9c90',
            favorite: false,
          },
        ],
        vendor: [],
        bank: [],
      },
      monthlyCustomers: '345',
      orgType: 'dispensary',
      onBoardingComplete: true,
      state: 'Connecticut',
      id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      ptEmployees: '34',
      posName: '345',
      entityType: 'sole',
      businessHours: {
        sunday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: false,
        },
        saturday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: false,
        },
        tuesday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: true,
        },
        wednesday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: true,
        },
        thursday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: true,
        },
        friday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: true,
        },
        monday: {
          openHour: '09:00',
          closeHour: '17:00',
          isOpen: true,
        },
      },
      groups: [
        {
          name: 'Admin Users',
          id: 'dcc58e5f-c00f-4691-bc80-ce5ccff9b51e',
          type: 'administration',
          users: ['477b6199-4a30-4ba7-8ea3-bf1dde6d9c90'],
          roles: ['dispensary_admin'],
        },
        {
          name: 'Account Owner',
          id: '23c9be64-b031-40fd-a95c-d3b6647ea06f',
          type: 'account_owner',
          users: ['477b6199-4a30-4ba7-8ea3-bf1dde6d9c90'],
          roles: ['dispensary_account_owner'],
        },
        {
          name: 'Standard Users',
          id: '3b9dcea1-2985-4695-a65b-662186a9b9b7',
          type: 'standard_users',
          users: [],
          roles: ['dispensary_user'],
        },
        {
          name: 'Deposit Transportation',
          id: 'c56fd128-a1f2-4c54-aa8d-73a163581ef3',
          type: 'deposit_transport',
          users: [],
          roles: ['dispensary_deposit_transport'],
        },
      ],
      cannabisLicenseType: 'Medical',
      cannabisLicenseExpirationDate: '2019-04-04T04:00:00.000Z',
      phone: '2037330596',
      streetAddress: '10 sundance',
      name: 'aprild3',
      initialPercentageCashOnHand: '345',
      dueDiligenceStatus: 'submitted',
    },
    created_date: '2019-04-04T14:29:30.665Z',
    email_data: {
      base_url: 'https://integration.greencheckverified.com',
      dispensary_name: 'aprild3',
    },
    id: '546039ca-fd99-4bd3-be15-561d6914af01',
    send_date: '2019-04-04T14:29:30.376Z',
    gcv_version_num: 1,
    email_address: 'jrinciari+april2@greencheckverified.com',
    type: 'bank_dispensary_review',
  },
  {
    completed: false,
    user_id: '1234567',
    data: {
      end_date: '2019-04-03T20:42:48Z',
      dispensary_id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      total_amount_green_checked: 353416,
      total_amount_red_flagged: 1674,
      total_dollars_sold: 374407,
      final_deposit: 372733,
      total_dollars_compliant: 372733,
      gcv_version_num: 1,
      deposit_date: '2019-04-04T14:58:20.815Z',
      delMethod: {
        name: 'Johnny rinciari',
        active: true,
        id: '9af88a08-b059-4275-b550-604148eafdd5',
        type: 'user',
        favorite: false,
      },
      num_records_red_flagged: 1,
      num_records_green_checked: 94,
      total_amount_warning: 19317,
      num_records_warning: 5,
      max_deposit: 372733,
      deposit_id: '88c434a1-9517-4e20-8902-007cf1d9185a',
      start_date: '2019-04-03T14:03:19Z',
      status: 'pending',
    },
    created_date: '2019-04-04T14:58:23.182Z',
    email_data: {
      base_url: 'https://integration.greencheckverified.com',
      dispensary_name: 'aprild3',
    },
    id: '6e76d7c2-6808-4eab-96c5-59c26fbbc359',
    send_date: '2019-04-04T14:58:22.940Z',
    gcv_version_num: 1,
    email_address: 'jrinciari+april2@greencheckverified.com',
    type: 'new_deposit',
  },
  {
    completed: false,
    user_id: '1234567',
    data: {
      end_date: '2019-04-07T20:59:52Z',
      dispensary_id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      total_amount_green_checked: 3587186,
      total_amount_red_flagged: 6973715,
      total_dollars_sold: 11003359,
      final_deposit: 4029644,
      total_dollars_compliant: 4029644,
      gcv_version_num: 1,
      deposit_date: '2019-04-08T01:27:17.784Z',
      delMethod: {
        name: 'Johnny',
        active: true,
        id: 'a82b3df3-889c-4fdb-a04f-b3d55cfce9e0',
        type: 'vendor',
        favorite: false,
      },
      num_records_red_flagged: 1891,
      num_records_green_checked: 989,
      total_amount_warning: 442458,
      num_records_warning: 119,
      max_deposit: 4029644,
      deposit_id: 'b5631422-33cb-46e6-8631-a51cb8b10dc4',
      start_date: '2019-04-04T08:00:00Z',
      status: 'pending',
    },
    created_date: '2019-04-08T01:27:20.250Z',
    email_data: {
      base_url: 'https://integration.greencheckverified.com',
      dispensary_name: 'aprild3',
    },
    id: 'bb209d6e-eaa1-4f5c-bfd1-5b7a0c065d7c',
    send_date: '2019-04-08T01:27:19.978Z',
    gcv_version_num: 1,
    email_address: 'jrinciari+april2@greencheckverified.com',
    type: 'new_deposit',
  },
  {
    completed: false,
    user_id: '1234567',
    data: {
      dispensary_id: '40df1343-daa4-4817-ba0c-0c4a04a491b0',
      amount: 0,
      received_date: '2019-04-04T14:30:35.741Z',
      bank_id: 'ddbb4e00-9620-468c-be24-a5c3f38343ba',
      due_date: '2019-05-04T14:30:35.763Z',
      part4FormGroup: {
        suspiciousActivityFromDate: '2019-04-04T14:30:35.765Z',
      },
      report_type: 'SAR',
      id: '7ca119de-2831-4519-b8a3-d7b8eecb5cc1',
      green_check_transactions: [],
      homeGroup: {
        initialReport: true,
      },
      status: 'open',
      gcv_version_num: 1,
    },
    created_date: '2019-04-04T14:30:39.713Z',
    email_data: {
      base_url: 'https://integration.greencheckverified.com',
      report_type: 'SAR',
      dispensary_name: 'aprild3',
    },
    id: 'e061b43d-3234-40f6-bf46-29410abd3167',
    send_date: '2019-04-04T14:30:39.462Z',
    gcv_version_num: 2,
    email_address: 'jrinciari+april2@greencheckverified.com',
    type: 'new_fincen',
  },
];

export function mockSale(dispensaryId, salesId) {
  return {
    dispensary_id: dispensaryId,
    employee_first: 'Jessie',
    customer_last: 'Johns',
    customer_first: 'Emie',
    customer_dob: '1954-10-19',
    employee_id: 'd944d398-ad6e-407b-998c-7689a0225817',
    receipt_date: '2019-04-08T08:00:00Z',
    status: 'red_flag',
    compliance_exceptions: ['Purchased outside hours of operation'],
    tax: 122,
    customer_id: 'OTQzYjhhNmQtODI2My00NDBiLTg5MTEtMmJiNTM3NjU0YzU2LmRyaXZlcnM=',
    customer_address: '0685 Dare Path',
    products_sold: [
      {
        product_type: 'flower',
        product_id: 'Harlequin',
        percent_thc: 25,
        milligrams_thc: 850,
        price_per_unit: 1525,
        grams: 6,
        expiration_date: '2020-06-17',
        num_units: 1,
      },
    ],
    payment_type: 'cash',
    medical_or_adult: 'medical',
    compliance_warnings: [],
    id_number: '943b8a6d-8263-440b-8911-2bb537654c56',
    // tslint:disable-next-line:max-line-length
    s3_date_uploaded:
      '2019-04-10T18:21:14.457Z.public/organizations/dispensaries/6607fa9c-b988-4d2b-bbb4-a81b68e651cc/us-east-1:d8a23881-b7a9-4b63-a671-4b18b8989c27/sale/MOCK-SALES-2019-04-08-2019-04-08-7ad97f5c.json-6b81cf10-f279-4b59-b3d0-a87d18e63d3e',
    id_type: 'drivers',
    customer_showed_id: true,
    price: 1525,
    id: salesId,
    total_paid: 1647,
  };
}

export function mockVendor(vendorId) {
  return {
    city: 'New Haven',
    country: 'unitedstates',
    id: `${vendorId}`,
    name: 'HunnyBunny Money Delivery',
    phone: '2034356578',
    state: 'Connecticut',
    street: '589 Cash St',
    tin: '908754654',
    zipCode: '06510',
    orgType: 'transport_vendor',
  };
}
