import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmDialog.component.html',
  styleUrls: ['./confirmDialog.component.scss'],
})
export class ConfirmDialogComponent {
  message = '';
  title = 'Confirm';
  firstButton = { text: 'Cancel', type: 'tertiary', display: true };
  secondButton = { text: 'Confirm', type: 'danger', display: true };

  constructor(private matDialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.title = data.title ? data.title : 'Confirm';
    if (data.firstButton) {
      this.firstButton = { ...this.firstButton, ...data.firstButton };
      this.secondButton = { ...this.secondButton, ...data.secondButton };
    }
  }

  approve() {
    this.close(true);
  }

  close(val: boolean = false) {
    this.matDialogRef.close({ confirmed: val });
  }
}
