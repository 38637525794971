import { mockDeposit } from './mock.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  deposits = [
    mockDeposit('123456'),
    mockDeposit('123456'),
    mockDeposit('123456'),
    mockDeposit('123456'),
    mockDeposit('123456'),
  ];

  newDeposit = mockDeposit('123456');

  getDepositsBy(dispId) {
    return this.deposits.filter(dep => dep.deposit_id === dispId);
  }

  getAllDeposits() {
    return this.deposits;
  }

  approveDeposit(depositId) {
    let index;
    this.deposits = this.deposits.map((dep, i) => {
      if (dep.deposit_id === depositId) {
        index = i;
        return { ...dep, status: 'approved' };
      } else {
        return dep;
      }
    });
    return this.deposits[index];
  }

  createDeposit() {
    const deposit = mockDeposit('123456');
    this.deposits.push(deposit);
    return deposit;
  }

  fetchNewDeposit() {
    return this.newDeposit;
  }
}
