import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Bank } from '@gcv/shared';
import { BankActionTypes } from './bank.actions';

export interface BankState extends EntityState<Bank> {}

export const adapterBank: EntityAdapter<Bank> = createEntityAdapter<Bank>();

export const initialBankState = adapterBank.getInitialState();

export function bankReducer(state = initialBankState, action): BankState {
  switch (action.type) {
    case BankActionTypes.BankLoaded:
      return adapterBank.upsertOne(action.payload, state);
    case BankActionTypes.BankUpdated:
      return adapterBank.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case BankActionTypes.DispensaryAddedToBank:
      return adapterBank.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case BankActionTypes.BankCreated:
      return adapterBank.addOne(action.payload, state);
    case BankActionTypes.AllBanksLoaded:
      return adapterBank.addMany(action.payload, state);
    case BankActionTypes.GroupsUpdated:
      return adapterBank.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case BankActionTypes.ResetBank:
      return initialBankState;
    default:
      return state;
  }
}

export const {
  selectIds: selectBankIds,
  selectEntities: selectBankEntities,
  selectAll: selectAllBanks,
} = adapterBank.getSelectors();
