import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Deposit } from '@gcv/shared';
import { DepositActionTypes } from './deposits.actions';

export interface DepositState extends EntityState<Deposit> {
  newDeposit: Deposit | null;
}

export const adapterDeposit: EntityAdapter<Deposit> = createEntityAdapter<Deposit>({
  selectId: deposit => deposit.deposit_id,
});

export const initialDepositState = adapterDeposit.getInitialState({ newDeposit: null });

export function depositReducer(state = initialDepositState, action): DepositState {
  switch (action.type) {
    case DepositActionTypes.DepositsLoaded:
      return adapterDeposit.addMany(action.payload, state);
    case DepositActionTypes.NewDepositLoaded:
      return {
        ...state,
        newDeposit: action.payload,
      };
    case DepositActionTypes.DepositCreated:
      return adapterDeposit.upsertOne(action.payload, state);
    case DepositActionTypes.DepositAccepted:
      return adapterDeposit.updateOne({ id: action.payload.deposit_id, changes: action.payload }, state);
    case DepositActionTypes.ResetDeposits:
      return initialDepositState;
    default:
      return state;
  }
}

export const getNewDeposit = (state: DepositState) => state.newDeposit;

export const {
  selectIds: selectDepositIds,
  selectEntities: selectDepositEntities,
  selectAll: selectAllDeposits,
} = adapterDeposit.getSelectors();
