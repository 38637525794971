import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gcv-navigation-shell',
  templateUrl: './navigation-shell.component.html',
  styleUrls: ['./navigation-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationShellComponent implements OnInit, OnChanges {
  @Input() username: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() notificationsComplete: any[];
  @Input() notificationsIncomplete: any[];
  @ViewChild('sidenav') sideNav;
  @Output() profileClicked = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() notificationClicked = new EventEmitter();
  @Output() notificationHandled = new EventEmitter();
  @Output() navigateToDashboard = new EventEmitter();
  public accountOpen = false;
  public opened = false;
  public tabIndex = 0;
  public completeNotifications = [];
  public incompleteNotifications = [];

  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnChanges() {}

  handleProfileClick() {
    this.profileClicked.emit();
  }

  handleNotificationIconClick() {
    this.sideNav.toggle();
    this.notificationClicked.emit();
  }

  handleNotificationClicked(e) {
    this.sideNav.toggle();
    this.notificationHandled.emit(e);
  }

  goToDashboard() {
    const url = this.router.url;
    if (url.search('bank') > -1) {
      this.navigateToDashboard.emit({ view: '/secure/bank/dashboard/overview' });
    } else if (url.search('dispensary') > -1) {
      this.navigateToDashboard.emit({ view: '/secure/dispensary/dashboard/overview' });
    } else {
      this.navigateToDashboard.emit({ view: '/gcv/dashboard/' });
      this.router.navigate(['']);
    }
  }

  toggleProfileDrawer(val) {
    this.accountOpen = val;
  }

  handleLogout() {
    this.logout.emit();
  }

  handleAccountSettingsClicked() {
    this.profileClicked.emit();
  }

  handleDismissNotificationDrawer() {
    this.sideNav.toggle();
  }

  handleNotificationTabClicked(val) {
    this.tabIndex = val;
  }
}
