import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Sale } from '@gcv/shared';
import { SalesActionTypes } from './sales.actions';

export interface SalesState extends EntityState<Sale> {}

export const adapterSales: EntityAdapter<Sale> = createEntityAdapter<Sale>();

export const initialSalesState = adapterSales.getInitialState();

export function salesReducer(state = initialSalesState, action): SalesState {
  switch (action.type) {
    case SalesActionTypes.SalesLoadedByDepositId:
      return adapterSales.upsertMany(action.payload, state);
    case SalesActionTypes.PaginatedSalesLoadedByDateRange:
      return adapterSales.upsertMany(action.payload, state);
    case SalesActionTypes.AllUndepositedSalesLoaded:
      return adapterSales.upsertMany(action.payload, state);
    case SalesActionTypes.ResetSales:
      return initialSalesState;
    default:
      return state;
  }
}

export const {
  selectIds: selectSalesIds,
  selectEntities: selectSalesEntities,
  selectAll: selectAllSales,
} = adapterSales.getSelectors();
