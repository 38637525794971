// ------------------------------------------------------------------------
// ALL STATE EXPORTS
// ------------------------------------------------------------------------

import * as fromUser from './user/user.reducer';
import * as fromStaff from './staff/staff.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromPrimaryContact from './primaryContact/primaryContact.reducer';
import * as fromDeposits from './deposits/deposits.reducer';
import * as fromBank from './bank/bank.reducer';
import * as fromFincen from './fincen/fincen.reducer';
import * as fromDispensary from './dispensary/dispensary.reducer';
import * as fromSales from './sales/sales.reducer';
import * as fromSalesFile from './salesFile/salesFile.reducer';
import * as fromVendor from './vendors/vendors.reducer';
import * as fromAggregates from './aggregates/aggregates.reducer';
import * as fromError from './error/error.reducer';
import { ActionReducerMap } from '@ngrx/store';

// -------------------------------------------------------------------------
// APPLICATION STATE
// -------------------------------------------------------------------------

export interface AppState {
  user: fromUser.UserState;
  notifications: fromNotifications.NotificationState;
  staff: fromStaff.StaffState;
  primaryContact: fromPrimaryContact.PrimaryContactState;
  deposits: fromDeposits.DepositState;
  bank: fromBank.BankState;
  fincen: fromFincen.FincenState;
  dispensary: fromDispensary.DispensaryState;
  sales: fromSales.SalesState;
  salesfile: fromSalesFile.SalesFileState;
  vendors: fromVendor.VendorState;
  aggregates: fromAggregates.AggregatesState;
  error: fromError.ErrorState;
}

// -------------------------------------------------------------------------
// APPLICATION REDUCERS
// -------------------------------------------------------------------------

export const reducers: ActionReducerMap<AppState> = {
  user: fromUser.userReducer,
  notifications: fromNotifications.notificationReducer,
  staff: fromStaff.staffReducer,
  primaryContact: fromPrimaryContact.dispensaryPrimaryContactReducer,
  deposits: fromDeposits.depositReducer,
  bank: fromBank.bankReducer,
  fincen: fromFincen.fincenReducer,
  dispensary: fromDispensary.dispensaryReducer,
  sales: fromSales.salesReducer,
  salesfile: fromSalesFile.salesFileReducer,
  vendors: fromVendor.vendorReducer,
  aggregates: fromAggregates.aggregatesReducer,
  error: fromError.errorReducer,
};
