import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

@Component({
  selector: 'gcv-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  height: any;

  constructor() {
    this.height = window.outerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = window.outerHeight;
  }
}
