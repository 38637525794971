import { mockUser } from './mock.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  users = {};

  constructor() {
    const user = mockUser('123456');
    this.users[user.id] = user;
  }

  getUserById(id) {
    return this.users[id];
  }

  putUserById(data) {
    this.users[data.id] = data;
    return this.users[data.id];
  }

  createUser(data) {
    const user = mockUser(data.id);
    this.users[user.id] = user;
    return user;
  }

  getKba(userId) {
    if (this.users[userId]) {
      return ['1234', '12345'];
    } else {
      return [];
    }
  }

  manuallyVerifyUser(id: string) {
    this.users[id] = this.users[id].identityVerified = true;
    return this.users[id];
  }
}
