// tslint:disable
/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
// tslint:enable

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AuthState } from 'aws-amplify-angular/dist/src/providers/auth.state';
import { AmplifyService } from 'aws-amplify-angular';

import * as _ from 'underscore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxValidators } from 'ngx-form-validators';
import { caesarShift } from '../../app/auth/route-guards/cognito.service';

@Component({
  selector: 'app-amplify-auth-sign-in-core',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./amplify-auth-sign-in-core.scss'],
})
export class SignInComponent {
  // tslint:disable-next-line:variable-name
  _authState: AuthState;
  // tslint:disable-next-line:variable-name
  _show: boolean;
  username: string;
  password: string;
  @Output() signingIn = new EventEmitter();
  userData = new FormGroup({
    username: new FormControl('', [Validators.required, NgxValidators.email]),
    password: new FormControl('', [Validators.required]),
  });
  errorMessage: string;
  amplifyService: AmplifyService;
  @Output() shouldPassChange = new EventEmitter();

  constructor(amplifyService: AmplifyService) {
    this.amplifyService = amplifyService;
    this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then(user => {
        if (user) {
          this.amplifyService.setAuthState({ state: 'signedIn', user });
        }
      })
      .catch(err => {});
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    const { username } = this.userData.getRawValue();
    if (authState.state === 'signedIn') {
      this.userData.controls.username.setValue(
        authState.user ? authState.user.signInUserSession.idToken.payload.email : ''
      );
    } else {
      this.userData.controls.username.setValue(authState.user ? authState.user.email : '');
    }
  }

  setUsername(username: string) {
    this.userData.controls.username.setValue(username ? username || '' : '');
  }

  setPassword(password: string) {
    this.password = password;
  }

  onSignIn() {
    this.signingIn.emit(true);
    const { username, password } = this.userData.getRawValue();
    this.amplifyService
      .auth()
      .signIn(username.toLowerCase(), password)
      .then(user => {
        this.shouldPassChange.emit(password === this.caesarSetUp());
        if (user['challengeName'] === 'SMS_MFA' || user['challengeName'] === 'SOFTWARE_TOKEN_MFA') {
          this.amplifyService.setAuthState({ state: 'confirmSignIn', user });
        } else if (user['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
          this.amplifyService.setAuthState({ state: 'requireNewPassword', user });
        } else if (this.password === this.caesarSetUp()) {
          this.amplifyService.setAuthState({ state: 'requireNewPassword', user });
        } else {
          this.amplifyService.setAuthState({ state: 'signedIn', user });
        }
      })
      .catch(err => {
        this.signingIn.emit(false);
        this._setError(err);
      });
  }

  caesarSetUp() {
    const { username } = this.userData.getRawValue();
    const extraChars = '@17';
    const tempPass = username.split('@')[0] + 'ajkcdYHNMJU';
    return caesarShift(tempPass, 3) + extraChars;
  }

  onAlertClose() {
    this._setError(null);
  }

  onForgotPassword() {
    const { username } = this.userData.getRawValue();
    const user = username ? { username } : null;
    this.amplifyService.setAuthState({ state: 'forgotPassword', user });
  }

  onSignUp() {
    const { username } = this.userData.getRawValue();
    const user = username ? { username } : null;
    this.amplifyService.setAuthState({ state: 'signUp', user });
  }

  _setError(err) {
    if (!err) {
      this.errorMessage = null;
      return;
    }

    if (err.code && err.code === 'UserNotConfirmedException') {
      this.errorMessage = 'User is not Confirmed. Check email for link';
    } else {
      this.errorMessage = err.message || err;
    }
  }

  checkForEnter(e) {
    if (e.keyCode === 13 && this.userData.valid) {
      this.onSignIn();
    }
  }
}
