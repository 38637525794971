import { DataPersistence } from '../../utils/data.persistance';
import { SalesState } from './sales.reducer';
import { Effect } from '@ngrx/effects';
import {
  AllUndepositedSalesLoaded,
  LoadAllUndepositedSales,
  LoadPaginatedSalesByDateRange,
  LoadSalesByDepositId,
  PaginatedSalesLoadedByDateRange,
  SalesActionTypes,
  SalesLoadedByDepositId,
} from './sales.actions';
import { Sale } from '@gcv/shared';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class SalesEffect {
  @Effect() loadSalesByDepositId$ = this.dataPersistance.fetch(SalesActionTypes.LoadSalesByDepositId, {
    run: (action: LoadSalesByDepositId, state: SalesState) => {
      return this.sdk
        .dispensariesIdDepositsDepositIdSalesGet(action.payload.depositId, action.payload.dispensaryId)
        .pipe(map((res: Sale[]) => new SalesLoadedByDepositId(res)));
    },
    onError: err => {
      return new AddError({ action: SalesActionTypes.LoadSalesByDepositId, message: err });
    },
  });

  @Effect() loadAllUndepositedSales$ = this.dataPersistance.fetch(SalesActionTypes.LoadAllUndepositedSales, {
    run: (action: LoadAllUndepositedSales, state: SalesState) => {
      return this.sdk
        .dispensariesIdSalesGet(action.payload.dispensaryId, 'true')
        .pipe(map((res: Sale[]) => new AllUndepositedSalesLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: SalesActionTypes.AllUndepositedSalesLoaded, message: err });
    },
  });

  @Effect() loadPaginatedSalesByDateRange$ = this.dataPersistance.fetch(
    SalesActionTypes.LoadPaginatedSalesByDateRange,
    {
      run: (action: LoadPaginatedSalesByDateRange, state: SalesState) => {
        return this.sdk
          .dispensariesIdSalesPaginatedGet(
            action.payload.endDate.fromISO(),
            action.payload.startDate.fromISO(),
            action.payload.dispensaryId,
            null,
            null,
            action.payload.limit
          )
          .pipe(map((res: { lastKey: string; items: Sale[] }) => new PaginatedSalesLoadedByDateRange(res.items)));
      },
      onError: err => {
        return new AddError({ action: SalesActionTypes.LoadPaginatedSalesByDateRange, message: err });
      },
    }
  );

  constructor(private dataPersistance: DataPersistence<SalesState>, private sdk: DefaultService) {}
}
