import { EntityAdapter, EntityState } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import { SalesFileActionTypes } from './salesFile.actions';

export interface SalesFileHash {
  data_hash: string;
  isDuplicate: boolean;
}

export interface SalesFileState extends EntityState<SalesFileHash> {}

export const adapterSalesFile: EntityAdapter<SalesFileHash> = createEntityAdapter<SalesFileHash>({
  selectId: salesFile => salesFile.data_hash,
});

export const initialSalesFileState = adapterSalesFile.getInitialState();

export function salesFileReducer(state = initialSalesFileState, action): SalesFileState {
  switch (action.type) {
    case SalesFileActionTypes.SalesFileHashChecked:
      return adapterSalesFile.upsertOne(action.payload, state);
    case SalesFileActionTypes.ResetSalesFile:
      return initialSalesFileState;
    default:
      return state;
  }
}

export const {
  selectIds: selectSalesFileIds,
  selectEntities: selectSalesFileEntities,
  selectAll: selectAllSalesFiles,
} = adapterSalesFile.getSelectors();
