import { NgModule } from '@angular/core';
import { GcvInlineSVGModule } from './inline-svg.module';
import { CognitoComponent } from '../app/auth/cognito/cognito.component';
import { SignInComponent } from './sign-in-component/sign-in.component.core';
import { ForgotPasswordComponent } from './forgot-password-component/forgot-password.component.core';
import { RequireNewPasswordComponent } from './require-new-password-component/require-new-password.component.core';
import { GcvMaterialCommonModule } from './material.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { RoleDirective } from '../directives/role.directive';
import { NewPasswordComponent } from '../app/auth/cognito/new-password/new.password.component';
import { ViewAccountMotComponent } from './view-account-mot/view-account-mot.component';
import { ViewDocumentButtonComponent } from './view-document-button/view-document-button.component';
import { MotWarningDialogComponent } from './mot-warning-dialog/mot-warning-dialog';
import { PipesModule } from '../pipes/pipes.module';
import { ReviewAccountDocumentComponent } from '../app/bank/accounts/review-account-document/review-account-document.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirmDialog.component';
import { EditUserComponent } from './edit-user/edit-user-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgIdleModule } from '@ng-idle/core';
import { MotDetailDialogComponent } from './mot-detail-dialog/mot-detail-dialog.component';
import { HelpTextDialogComponent } from './help-text-dialog/help-text-dialog.component';
import { GcvUiModule } from '@user-interface/gcv-ui';
import { DispensaryAdminVerificationContainerComponent } from '../app/dispensary/registration/dispensary-admin-verification/dispensary-admin-verification-container/dispensary-admin-verification-container.component';
import { DispensaryAdminVerificationPresenterComponent } from '../app/dispensary/registration/dispensary-admin-verification/dispensary-admin-verification-presenter/dispensary-admin-verification-presenter.component';

/**
 * Create a New Internal Module which imports ComponentLib and Other Non Lib Components
 */
@NgModule({
  declarations: [
    RequireNewPasswordComponent,
    ReviewAccountDocumentComponent,
    ForgotPasswordComponent,
    MotDetailDialogComponent,
    CognitoComponent,
    SignInComponent,
    NotFoundComponent,
    ViewAccountMotComponent,
    NewPasswordComponent,
    ViewDocumentButtonComponent,
    RoleDirective,
    EditUserComponent,
    MotWarningDialogComponent,
    ConfirmDialogComponent,
    DispensaryAdminVerificationPresenterComponent,
    DispensaryAdminVerificationContainerComponent,
    HelpTextDialogComponent,
  ],
  imports: [
    GcvUiModule,
    PipesModule,
    GcvInlineSVGModule,
    NgxPaginationModule,
    NgIdleModule.forRoot(),
    GcvMaterialCommonModule,
  ],
  exports: [
    ReviewAccountDocumentComponent,
    CognitoComponent,
    MotDetailDialogComponent,
    RequireNewPasswordComponent,
    SignInComponent,
    NgxPaginationModule,
    EditUserComponent,
    MotWarningDialogComponent,
    ForgotPasswordComponent,
    GcvUiModule,
    PipesModule,
    GcvInlineSVGModule,
    NotFoundComponent,
    ViewAccountMotComponent,
    GcvMaterialCommonModule,
    ViewDocumentButtonComponent,
    RoleDirective,
    NewPasswordComponent,
    ConfirmDialogComponent,
    DispensaryAdminVerificationContainerComponent,
    HelpTextDialogComponent,
    // DispensaryAdminVerificationContainerComponent,
  ],

  entryComponents: [
    MotDetailDialogComponent,
    EditUserComponent,
    MotWarningDialogComponent,
    ViewAccountMotComponent,
    ReviewAccountDocumentComponent,
    ConfirmDialogComponent,
    DispensaryAdminVerificationContainerComponent,
    HelpTextDialogComponent,
  ],
  // entryComponents: [, ReviewAccountDocumentComponent],
})
export class GcvCommonModule {}
