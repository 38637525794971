export interface SalesAggregate {
  [key: string]: {
    bankable: number;
    unbankable: number;
    bankableRecords: number;
    unbankableRecords: number;
  };
}

export interface DispensaryAggregate {
  [key: string]: {
    totalSales: number;
    verifiedSales: number;
    verifiedSalesPercent: number;
    unverifiedSales: number;
    unverifiedSalesPercent: number;
    salePercentIncrease: number;
    totalPurchases: number;
    verifiedPurchases: number;
    verifiedPurchasePercent: number;
    unverifiedPurchases: number;
    unverifiedPurchasesPercent: number;
    acceptedDeposits: number;
    pendingDeposits: number;
    maxDeposit: number;
  };
}

export interface BankAggregate {
  [key: string]: {
    totalSales: number;
    verifiedSales: number;
    verifiedSalesPercent: number;
    unverifiedSales: number;
    unverifiedSalesPercent: number;
    salePercentIncrease: number;
    totalPurchases: number;
    verifiedPurchases: number;
    verifiedPurchasePercent: number;
    unverifiedPurchases: number;
    unverifiedPurchasesPercent: number;
  };
}

export interface FincenDepositAggregate {
  [key: string]: {
    unverifiedSalesPercent: number;
    verifiedPurchases: number;
    unverifiedSales: number;
    verifiedSalesPercent: number;
    totalPurchases: number;
    verifiedPurchasesPercent: number;
    totalDeposits: number;
    unverifiedPurchasesPercent: number;
    unverifiedPurchases: number;
    totalSales: number;
    totalSalesDeposited: number;
    verifiedSales: number;
  };
}

export interface FincenReportAggregate {
  status: string;
  name: string;
  dispensaryId: string;
  sarPeriod: number;
  sarPeriodStart: string;
  sarPeriodEnd: string;
  totalDeposits: number;
  depositCount: number;
  openReports: number;
  ctrCount: number;
  depositIds: string[];
}

export interface AggregateResponsePayload {
  company_id: string;
  data: DispensaryAggregate | BankAggregate | FincenDepositAggregate | SalesAggregate | FincenReportAggregate[];
  last_updateD: string;
  type: AggregateTypes;
}

export interface AggregateRequestPayload {
  companyId: string;
  type: AggregateTypes;
}

export enum AggregateTypes {
  bank = 'bank',
  fincen = 'fincen',
  sales = 'sales',
  dispensary = 'dispensary',
  fincenDeposits = 'fincenDeposits',
}
