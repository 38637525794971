import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPercentCustom' })
export class FormatPercentCustom implements PipeTransform {
  constructor() {}

  transform(value: string) {
    return value + '%';
  }

  parse(value: string) {
    return value.replace(/%/g, '');
  }
}
