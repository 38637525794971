import * as fromPrimaryContact from './primaryContact.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectPrimaryContactState = createFeatureSelector<fromPrimaryContact.PrimaryContactState>(
  'primaryContact'
);

export const selectPrimaryContactIds = createSelector(
  selectPrimaryContactState,
  fromPrimaryContact.selectPrimaryContactIds
);

export const selectPrimaryContactEntities = createSelector(
  selectPrimaryContactState,
  fromPrimaryContact.selectPrimaryContactEntities
);

export const selectPrimaryContactAll = createSelector(
  selectPrimaryContactState,
  fromPrimaryContact.selectPrimaryContactAll
);

export const selectPrimaryContactById = createSelector(
  selectPrimaryContactEntities,
  (primaryContactDicionary, props) => primaryContactDicionary[props.primaryContactId]
);
