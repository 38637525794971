import { Component, Input } from '@angular/core';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { MatDialog } from '@angular/material';
import { ReviewAccountDocumentComponent } from '../../app/bank/accounts/review-account-document/review-account-document.component';

@Component({
  selector: 'app-view-document-button',
  templateUrl: './view-document-button.component.html',
  styleUrls: ['./view-document-button.component.scss'],
})
export class ViewDocumentButtonComponent {
  @Input() documentName;
  @Input() docDescription: string;
  @Input() docKey;
  @Input() s3Key;
  @Input() dispensaryId: any;
  @Input() cognitoId: any;
  @Input() type = 'pdf';
  @Input() isGcv = false;

  constructor(private s3FileManagement: S3FileManagementService, private dialog: MatDialog) {}

  async view() {
    const data = {};
    let s3Key = '';
    if (this.isGcv) {
      s3Key = `organizations/dispensaries/${this.dispensaryId}/documents/master/${this.docKey.key}`;
    } else {
      s3Key = `organizations/dispensaries/${this.dispensaryId}/documents/${this.cognitoId}/${this.docKey.key}`;
    }
    this.s3FileManagement.get(s3Key).then(url => {
      data['s3KeyUrl'] = url;
      data['documentName'] = this.documentName;
      data['status'] = 'reviewed';
      data['type'] = this.type === 'pdf' ? 'application/pdf' : 'image/jpg';
      const matDialog = this.dialog.open(ReviewAccountDocumentComponent, { data, maxWidth: '1280px', width: '1280px' });
      matDialog.afterClosed().subscribe(() => {});
    });
  }
}
