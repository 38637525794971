import { Action } from '@ngrx/store';
import { Deposit } from '@gcv/shared';

export enum DepositActionTypes {
  LoadDeposits = '[Deposits] Load Deposits',
  DepositsLoaded = '[Deposits] Deposits Loaded',

  CreateDeposit = '[Deposits] Create Deposit',
  DepositCreated = '[Deposits] Deposit Created',

  AcceptDeposit = '[Deposits] Accept Deposit',
  DepositAccepted = '[Deposits] Deposit Accepted',

  LoadNewDeposit = '[Deposits] Load New Deposit',
  NewDepositLoaded = '[Deposits] New Deposit Loaded',

  ResetDeposits = '[Deposits] Reset Deposits',
}

export class LoadDeposits implements Action {
  readonly type = DepositActionTypes.LoadDeposits;

  constructor(public payload: { dispensaryId: string }) {}
}

export class DepositsLoaded implements Action {
  readonly type = DepositActionTypes.DepositsLoaded;

  constructor(public payload: Deposit[]) {}
}

export class CreateDeposit implements Action {
  readonly type = DepositActionTypes.CreateDeposit;

  constructor(public payload: { deposit: Partial<Deposit>; dispensaryId: string }) {}
}

export class DepositCreated implements Action {
  readonly type = DepositActionTypes.DepositCreated;

  constructor(public payload: Deposit) {}
}

export class AcceptDeposit implements Action {
  readonly type = DepositActionTypes.AcceptDeposit;

  constructor(public payload: { deposit: Deposit; bankId: string }) {}
}

export class DepositAccepted implements Action {
  readonly type = DepositActionTypes.DepositAccepted;

  constructor(public payload: Deposit) {}
}

export class LoadNewDeposit implements Action {
  readonly type = DepositActionTypes.LoadNewDeposit;

  constructor(public payload: { dispensaryId: string }) {}
}

export class NewDepositLoaded implements Action {
  readonly type = DepositActionTypes.NewDepositLoaded;

  constructor(public payload: Deposit) {}
}

export class ResetDeposits implements Action {
  readonly type = DepositActionTypes.ResetDeposits;
}

export type DepositActions =
  | LoadDeposits
  | DepositsLoaded
  | CreateDeposit
  | DepositCreated
  | AcceptDeposit
  | DepositAccepted
  | LoadNewDeposit
  | NewDepositLoaded;
