import { Action } from '@ngrx/store';
import { TransportVendor } from '@gcv/shared';

export enum VendorsActionTypes {
  LoadVendorById = '[Vendors] Load Vendor By Id',
  VendorLoadedById = '[Vendors] Vendor Loaded By Id',

  CreateDispensaryVendor = '[Vendors] Create Dispensary Vendor',

  UpdateDispensaryVendor = '[Vendors] Update Dispensary Vendor',

  UpdateBankVendor = '[Vendors] Update Bank Vendor',

  CreateBankVendor = '[Vendors] Create Bank Vendor',

  DeleteBankVendor = '[Vendors] Delete Bank Vendor',

  FavoriteDispensaryVendorByTypeAndId = '[Vendors] Favorite Dispensary Vendor By Type and Id',

  AssignVendorToSingleDispensary = '[Vendors] Assign Vendor to Single Dispensary',

  UnassignVendorToSingleDispensary = '[Vendors] Unassign Vendor to Single Dispensary',

  AssignVendorToAllDispensaries = '[Vendors] Assign Vendor to All Dispensaries',

  ResetVendors = '[Vendors] Reset All',
}

export class LoadVendorById implements Action {
  readonly type = VendorsActionTypes.LoadVendorById;

  constructor(public payload: { vendorId: string }) {}
}

export class VendorLoadedById implements Action {
  readonly type = VendorsActionTypes.VendorLoadedById;

  constructor(public payload: TransportVendor) {}
}

export class CreateDispensaryVendor implements Action {
  readonly type = VendorsActionTypes.CreateDispensaryVendor;

  constructor(public payload: { dispensaryId: string; vendor: Partial<TransportVendor> }) {}
}

export class UpdateDispensaryVendor implements Action {
  readonly type = VendorsActionTypes.UpdateDispensaryVendor;

  constructor(
    public payload: { vendorId: string; vendorType: 'user' | 'vendor'; dispensaryId: string; vendor: TransportVendor }
  ) {}
}

export class UpdateBankVendor implements Action {
  readonly type = VendorsActionTypes.UpdateBankVendor;

  constructor(public payload: { vendorId: string; bankId: string; vendor: TransportVendor }) {}
}

export class CreateBankVendor implements Action {
  readonly type = VendorsActionTypes.CreateBankVendor;

  constructor(public payload: { bankId: string; vendor: Partial<TransportVendor>; dispensaryId?: string }) {}
}

export class DeleteBankVendor implements Action {
  readonly type = VendorsActionTypes.DeleteBankVendor;

  constructor(public payload: { bankId: string; vendorId: string }) {}
}

export class FavoriteDispensaryVendorByTypeAndId implements Action {
  readonly type = VendorsActionTypes.FavoriteDispensaryVendorByTypeAndId;

  constructor(public payload: { dispensaryId: string; vendorId: string; type: string }) {}
}

export class AssignVendorToSingleDispensary implements Action {
  readonly type = VendorsActionTypes.AssignVendorToSingleDispensary;

  constructor(public payload: { bankId: string; vendorId: string; dispensaryId: string }) {}
}

export class UnassignVendorToSingleDispensary implements Action {
  readonly type = VendorsActionTypes.UnassignVendorToSingleDispensary;

  constructor(public payload: { bankId: string; vendorId: string; dispensaryId: string }) {}
}

export class AssignVendorToAllDispensaries implements Action {
  readonly type = VendorsActionTypes.AssignVendorToAllDispensaries;

  constructor(public payload: { bankId: string; vendorId: string }) {}
}

export class ResetVendors implements Action {
  readonly type = VendorsActionTypes.ResetVendors;
}

export type VendorActions =
  | ResetVendors
  | AssignVendorToAllDispensaries
  | AssignVendorToSingleDispensary
  | UnassignVendorToSingleDispensary
  | FavoriteDispensaryVendorByTypeAndId
  | DeleteBankVendor
  | CreateBankVendor
  | UpdateBankVendor
  | UpdateDispensaryVendor
  | CreateDispensaryVendor
  | VendorLoadedById
  | LoadVendorById;
