import * as fromUser from './user.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectUserState = createFeatureSelector<fromUser.UserState>('user');

export const selectUserIds = createSelector(
  selectUserState,
  fromUser.selectUserIds
);

export const selectUserEntities = createSelector(
  selectUserState,
  fromUser.selectUserEntities
);

export const selectUsersAll = createSelector(
  selectUserState,
  fromUser.selectAllUsers
);

export const selectKbaQuestions = createSelector(
  selectUserState,
  state => state.kbaQuestions
);
