import { DataPersistence } from '../../utils/data.persistance';
import { SalesFileHash, SalesFileState } from './salesFile.reducer';
import { Effect } from '@ngrx/effects';
import { CheckSalesFileHash, SalesFileActionTypes, SalesFileHashChecked } from './salesFile.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MockDefaultService } from '../../mock-sdk/mock.default.service';
import { AddError } from '../error';

@Injectable({
  providedIn: 'root',
})
export class SalesFileEffect {
  @Effect() checkSalesFileHash$ = this.dataPersistance.fetch(SalesFileActionTypes.CheckSalesFileHash, {
    run: (action: CheckSalesFileHash, state: SalesFileState) => {
      return this.mockDefaultService.salesFileHashHashGet(action.payload.hash).pipe(
        map(
          (res: SalesFileHash) =>
            new SalesFileHashChecked({
              data_hash: action.payload.hash,
              isDuplicate: res.data_hash ? true : false,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: SalesFileActionTypes.CheckSalesFileHash, message: err });
    },
  });

  constructor(
    private dataPersistance: DataPersistence<SalesFileState>,
    private mockDefaultService: MockDefaultService
  ) {}
}
