import * as fromBank from './bank.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectBankState = createFeatureSelector<fromBank.BankState>('bank');

export const selectBankIds = createSelector(
  selectBankState,
  fromBank.selectBankIds
);

export const selectBankEntities = createSelector(
  selectBankState,
  fromBank.selectBankEntities
);

export const selectAllBank = createSelector(
  selectBankState,
  fromBank.selectAllBanks
);

export const selectBankById = createSelector(
  selectBankEntities,
  (state, props) => {
    return state[props.bankId];
  }
);
