import { Observable } from 'rxjs';
import { SalesFileHash, SalesFileState } from './salesFile.reducer';
import { Dictionary } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { selectSalesFileEntities } from './salesFile.selectors';
import { CheckSalesFileHash, ResetSalesFile } from './salesFile.actions';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SalesFileFacade {
  salesFile$: Observable<Dictionary<SalesFileHash>>;

  constructor(private store: Store<SalesFileState>) {
    this.salesFile$ = store.pipe(select(selectSalesFileEntities));
  }

  checkSalesFileHash(hash: string) {
    this.store.dispatch(new CheckSalesFileHash({ hash }));
    return this.salesFile$.pipe(map(salesFile => salesFile[hash]));
  }

  selectSalesFileHash(hash: string) {
    return this.salesFile$.pipe(map(salesFileDictionary => salesFileDictionary[hash]));
  }

  resetSalesFile() {
    this.store.dispatch(new ResetSalesFile());
  }
}
