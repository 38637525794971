import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@gcv/shared';
import { ConfirmDialogComponent } from '../confirm-dialog/confirmDialog.component';

@Component({
  selector: 'gcv-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUserComponent implements OnInit {
  message = '';
  userDetails: FormGroup;
  user: User;
  roles = [{ viewValue: 'Admin', value: 'bank_admin' }, { viewValue: 'Standard', value: 'bank_user' }];

  constructor(
    private matDialogRef: MatDialogRef<EditUserComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.user) {
      this.user = data.user;
    } else {
      this.close({ isSaving: false });
    }
  }

  ngOnInit() {
    this.userDetails = new FormGroup({
      firstName: new FormControl(this.user.firstName ? this.user.firstName : '', Validators.required),
      lastName: new FormControl(this.user.lastName ? this.user.lastName : '', Validators.required),
      email: new FormControl(this.user.email ? this.user.email : '', Validators.required),
      role: new FormControl(this.user.role ? this.user.role : '', Validators.required),
    });
  }

  saveUser() {
    const updatedUser = { ...this.user, ...this.userDetails.getRawValue() };
    this.close({ isSaving: true, user: updatedUser });
    // this.userApp
    //   .updateStaff({ id: updatedUser.id }, updatedUser)
    //   .then(() => {
    //     this.close();
    //     this.snackBar.open('User has been updated', '', {
    //       duration: 3000,
    //     });
    //   })
    //   .catch(() => {
    //     this.close();
    //     this.snackBar.open('An error occurred updating user.', '', {
    //       duration: 3000,
    //     });
    //   });
  }

  close(data) {
    this.matDialogRef.close({ data });
  }

  archiveUser() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you wish to archive this user?' },
    });

    dialog.afterClosed().subscribe(data => {
      if (data.confirmed) {
        this.close({
          isSaving: true,
          user: {
            ...this.user,
            invitation_status: 'archived',
            archive_date: new Date().toISOString(),
          },
        });
      } else {
        this.close({ isSaving: false });
      }
    });
  }
}
