import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gcv-dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html',
  styleUrls: ['./dashboard-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWrapperComponent {
  @Input()
  data: any;
  @Input()
  styles: any;

  constructor() {}
}
