import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { Dispensary, DocumentChecker } from '@gcv/shared';
import { map } from 'rxjs/operators';
import { AggregatesFacade, DispensaryAggregate, DispensaryFacade } from '@user-interface/gcv-state';

@Component({
  selector: 'app-active-container',
  templateUrl: './active-container.component.html',
  styleUrls: ['./active-container.component.scss'],
})
export class ActiveContainerComponent implements OnInit {
  public dispensaries$: Observable<Dispensary[]>;
  public dispAggregate$: Observable<{ [k: string]: DispensaryAggregate }>;

  static dispensaryStatus(dispensary): boolean {
    return (
      DocumentChecker.isGcvReviewed(dispensary) &&
      DocumentChecker.areDocumentsUploaded(dispensary) &&
      DocumentChecker.isBankReviewed(dispensary, dispensary.documentContext ? dispensary.documentContext : 'default')
    );
  }

  constructor(private router: Router, private dispFacade: DispensaryFacade, private aggFacade: AggregatesFacade) {}

  ngOnInit() {
    this.setupStream();
  }

  setupStream() {
    this.dispensaries$ = this.dispFacade
      .selectAllDispensaries()
      .pipe(
        map((dispensaries: Dispensary[]) =>
          dispensaries.filter((dispensary: Dispensary) => ActiveContainerComponent.dispensaryStatus(dispensary))
        )
      );
    this.dispAggregate$ = this.aggFacade.selectAllDispensaryAggregates();
  }

  handleButtonClicked(dispensary) {
    const data: NavigationExtras = {
      queryParams: {
        account: dispensary.id,
      },
    };
    this.router.navigate(['/secure/bank/account/view'], data).then(() => {});
  }
}
