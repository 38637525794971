import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-user-group-card',
  templateUrl: 'user-group-card.component.html',
  styleUrls: ['./user-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupCardComponent implements OnInit {
  @Input() group: any;
  @Input() userMap: any;
  @Output() viewGroup = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleViewGroup() {
    this.viewGroup.emit(this.group);
  }
}
