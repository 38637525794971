import { select, Store } from '@ngrx/store';
import { FincenState } from './fincen.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';
import {
  selectFincenAll,
  selectFincenByDispensaryId,
  selectFincenByTypeAndStatus,
  selectFincenEntities,
  selectFincenReportById,
} from './fincen.selectors';
import { CreateFincen, ExportReportAsXml, LoadFincen, ResetFincen, UpdateFincen } from './fincen.actions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FincenFacade {
  fincen$: Observable<Dictionary<FincenCtrReport | FincenSarReport>>;

  constructor(private store: Store<FincenState>) {
    this.fincen$ = store.pipe(select(selectFincenEntities));
  }

  getFincenByBankAndDispensaryId(bankId: string, dispensaryId: string) {
    this.store.dispatch(new LoadFincen({ bankId, dispensaryId }));
  }

  putFincenReport(report: FincenCtrReport | FincenSarReport) {
    this.store.dispatch(new UpdateFincen(report));
  }

  postFincenReport(report: FincenCtrReport | FincenSarReport) {
    this.store.dispatch(new CreateFincen(report));
  }

  exportReportsAsXml(reports: (FincenCtrReport | FincenSarReport)[], bankName: string) {
    this.store.dispatch(new ExportReportAsXml({ reports, bankName }));
  }

  resetFincen() {
    this.store.dispatch(new ResetFincen());
  }

  selectFincenReportById(fincenId: string) {
    return this.store.pipe(select(selectFincenReportById, { fincenId }));
  }

  selectFincenByDispensaryId(dispensaryId: string) {
    return this.store.pipe(select(selectFincenByDispensaryId, { dispensaryId }));
  }

  selectFincenByTypeAndStatus(type: 'CTR' | 'SAR', status: 'open' | 'in progress' | 'complete') {
    return this.store.pipe(select(selectFincenByTypeAndStatus, { status, type }));
  }

  selectAllFincen() {
    return this.store.pipe(select(selectFincenAll));
  }
}
