import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { HttpClient } from '@angular/common/http';
import { RoleResolverService } from '../../../../services/role-resolver.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { documentPropertyToHumanReadableNameMap } from '@gcv/shared';

@Component({
  selector: 'app-review-account-document',
  templateUrl: './review-account-document.component.html',
  styleUrls: ['./review-account-document.component.scss'],
})
export class ReviewAccountDocumentComponent implements OnInit {
  @ViewChild('pdfView')
  pdfView: ElementRef;
  selectedTab = 0;
  docMap = documentPropertyToHumanReadableNameMap;
  objUrl: string;
  documentName = '';
  docDescription = '';
  statusSelected = 'approve';
  s3KeyUrl: string;
  type: string;
  approved = false;
  docData: any;

  constructor(
    private httpClient: HttpClient,
    private roleResolver: RoleResolverService,
    private matDialogRef: MatDialogRef<ReviewAccountDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.docData = data;
  }

  ngOnInit() {
    this.documentName = this.docData.documentName;
    if (this.docData.status === 'reviewed') {
      this.approved = true;
    }
    if (this.docData.objUrl) {
      this.objUrl = this.docData.objUrl;
      this.pdfView.nativeElement.src = this.objUrl;
    } else if (this.docData.s3KeyUrl) {
      this.type = this.docData.type;
      this.s3KeyUrl = this.docData.s3KeyUrl;
      this.fetchDataFromS3();
    }

    if (this.type === 'image/jpg') {
      const nativeEl = this.pdfView.nativeElement;
      if ((nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete') {
        nativeEl.onload = this.onIframeLoad.bind(this);
      } else {
        if (nativeEl.addEventListener) {
          nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
        } else if (nativeEl.attachEvent) {
          nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
        }
      }
    }
  }

  onIframeLoad() {
    const imgTag = this.pdfView.nativeElement.contentWindow.document.body.children[0];
    if (imgTag) {
      imgTag.style.cssText = 'width: 100%; height: 100%; object-fit: contain;';
    }
  }

  fetchDataFromS3() {
    const self = this;
    this.getPdfData(this.s3KeyUrl).subscribe(
      (res: Blob) => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const filename = 'filename';
          const base64 = e.target['result'];
          const base64Array = base64arraybuffer.decode(base64);
          const file = new File([base64Array], filename, {
            type: this.type,
          });
          const objUrlFromS3 = window.URL.createObjectURL(file);
          this.objUrl = objUrlFromS3;
          self.pdfView.nativeElement.src = objUrlFromS3;
        };
        fileReader.readAsBinaryString(res);
      },
      err => {
        console.error(err);
      }
    );
  }

  getPdfData(s3KeyUrl: string): any {
    const cacheBusterUrl = `${s3KeyUrl}`;
    return this.httpClient.get(cacheBusterUrl, {
      responseType: 'blob',
    });
  }

  cancel() {
    this.matDialogRef.close();
  }

  submit() {
    const results = {
      documentName: this.documentName,
      status: this.statusSelected,
    };
    this.documentName = '';
    this.matDialogRef.close({
      data: results,
    });
  }

  isDisabled() {
    return this.roleResolver.checkPermission(['account_info_update']);
  }
}
