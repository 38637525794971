import Amplify, { API, Auth } from 'aws-amplify';
import { Observable, from } from 'rxjs';
import { OnInit, Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHandler } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpClient extends HttpClient {
  constructor(private logger: LoggerService, private httpHandler: HttpHandler) {
    super(httpHandler);
    this.setupAmplify();
  }

  setupAmplify() {
    API.configure({
      endpoints: [
        {
          name: 'endpoint',
          endpoint: environment.basePath,
        },
      ],
    });
  }

  get<T>(path, data): Observable<any> {
    const init: any = {
      header: data.headers,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      if (data.params.updates) {
        data.params.updates.forEach(paramData => {
          init.queryStringParameters[paramData.param] = paramData.value;
        });
      }
    }
    return from(API.get('endpoint', path.split(environment.basePath)[1], init));
  }

  put<T>(path, body, data) {
    const init: any = {
      header: data.headers,
      body,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }

    return from(API.put('endpoint', path.split(environment.basePath)[1], init));
  }

  post<T>(path, body, data) {
    const init: any = {
      header: data.headers,
      body,
      response: false,
    };
    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }
    return from(API.post('endpoint', path.split(environment.basePath)[1], init));
  }

  delete<T>(path, data) {
    const init: any = {
      header: data.headers,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }

    return from(API.del('endpoint', path.split(environment.basePath)[1], init));
  }
}
