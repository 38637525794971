import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { User } from '@gcv/shared';
import { map } from 'rxjs/operators';

import { DataPersistence } from '../../utils/data.persistance';
import {
  AllStaffLoaded,
  CreateOrganizationStaff,
  LoadAllStaff,
  LoadOrganizationStaff,
  ManuallyVerifyStaff,
  OrganizationStaffCreated,
  OrganizationStaffLoaded,
  OrganizationStaffUpdated,
  StaffActionTypes,
  StaffManuallyVerified,
  UpdateOrganizationStaff,
} from './staff.actions';
import { StaffState } from './staff.reducer';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class StaffEffect {
  @Effect() loadStaff$ = this.dataPersistance.fetch(StaffActionTypes.LoadOrganizationStaff, {
    run: (action: LoadOrganizationStaff, state: StaffState) => {
      return this.sdk
        .organizationsIdUsersGet(action.payload.orgId)
        .pipe(map((res: User[]) => new OrganizationStaffLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.LoadOrganizationStaff, message: err });
    },
  });

  @Effect() manuallyVerifyStaff$ = this.dataPersistance.fetch(StaffActionTypes.ManuallyVerifyStaff, {
    run: (action: ManuallyVerifyStaff, state: StaffState) => {
      return this.sdk
        .gcvUserIdVerificationPut(action.payload.userId)
        .pipe(map((res: User) => new StaffManuallyVerified(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.ManuallyVerifyStaff, message: err });
    },
  });

  @Effect() LoadAllStaff$ = this.dataPersistance.fetch(StaffActionTypes.LoadAllStaff, {
    run: (action: LoadAllStaff, state: StaffState) => {
      return this.sdk.usersGet().pipe(map((res: User[]) => new AllStaffLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.LoadAllStaff, message: err });
    },
  });

  @Effect() CreateOrganizationStaff$ = this.dataPersistance.fetch(StaffActionTypes.CreateOrganizationStaff, {
    run: (action: CreateOrganizationStaff, state: StaffState) => {
      return this.sdk.usersPost(action.payload).pipe(
        map((res: User) => {
          console.log(res);
          return new OrganizationStaffCreated(res);
        })
      );
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.CreateOrganizationStaff, message: err });
    },
  });

  @Effect() updateStaff$ = this.dataPersistance.pessimisticUpdate(StaffActionTypes.UpdateOrganizationStaff, {
    run: (action: UpdateOrganizationStaff, state: StaffState) => {
      return this.sdk
        .usersIdPut(action.payload.id, action.payload)
        .pipe(map((res: User) => new OrganizationStaffUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.UpdateOrganizationStaff, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<StaffState>, private sdk: DefaultService) {}
}
