import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gcv-support-card',
  templateUrl: './support-card.component.html',
  styleUrls: ['./support-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportCardComponent {
  @Input() cardBody: string;
  @Input() cardTitle: string;
  public expanded = false;

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
