import { Action } from '@ngrx/store';
import { User } from '@gcv/shared';

export enum StaffActionTypes {
  LoadOrganizationStaff = '[Staff] Load Organization Staff',
  OrganizationStaffLoaded = '[Staff] Organization Staff Loaded',

  CreateOrganizationStaff = '[Staff] Create Organization Staff',
  OrganizationStaffCreated = '[Staff] Organization Staff Created',

  LoadAllStaff = '[Staff] Load All Staff',
  AllStaffLoaded = '[Staff] All Staff Loaded',

  ManuallyVerifyStaff = '[Staff] Manually Verify Staff',
  StaffManuallyVerified = '[Staff] Staff Manually Verified',

  UpdateOrganizationStaff = '[Staff] Update Organization Staff',
  OrganizationStaffUpdated = '[Staff] Organization Staff Updated',

  ResetStaff = '[Staff] Reset',
}

export class LoadOrganizationStaff implements Action {
  readonly type = StaffActionTypes.LoadOrganizationStaff;

  constructor(public payload: { orgId: string }) {}
}

export class OrganizationStaffLoaded implements Action {
  readonly type = StaffActionTypes.OrganizationStaffLoaded;

  constructor(public payload: User[]) {}
}

export class ManuallyVerifyStaff implements Action {
  readonly type = StaffActionTypes.ManuallyVerifyStaff;

  constructor(public payload: { userId: string }) {}
}

export class StaffManuallyVerified implements Action {
  readonly type = StaffActionTypes.StaffManuallyVerified;

  constructor(public payload: User) {}
}

export class CreateOrganizationStaff implements Action {
  readonly type = StaffActionTypes.CreateOrganizationStaff;

  constructor(public payload: Partial<User>) {}
}

export class OrganizationStaffCreated implements Action {
  readonly type = StaffActionTypes.OrganizationStaffCreated;

  constructor(public payload: User) {}
}

export class LoadAllStaff implements Action {
  readonly type = StaffActionTypes.LoadAllStaff;
}

export class AllStaffLoaded implements Action {
  readonly type = StaffActionTypes.AllStaffLoaded;

  constructor(public payload: User[]) {}
}

export class UpdateOrganizationStaff implements Action {
  readonly type = StaffActionTypes.UpdateOrganizationStaff;

  constructor(public payload: User) {}
}

export class OrganizationStaffUpdated implements Action {
  readonly type = StaffActionTypes.OrganizationStaffUpdated;

  constructor(public payload: User) {}
}

export class ResetStaff implements Action {
  readonly type = StaffActionTypes.ResetStaff;
}

export type StaffActions =
  | LoadOrganizationStaff
  | OrganizationStaffLoaded
  | UpdateOrganizationStaff
  | OrganizationStaffUpdated
  | ResetStaff
  | CreateOrganizationStaff
  | OrganizationStaffCreated
  | LoadAllStaff
  | AllStaffLoaded;
