import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment,
  CanActivateChild,
} from '@angular/router';

import { LoggerService } from '../../../services/logger/logger.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { RoleResolverService } from '../../../services/role-resolver.service';

/* 
Current Set of Roles - 3/1/19
  | 'account_approve'
  | 'account_info_update'
  | 'account_info_view'
  | 'deposit_approve'
  | 'deposit_create'
  | 'deposit_view'
  | 'deposit_view_all'
  | 'fincen_report_approve'
  | 'fincen_report_prepare'
  | 'fincen_report_view'
  | 'personal_user_details_update'
  | 'personal_user_details_view'
  | 'organizational_details_update'
  | 'organizational_details_view'
  | 'sales_file_upload'
  | 'sales_file_view'
  | 'user_add'
  | 'user_remove';
*/

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    public router: Router,
    public logger: LoggerService,
    private location: Location,
    private roleResolver: RoleResolverService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const actions = route.data['actions'] ? route.data['actions'] : [];
    const allowed = this.roleResolver.checkPermission(actions);
    if (!allowed) {
      this.location.back();
    }
    return allowed;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const actions = route.data['actions'] ? route.data['actions'] : [];
    const allowed = this.roleResolver.checkPermission(actions);
    if (!allowed) {
      this.location.back();
    }
    return allowed;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const actions = route.data['actions'] ? route.data['actions'] : [];
    const allowed = this.roleResolver.checkPermission(actions);
    if (!allowed) {
      this.location.back();
    }
    return allowed;
  }
}
