import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';
import { FincenActionTypes } from './fincen.actions';

export interface FincenState extends EntityState<FincenCtrReport | FincenSarReport> {}

export const adapterFincen: EntityAdapter<FincenCtrReport | FincenSarReport> = createEntityAdapter<
  FincenCtrReport | FincenSarReport
>();

export const initialFincenState = adapterFincen.getInitialState();

export function fincenReducer(state = initialFincenState, action): FincenState {
  switch (action.type) {
    case FincenActionTypes.FincenLoaded:
      return adapterFincen.upsertMany(action.payload, state);
    case FincenActionTypes.FincenCreated:
      return adapterFincen.addOne(action.payload, state);
    case FincenActionTypes.FincenUpdated:
      return adapterFincen.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case FincenActionTypes.ResetFincen:
      return initialFincenState;
    default:
      return state;
  }
}

export const {
  selectIds: selectFincenIds,
  selectEntities: selectFincenEntities,
  selectAll: selectAllFincen,
} = adapterFincen.getSelectors();
