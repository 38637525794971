import { mockVendor } from './mock.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  vendors = {};

  constructor() {
    for (let i = 0; i < 5; i++) {
      const vendor = mockVendor(i);
      this.vendors[`${vendor.id}`] = vendor;
    }
  }

  getVendorById(id) {
    return this.vendors[id];
  }

  createVendor(vendor) {
    const newVendor = mockVendor(vendor.id);
    this.vendors[newVendor.id] = newVendor;
    return newVendor;
  }

  putVendorById(vendor) {
    this.vendors[vendor.id] = vendor;
    return vendor;
  }
}
