import { mockReport } from './mock.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FincenService {
  reports = {};

  constructor() {
    const reports = [
      mockReport('123456', '232322', 'CTR', Math.floor(Math.random() * 10000000)),
      mockReport('123456', '232322', 'SAR', Math.floor(Math.random() * 10000000)),
      mockReport('123456', '2323223', 'CTR', Math.floor(Math.random() * 10000000)),
    ];
    reports.forEach(report => {
      if (!this.reports['123456']) {
        this.reports['123456'] = {};
      }
      this.reports['123456'][report.id] = report;
    });
  }

  getReportByBankId(id, dispensaryId = '232322') {
    return Object.values(this.reports['123456']).filter((report: any) => report.dispensary_id === dispensaryId);
  }

  updateReport(data) {
    this.reports['123456'][data.id] = data;
    return data;
  }

  createReport(data) {
    const newReport = mockReport('123456', data.dispensary_id, data.type, Math.floor(Math.random() * 1000000));
    this.reports['123456'][newReport.id] = newReport;
    return newReport;
  }
}
