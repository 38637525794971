import { MockDefaultService } from '../mock-sdk/mock.default.service';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';

export interface MODEL4cbb66 {
  fiName: string;
  selected: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class XmlExportService {
  constructor(private mockSdk: MockDefaultService) {}

  exportSelectAsXml(selected: any[], fiName: string) {
    const body: MODEL4cbb66 = {
      fiName,
      selected,
    };
    return this.mockSdk.fincenReportsXmlPost(body);
  }

  export(response, selected) {
    let mimeType;
    if (selected.length === 1) {
      mimeType = 'text/xml: charset=utf=8';
    } else {
      mimeType = 'application/zip';
    }
    const file = new File([Buffer.from(response.fileData, 'base64')], response.fileName, { type: mimeType });
    saveAs(file);
    return of();
  }
}
