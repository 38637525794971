import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatReportDate',
})
export class FormatReportDatePipe implements PipeTransform {
  transform(date: any, args?: number): any {
    if (date) {
      const dueDate = DateTime.fromISO(date, { zone: 'utc' });
      const todaysDate = DateTime.utc();
      const diff = todaysDate.diff(dueDate, 'days').toObject();
      return args - Math.abs(Math.floor(diff.days));
    } else {
      return 0;
    }
  }
}
