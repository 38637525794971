import { AbstractControl, ValidationErrors } from '@angular/forms';

const validationErrorObject = {
  passwordStrength: `Password must contain at least two of the following: numbers, lowercase letters, uppercase letters, or special characters.`,
};
const PasswordStrengthValidator = (control: AbstractControl): ValidationErrors | null => {
  const value: string = control.value || '';
  if (value) {
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numberCharacters = /[0-9]+/g;
    const specialCharacters = /[!@#$%^&*()_+\-\[\]{};':"\\|,.<>\/?]+/;
    if (
      upperCaseCharacters.test(value) === false ||
      lowerCaseCharacters.test(value) === false ||
      numberCharacters.test(value) === false ||
      specialCharacters.test(value) === false
    ) {
      return validationErrorObject;
    }
  } else {
    return null;
  }
};

export { PasswordStrengthValidator, validationErrorObject };
