import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { LoggerService } from './logger/logger.service';
import { Location } from '@angular/common';
import { UtilsService } from './utils.service';
import { DEFAULT_TIME_FILTER } from '@user-interface/gcv-ui';

/*
  This service provides communication for the Dispensary App family of components
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class DispensaryAppFamilyService implements OnDestroy {
  public view = new Subject();
  private notifications: any[];
  private previousRoute = '';
  public timeFilter = new BehaviorSubject<string>(DEFAULT_TIME_FILTER);

  private view$: Subscription;
  private timeFilter$: Subscription;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private location: Location,
    private utils: UtilsService
  ) {
    this.setupStream();
  }

  setupStream() {
    this.view$ = this.view.subscribe((data: any) => {
      if (data.hasOwnProperty('view')) {
        this.router.navigate([`${data.view}`]).then(() => {});
      }
    });
  }

  ngOnDestroy() {
    if (this.view$) {
      this.view$.unsubscribe();
    }

    if (this.timeFilter$) {
      this.timeFilter$.unsubscribe();
    }
  }

  setPreviousUrl(url) {
    this.previousRoute = url;
  }

  setDashboardTimeFilter(timePeriod) {
    const newFilter = this.utils.getNewTimeFilter(timePeriod);
    this.timeFilter.next(newFilter);
  }

  getNotifications() {
    return this.notifications;
  }
}
