import { Injectable } from '@angular/core';
import { mockBank } from './mock.data';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  bank: { [key: string]: any } = {};
  groups = {};

  constructor() {
    const bank = mockBank('12345677');
    this.bank[bank.id] = bank;
    this.bank[bank.id].groups.forEach(group => {
      this.groups[group.id] = group;
    });
  }

  getBankById(id) {
    return this.bank[id];
  }

  createBank(data) {
    const bank = mockBank(data.id);
    this.bank[bank.id] = bank;
    return bank;
  }

  updateBank(data) {
    this.bank[data.id] = data;
    return this.bank[data.id];
  }

  getGroupById(id: string) {
    return this.groups[id];
  }

  putGroupsById(id, data) {
    this.bank[id].groups = data;
    return this.bank[id];
  }
}
