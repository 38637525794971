import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { User } from '@gcv/shared';
import { map } from 'rxjs/operators';

import {
  LoadUser,
  LoadUserKba,
  UpdateUser,
  UpdateUserDocuments,
  UserActionTypes,
  UserKbaAnswersVerified,
  UserKbaLoaded,
  UserLoaded,
  UserUpdated,
  VerifyUserKbaAnswers,
} from './user.actions';
import { DataPersistence } from '../../utils/data.persistance';
import { UserState } from './user.reducer';
import { AddError } from '../error';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class UserEffect {
  @Effect() loadUser$ = this.dataPersistance.fetch(UserActionTypes.LoadUser, {
    run: (action: LoadUser, state: UserState) => {
      return this.sdk.usersIdGet(action.payload.userId).pipe(map((res: User) => new UserLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.LoadUser, message: err });
    },
  });

  @Effect() updateUser$ = this.dataPersistance.pessimisticUpdate(UserActionTypes.UpdateUser, {
    run: (action: UpdateUser, state: UserState) => {
      return this.sdk.usersIdPut(action.payload.id, action.payload).pipe(map((res: User) => new UserUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.UpdateUser, message: err });
    },
  });

  @Effect() verifyUserByKba$ = this.dataPersistance.fetch(UserActionTypes.LoadUserKba, {
    run: (action: LoadUserKba, state: UserState) => {
      return this.sdk.usersIdKbaGet(action.payload.userId).pipe(map((res: any) => new UserKbaLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.LoadUserKba, message: err });
    },
  });

  @Effect() verifyUserKbaAnswers = this.dataPersistance.fetch(UserActionTypes.VerifyUserKbaAnswers, {
    run: (action: VerifyUserKbaAnswers, state: UserState) => {
      return this.sdk
        .usersIdVerifyPost(action.payload.userId, action.payload.userAnswers)
        .pipe(map((res: User) => new UserKbaAnswersVerified(res)));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.VerifyUserKbaAnswers, message: err });
    },
  });

  @Effect({ dispatch: false }) updateUserDocuments = this.dataPersistance.fetch(UserActionTypes.UpdateUserDocuments, {
    run: (action: UpdateUserDocuments, state: UserState) => {
      return this.sdk.usersIdDocumentsPut(action.payload.userId);
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.UpdateUserDocuments, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<UserState>, private sdk: DefaultService) {}
}
