import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Dispensary } from '@gcv/shared';
import { DispensaryFacade } from '@user-interface/gcv-state';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DispensaryRouteResolver implements Resolve<any> {
  constructor(private dispFacade: DispensaryFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Dispensary> {
    const id = route.queryParams['account'];
    let observable: Observable<Dispensary>;
    if (id) {
      observable = this.dispFacade.selectDispensaryById(id);
    } else {
      observable = this.dispFacade.selectCurrentDispensary();
    }
    return observable.pipe(take(1));
  }
}
