import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, InlineSVGModule.forRoot()],
  declarations: [],
  exports: [],
})
export class GcvInlineSVGModule {}
