import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SalesFileService {
  salesFileHashes = {
    '123456': true,
  };

  checkSalesFileHash(hash) {
    const isDuplicate = this.salesFileHashes[hash];
    if (!isDuplicate) {
      this.salesFileHashes[hash] = true;
    }
    return { data_hash: isDuplicate };
  }
}
