export const FINCEN_FEATURE_MODULE_BLACKLIST = 'fincen-feature-module-blacklist';
export const WEB_SOCKETS_ENABLED = 'web-sockets-enabled';
export class FeatureFlags {
  static get FINCEN_FEATURE_MODULE_BLACKLIST() {
    return FINCEN_FEATURE_MODULE_BLACKLIST;
  }

  static get WEB_SOCKETS_ENABLED() {
    return WEB_SOCKETS_ENABLED;
  }
}
