import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, start: number = 0, end: number = 5): string {
    if (value && value.length > end) {
      return value.substr(start, end) + '...';
    } else if (value && value.length < end) {
      return value;
    } else {
      return '';
    }
  }
}
