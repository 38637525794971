import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-password',
  templateUrl: 'new.password.component.html',
  styleUrls: ['new.password.component.scss'],
})
export class NewPasswordComponent implements OnDestroy {
  errorMessage: any;
  loading = false;
  password = '';
  email = '';
  private route$: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.setupStream();
  }

  setupStream() {
    this.route$ = this.route.queryParams.subscribe(params => {
      if (params['password'] && params['email']) {
        this.password = params['password'];
        this.email = params['email'];
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy() {
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  setErrorMessage(err) {
    this.errorMessage = err;
  }

  isLoading(loading) {
    this.loading = loading;
  }

  passwordChanged(user) {
    this.router.navigate(['/login']);
  }

  onAlertClose() {
    this.errorMessage = undefined;
  }
}
