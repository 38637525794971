import { CognitoUser } from 'amazon-cognito-identity-js';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';

export enum AUTH_STATUS {
  Unauthenticated,
  RegisteredButNotConfirmed,
  RegistrationConfirmed,
  Authenticated,
}
const CAPITAL_LETTER_A_UTF_16_DECIMAL = 65;
const CAPITAL_LETTER_Z_UTF_16_DECIMAL = 90;
const SMALL_LETTER_A_UTF_16_DECIMAL = 97;
const SMALL_LETTER_Z_UTF_16_DECIMAL = 122;
const ENGLISH_ALPHABET_LENGTH = 26;

const getShiftedCharCode = (code: number, aIndex: number, amount: number) => {
  return ((code - aIndex + amount) % ENGLISH_ALPHABET_LENGTH) + aIndex;
};

/**
 * @see https://gist.github.com/EvanHahn/2587465
 */
const caesarShift = (str: string, amount: number) => {
  // Wrap the amount
  if (amount < 0) {
    return caesarShift(str, amount + ENGLISH_ALPHABET_LENGTH);
  }
  // Make an output variable
  let output = '';
  // Go through each character
  for (let i = 0; i < str.length; i++) {
    // Get the character we'll be appending
    let c = str[i];
    // If it's a letter...
    if (c.match(/[a-z]/i)) {
      // Get its code
      const code = str.charCodeAt(i);
      // Uppercase letters
      if (code >= CAPITAL_LETTER_A_UTF_16_DECIMAL && code <= CAPITAL_LETTER_Z_UTF_16_DECIMAL) {
        c = String.fromCharCode(getShiftedCharCode(code, CAPITAL_LETTER_A_UTF_16_DECIMAL, amount));
        // Lowercase letters
      } else if (code >= SMALL_LETTER_A_UTF_16_DECIMAL && code <= SMALL_LETTER_Z_UTF_16_DECIMAL) {
        c = String.fromCharCode(getShiftedCharCode(code, SMALL_LETTER_A_UTF_16_DECIMAL, amount));
      }
    } else {
      c = '';
    }
    // Append
    output += c;
  }
  // All done!
  return output;
};

export { caesarShift };

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  public status: AUTH_STATUS;
  public cognitoUser: CognitoUser;

  constructor() {}

  public registerUser(username, role, fullName) {
    return new Promise((resolve, reject) => {
      const password = caesarShift(username.split('@')[0] + 'ajkcdYHNMJU', 3) + '@17';
      Auth.signUp({
        username,
        password,
        attributes: {
          'custom:Role': role,
          name: fullName,
        },
      })
        .then(data => {
          this.cognitoUser = data.user;
          this.status = AUTH_STATUS.RegisteredButNotConfirmed;
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }
}
