import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-user-interface-due-diligence-requirement-card',
  templateUrl: './due-diligence-requirement-card.component.html',
  styleUrls: ['./due-diligence-requirement-card.component.scss'],
})
export class DueDiligenceRequirementCardComponent implements OnInit {
  @Input() isUploaded = false;
  @Input() multipleDocs = false;
  @Input() filename = '';
  @Input() document = '';
  @Input() description = '';
  @Output() uploadEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleEdit() {
    this.editEvent.emit();
  }

  handleUpload() {
    this.uploadEvent.emit();
  }
}
