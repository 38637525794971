import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { LoggerService } from '../../../services/logger/logger.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(public router: Router, public logger: LoggerService, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isUserLoggedIn().pipe(
      mergeMap((user: any) => {
        if (user) {
          this.logger.debug('AuthGuard.canActivate');
          return this.authService.initLogin(user, false, true);
        } else {
          this.logger.debug('AuthGuard.canNotActivate');
          this.router.navigate(['/login']);
          return of(false);
        }
      })
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isUserLoggedIn().pipe(
      mergeMap((user: any) => {
        if (user) {
          this.logger.debug('AuthGuard.canActivateChild');
          return this.authService.initLogin(user, false, true);
        } else {
          this.logger.debug('AuthGuard.canNotActivateChild');
          this.router.navigate(['/login']);
          return of(false);
        }
      })
    );
  }
}
