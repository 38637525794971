import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { User } from '@gcv/shared';

@Component({
  selector: 'gcv-edit-group-add-users',
  templateUrl: './edit-group-add-user-dialog.component.html',
  styleUrls: ['./edit-group-add-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGroupAddUserDialogComponent {
  filterText = '';
  filteredUsers = [];
  users = [];
  group: any;
  userMap = {};
  selectedMap = {};

  constructor(
    private matDialogRef: MatDialogRef<EditGroupAddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userMap = data.userMap;
    this.users = data.allUsers;
    this.group = data.group;
    this.filteredUsers = this.users;
    if (data.group && data.group.users.length > 0) {
      data.group.users.forEach(userId => {
        this.selectedMap[userId] = this.userMap[userId];
      });
    }
  }

  selectedCount() {
    return Object.keys(this.selectedMap).length;
  }

  addUser(selectedUser, i) {
    if (this.selectedMap[this.filteredUsers[i].id]) {
      delete this.selectedMap[this.filteredUsers[i].id];
    } else {
      this.selectedMap[this.filteredUsers[i].id] = this.filteredUsers[i];
    }
  }

  filterItems() {
    if (this.filterText.length === 0) {
      this.filteredUsers = this.users;
    }
    this.filteredUsers = this.users.filter(user => {
      const filterLower = this.filterText.toLowerCase();
      if (user.firstName.toLowerCase().includes(filterLower) || user.lastName.toLowerCase().includes(filterLower)) {
        return user;
      }
    });
  }

  handleSaveClicked() {
    const group = this.group;
    group.users = Object.values(this.selectedMap).map((user: User) => user.id);
    this.matDialogRef.close({ group });
  }

  handleCloseClicked() {
    this.matDialogRef.close();
  }

  formatRole(role) {
    if (role === 'dispensary_admin' || role === 'bank_admin') {
      return 'Admin';
    } else if (role === 'dispensary_user' || role === 'bank_user') {
      return 'Standard';
    }
    return 'Standard';
  }
}
