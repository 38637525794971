import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcv-spinner-icon',
  templateUrl: './spinner-icon.component.html',
  styleUrls: ['./spinner-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerIconComponent implements OnInit {
  @Input() styles: string;

  constructor() {}

  ngOnInit() {}
}
